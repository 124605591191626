import React from 'react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { useSelector } from 'react-redux';

import { RootState } from '../stores';

export const Error = () => {
    // 全体stateより取得
    const errorMessage = useSelector((state: RootState) => state.error.message);
    return (
        <div
            className="ms-Grid"
            style={{
                height: '100%',
                width: '100%',
                textAlign: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: '#fed9cc',
                    marginTop: '50px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                }}
            >
                <h3>エラーが発生しました。管理者に連絡して下さい。</h3>
            </div>
            <div style={{ marginTop: '5px' }}>
                <div>{errorMessage}</div>
                <br />

                <Link href="/">トップページへ戻る</Link>
            </div>
        </div>
    );
};
