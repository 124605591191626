import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useId } from '@uifabric/react-hooks';
import {
    getTheme,
    mergeStyleSets,
    DefaultButton,
    Modal,
    IconButton,
    IIconProps,
    PrimaryButton,
    ComboBox,
    TextField,
    Label,
} from 'office-ui-fabric-react';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { useDispatch, useSelector } from 'react-redux';

import {
    ClassificationOption,
    iCDMaintenanceData,
    iCDMaintenanceSaveData,
    iCDMaintenanceAddData,
} from '../../types/types';
import { setLoader, deleteLoader } from '../../stores/ui/ui';
import { openDialog, openConfirm, closeDialog } from '../../stores/ui/dialog';
import { RootState } from '../../stores';
import { saveiCDData } from '../../api/call';

const skillOptions: IChoiceGroupOption[] = [
    { key: 'Category', text: 'スキルカテゴリ' },
    { key: 'Classification', text: 'スキル分類' },
    { key: 'Item', text: 'スキル項目' },
];

const taskOptions: IChoiceGroupOption[] = [
    { key: 'Category', text: 'タスク大分類' },
    { key: 'Classification', text: 'タスク中分類' },
    { key: 'Item', text: 'タスク小分類' },
];

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xxLarge,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

export const IcdMaintenanceAddModal = (props: any) => {
    const dispatch = useDispatch();

    const loginUser = useSelector((state: RootState) => state.user);

    const [selectedKey, setSelectedKey] = useState<string>('Category');
    const [category] = useState();
    const [categoryKey, setCategoryKey] = useState<string | number | undefined>('');
    const [classification] = useState();
    const [classificationOption, setClassificationOption] = useState<ClassificationOption[]>();
    const [classificationKey, setClassificationKey] = useState<string | number | undefined>('');
    const [field] = useState();
    const [fieldKey, setFieldKey] = useState<string | number | undefined>('');
    const [saveText, setSaveText] = useState<iCDMaintenanceSaveData>({
        Category: { name: '' },
        Classification: { name: '' },
        Item: { name: '' },
    });
    const titleId = useId('title');
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const skillMode = props.mode === 'skill';

    const onChange = React.useCallback(
        (
            ev: React.SyntheticEvent<HTMLElement> | undefined,
            option: IChoiceGroupOption | undefined
        ) => {
            if (option) {
                setSelectedKey(option.key);
            }
        },
        []
    );

    type saveKeys = keyof iCDMaintenanceSaveData;
    type dataKeys = keyof iCDMaintenanceData;

    const textChange = (o: any, category: saveKeys, key: dataKeys) => {
        let saveTextCopy = _.cloneDeep(saveText);
        if (saveTextCopy) {
            let text = o;
            if (key === 'icdId') {
                text = parseInt(o);
                if (!isNaN(text)) {
                    saveTextCopy[category][key] = text;
                    setSaveText(saveTextCopy);
                } else {
                    saveTextCopy[category][key] = 0;
                    setSaveText(saveTextCopy);
                }
            } else {
                saveTextCopy[category][key] = text;
                setSaveText(saveTextCopy);
            }
        }
    };

    const save = () => async () => {
        try {
            if (loginUser && loginUser.fiscalYearId) {
                let saveData: iCDMaintenanceAddData = {
                    skillMode: props.mode,
                    saveMode: selectedKey,
                    categoryKey: categoryKey,
                    classificationKey: classificationKey,
                    fieldId: fieldKey,
                    saveText: saveText,
                };
                dispatch(setLoader('saveiCDData'));
                dispatch(closeDialog());
                await saveiCDData(loginUser.fiscalYearId, saveData);
                dispatch(
                    openDialog({
                        title: '保存完了',
                        subText: '保存しました',
                        primaryAction: () => {
                            dispatch(closeDialog());
                            setSaveText({
                                Category: {
                                    icdId: undefined,
                                    code: '',
                                    name: '',
                                },
                                Classification: {
                                    icdId: undefined,
                                    code: '',
                                    name: '',
                                },
                                Item: {
                                    icdId: undefined,
                                    code: '',
                                    name: '',
                                },
                            });
                            props.hideModal();
                            props.init();
                        },
                    })
                );
            }
        } catch (err) {
            console.log(err);
            dispatch(
                openDialog({
                    title: '保存エラー',
                    subText: err.message, // FIXME: エラーメッセージが取得できてない
                    primaryAction: () => {
                        dispatch(closeDialog());
                    },
                })
            );
        } finally {
            dispatch(deleteLoader('saveiCDData'));
        }
    };

    const onSave = () => {
        let validate = true;
        switch (selectedKey) {
            case 'Category':
                validate = !!(
                    saveText[selectedKey].code &&
                    saveText[selectedKey].name &&
                    saveText[selectedKey].icdId
                );
                break;
            case 'Classification':
                validate = !!(
                    categoryKey &&
                    saveText[selectedKey].code &&
                    saveText[selectedKey].name &&
                    saveText[selectedKey].icdId
                );
                break;
            case 'Item':
                validate = !!(
                    classificationKey &&
                    fieldKey &&
                    saveText[selectedKey].code &&
                    saveText[selectedKey].name &&
                    saveText[selectedKey].icdId
                );
                break;
        }
        if (validate) {
            dispatch(
                openConfirm({
                    title: '保存確認',
                    subText: '変更を保存します。よろしいですか？',
                    primaryAction: save(),
                    secondaryAction: () => dispatch(closeDialog()),
                })
            );
        } else {
            dispatch(
                openDialog({
                    title: '入力エラー',
                    subText: '必須入力欄が空欄です。',
                    primaryAction: () => {
                        dispatch(closeDialog());
                    },
                })
            );
        }
    };

    useEffect(() => {
        // カテゴリに紐づくスキル分類 or タスク中分類を絞り込み
        let _classificationOption: ClassificationOption[] = skillMode
            ? props.data.addSkillClassificationOption
            : props.data.addTaskClassificationOption;
        if (categoryKey) {
            _classificationOption = _classificationOption.filter((cl: ClassificationOption) => {
                return cl.key2 === categoryKey;
            });
        }
        setClassificationOption(_classificationOption);
    }, [categoryKey, props.data, skillMode]);

    return (
        <Modal
            titleAriaId={titleId}
            isOpen={props.isModalOpen}
            onDismiss={props.hideModal}
            isBlocking={false}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <span id={titleId}>追加</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={props.hideModal}
                />
            </div>
            <div className={contentStyles.body}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ChoiceGroup
                                selectedKey={selectedKey}
                                options={skillMode ? skillOptions : taskOptions}
                                onChange={(e, o) => onChange(e, o)}
                                label="分類対象"
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <Label>{skillMode ? 'スキルカテゴリ' : 'タスク大分類'}</Label>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ComboBox
                                label="カテゴリ"
                                allowFreeform
                                autoComplete="on"
                                text={category}
                                options={
                                    skillMode
                                        ? props.data.addSkillCategoryOption
                                        : props.data.addTaskCategoryOption
                                }
                                onChange={(e, option) => {
                                    setCategoryKey(option?.key);
                                }}
                                disabled={selectedKey === 'Category'}
                                required
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                label="識別子 ※数値"
                                value={saveText.Category.icdId?.toString()}
                                onChange={(e, o) => textChange(o, 'Category', 'icdId')}
                                disabled={selectedKey !== 'Category'}
                                required
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                label="コード"
                                value={saveText.Category.code}
                                onChange={(e, o) => textChange(o, 'Category', 'code')}
                                disabled={selectedKey !== 'Category'}
                                required
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                label="名称"
                                value={saveText.Category.name}
                                onChange={(e, o) => textChange(o, 'Category', 'name')}
                                disabled={selectedKey !== 'Category'}
                                required
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <Label>{skillMode ? 'スキル分類' : 'タスク中分類'}</Label>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ComboBox
                                label="分類"
                                allowFreeform
                                autoComplete="on"
                                text={classification}
                                options={classificationOption}
                                onChange={(e, option) => {
                                    setClassificationKey(option?.key);
                                }}
                                disabled={selectedKey !== 'Item' || !categoryKey}
                                required
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                label="識別子 ※数値"
                                value={saveText.Classification.icdId?.toString()}
                                onChange={(e, o) => textChange(o, 'Classification', 'icdId')}
                                disabled={selectedKey !== 'Classification'}
                                required
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                label="コード"
                                value={saveText.Classification.code}
                                onChange={(e, o) => textChange(o, 'Classification', 'code')}
                                disabled={selectedKey !== 'Classification'}
                                required
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                label="名称"
                                value={saveText.Classification.name}
                                onChange={(e, o) => textChange(o, 'Classification', 'name')}
                                disabled={selectedKey !== 'Classification'}
                                required
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <Label>{skillMode ? 'スキル項目' : 'タスク小分類'}</Label>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ComboBox
                                label={skillMode ? 'スキル領域' : 'タスク領域'}
                                allowFreeform
                                autoComplete="on"
                                text={field}
                                options={
                                    skillMode
                                        ? props.data.SkillFieldOption
                                        : props.data.TaskFieldOption
                                }
                                onChange={(e, option) => {
                                    setFieldKey(option?.key);
                                }}
                                disabled={selectedKey !== 'Item'}
                                required
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                label="識別子 ※数値"
                                value={saveText.Item.icdId?.toString()}
                                onChange={(e, o) => textChange(o, 'Item', 'icdId')}
                                disabled={selectedKey !== 'Item'}
                                required
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                label="コード"
                                value={saveText.Item.code}
                                onChange={(e, o) => textChange(o, 'Item', 'code')}
                                disabled={selectedKey !== 'Item'}
                                required
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                            <TextField
                                label="名称"
                                value={saveText.Item.name}
                                onChange={(e, o) => textChange(o, 'Item', 'name')}
                                disabled={selectedKey !== 'Item'}
                                required
                            />
                        </div>
                    </div>

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <PrimaryButton text="保存" style={{ margin: 2 }} onClick={onSave} />
                            <DefaultButton
                                text="閉じる"
                                style={{ margin: 2 }}
                                onClick={props.hideModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
