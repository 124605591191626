import axios from 'axios';
import * as deepmerge from 'deepmerge';
import { keycloak } from '../App';
import { AppConfig } from '../AppConfig';
import { User } from '../stores/loginuser';

import {
    ChartData,
    ClassificationOption,
    ComparisonDto,
    IcdImportDto,
    iCDMaintenanceAddData,
    iCDMaintenanceScreenData,
    iCDSkillItem,
    iCDTaskMinorCategory,
    JobsDto,
    option,
    retSkillData,
    retTaskData,
    savePossessedData,
    TaskProfilesDto,
} from '../types/types';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

/**
 * GET用共通関数
 * @param url
 * @param queryParameters
 * @param additionalConfig
 */
const get = async <T>(url: string, queryParameters?: any, additionalConfig?: any): Promise<T> => {
    const config = deepmerge.all([
        getDefaultAxiosConfig(),
        additionalConfig ?? {},
        { params: queryParameters },
    ]);
    const { data } = await axios.get<T>(url, config);
    return data;
};

/**
 * POST用共通関数
 * @param url
 * @param data
 * @param additionalConfig
 */
const post = async (url: string, data?: any, additionalConfig?: any): Promise<void> => {
    const config = deepmerge.all([getDefaultAxiosConfig(), additionalConfig ?? {}]);
    await axios.post(url, data, { ...getDefaultAxiosConfig(), ...config });
};

const getDefaultAxiosConfig = () => {
    return {
        headers: {
            Authorization: 'Bearer ' + keycloak.token,
        },
    };
};

export async function getUserData(userId: number) {
    const url = AppConfig.ApiUrlGetUserData;
    const params = { userId };
    return get<User>(url, params);
}

export async function getLoginUserData() {
    const url = AppConfig.ApiUrlGetLoginUserData;
    return get<User>(url);
}

export async function getActiveUsers() {
    const url = AppConfig.ApiUrlGetActiveUsers;
    return get<User[]>(url);
}

export async function getUserChartData(userId: number, fiscalYearId: number) {
    const url = AppConfig.ApiUrlGetUserDetailData;
    const params = { fiscalYearId, userId };
    return get<ChartData>(url, params);
}

export async function getSkillDetail(userId: number, fiscalYearId: number) {
    //スキルテーブル項目
    const skillColumns = [
        { key: 'skillCategory', name: 'スキルカテゴリ' },
        { key: 'skillClassification', name: 'スキル分類' },
        { key: 'skillItems', name: 'スキル項目' },
        { key: 'ownedSkills', name: '保有スキル' },
        { key: 'target', name: '目標' },
    ];

    //各項目の値
    const SkillCategoryOption: option[] = [{ key: '', text: '' }];

    const SkillClassificationOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];

    const SkillItemsOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];

    const OwnedSkillsOption = [
        { key: '0', text: 'ALL' },
        { key: '1', text: '保持スキル' },
        { key: '2', text: '保持していないスキル' },
    ];

    const TargetOption: any[] = [];

    const url = AppConfig.ApiUrlGetSkillDisplayData;
    const params = { fiscalYearId, userId };
    const data = await get<retSkillData>(url, params);
    data.skillData.map((skill) => {
        if (!SkillCategoryOption.some((a) => a.key === skill.skillCategoryId.toString())) {
            SkillCategoryOption.push({
                key: skill.skillCategoryId.toString(),
                text: skill.skillCategory,
            });
        }
        if (
            !SkillClassificationOption.some((a) => a.key === skill.skillClassificationId.toString())
        ) {
            SkillClassificationOption.push({
                key: skill.skillClassificationId.toString(),
                key2: skill.skillCategoryId.toString(),
                text: skill.skillClassification,
            });
        }
        if (!SkillItemsOption.some((a) => a.key === skill.skillItemsId.toString())) {
            SkillItemsOption.push({
                key: skill.skillItemsId.toString(),
                key2: skill.skillClassificationId.toString(),
                text: skill.skillItems,
            });
        }
        return skill;
    });
    data.skillGoalData.map((skillGoal) => {
        if (!TargetOption.some((a) => a.key === skillGoal.jobSpecializedFieldId)) {
            TargetOption.push({ key: skillGoal.jobSpecializedFieldId, text: skillGoal.job });
        }
        return skillGoal;
    });

    return {
        skillData: data.skillData,
        skillColumns: skillColumns,
        SkillCategoryOption: SkillCategoryOption,
        SkillClassificationOption: SkillClassificationOption,
        SkillItemsOption: SkillItemsOption,
        SkillDetailsOption: data.skillDetailsData,
        OwnedSkillsOption: OwnedSkillsOption,
        TargetOption: TargetOption,
        skillGoalData: data.skillGoalData,
    };
}

export async function getTaskDetail(userId: number, fiscalYearId: number) {
    //スキルテーブル項目
    const taskColumns = [
        { key: 'taskCategory', name: 'タスク大分類' },
        { key: 'taskClassification', name: 'タスク中分類' },
        { key: 'taskItems', name: 'タスク小分類' },
        { key: 'ownedSkills', name: '保有タスク' },
        { key: 'target', name: '目標' },
    ];

    //各項目の値
    const TaskCategoryOption: option[] = [{ key: '', text: '' }];

    const TaskClassificationOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];

    const TaskItemsOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];

    const OwnedTasksOption = [
        { key: '', text: 'ALL' },
        { key: 'true', text: '保持タスク' },
        { key: 'false', text: '保持していないタスク' },
    ];

    const TargetOption: any[] = [];

    const url = AppConfig.ApiUrlGetTaskDisplayData;
    const params = { fiscalYearId, userId };
    const data = await get<retTaskData>(url, params);
    data.taskData.map((task) => {
        if (!TaskCategoryOption.some((a) => a.key === task.taskCategoryId.toString())) {
            TaskCategoryOption.push({
                key: task.taskCategoryId.toString(),
                text: task.taskCategory,
            });
        }
        if (!TaskClassificationOption.some((a) => a.key === task.taskClassificationId.toString())) {
            TaskClassificationOption.push({
                key: task.taskClassificationId.toString(),
                key2: task.taskCategoryId.toString(),
                text: task.taskClassification,
            });
        }
        if (!TaskItemsOption.some((a) => a.key === task.taskItemsId.toString())) {
            TaskItemsOption.push({
                key: task.taskItemsId.toString(),
                key2: task.taskClassificationId.toString(),
                text: task.taskItems,
            });
        }
        return task;
    });

    data.taskGoalData.map((taskGoal) => {
        if (!TargetOption.some((a) => a.key === taskGoal.taskProfileId)) {
            TargetOption.push({ key: taskGoal.taskProfileId, text: taskGoal.taskProfile });
        }
        return taskGoal;
    });

    return {
        taskData: data.taskData,
        taskColumns: taskColumns,
        TaskCategoryOption: TaskCategoryOption,
        TaskClassificationOption: TaskClassificationOption,
        TaskItemsOption: TaskItemsOption,
        TaskDetailsOption: data.taskDetailsData,
        OwnedTasksOption: OwnedTasksOption,
        TargetOption: TargetOption,
        taskGoalData: data.taskGoalData,
    };
}

export function SavePossession(
    loginUserId: number,
    fiscalYearId: number,
    saveData: savePossessedData
) {
    const url = AppConfig.ApiUrlSavePossession;
    return post(url, {
        loginUserId: loginUserId,
        fiscalYearId: fiscalYearId,
        savePossessedData: saveData,
    });
}

export function getJobs(userId: number, fiscalYearId: number) {
    const url = AppConfig.ApiUrlJobsData;
    const params = { fiscalYearId, userId };
    return get<JobsDto['Jobs']>(url, params);
}

export function getjobsDetails(userId: number, fiscalYearId: number) {
    const url = AppConfig.ApiUrlJobsDetailsData;
    const params = { fiscalYearId, userId };
    return get<JobsDto['JobsDetails']>(url, params);
}

export function getjobsSkill() {
    const url = AppConfig.ApiUrlJobsSkillData;
    return get<JobsDto['JobSkill']>(url);
}

export function insertSkillGoals(loginUserId: number, fiscalYearId: number, checked: any) {
    const url = AppConfig.ApiUrlInsertSkillGoalsData;
    return post(url, {
        loginUserId: loginUserId,
        fiscalYearId: fiscalYearId,
        jobSpecializedFieldIds: checked,
    });
}

export function getTaskProfiles(userId: number, fiscalYearId: number) {
    const url = AppConfig.ApiUrlTaskProfilesData;
    const params = { fiscalYearId, userId };
    return get<TaskProfilesDto['TaskProfiles']>(url, params);
}

export async function getTaskProfilesDetails(userId: number, fiscalYearId: number) {
    const url = AppConfig.ApiUrlTaskProfilesDetailsData;
    const params = { fiscalYearId, userId };
    return get<TaskProfilesDto['TaskProfilesDetails']>(url, params);
}

export function getTaskTaskProfiles() {
    const url = AppConfig.ApiUrlTaskTaskProfilesData;
    return get<TaskProfilesDto['TaskTaskProfiles']>(url);
}

export function insertTaskGoals(loginUserId: number, fiscalYearId: number, checked: any) {
    const url = AppConfig.ApiUrlInsertTaskGoalsData;
    return post(url, {
        loginUserId: loginUserId,
        fiscalYearId: fiscalYearId,
        taskProfileId: checked,
    });
}

export function getIcdFileTypes() {
    const url = AppConfig.ApiUrlIcdFileTypeData;
    return get<IcdImportDto['IcdFileTypes']>(url);
}

export function getIcdImportHistories() {
    const url = AppConfig.ApiUrlIcdImportHistoryData;
    return get<IcdImportDto['IcdImportHistories']>(url);
}
export function getSkillComparisonUsers() {
    const url = AppConfig.ApiUrlSkillComparisonUsersData;
    return get<ComparisonDto['ComparisonUsersDto']>(url);
}

export function executeIcdImport(
    loginUserId: number,
    fiscalYearId: number,
    icdFileType: number,
    icdImportFile: File
) {
    const url = AppConfig.ApiUrlIcdImport;
    const form = new FormData();
    form.append('loginUserId', loginUserId !== undefined ? loginUserId.toString() : '');
    form.append('fiscalYearId', fiscalYearId !== undefined ? fiscalYearId.toString() : '');
    form.append('icdFileType', icdFileType !== undefined ? icdFileType.toString() : '');
    form.append('icdImportFile', icdImportFile);
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    return post(url, form, config);
}

export function getTaskComparisonUsers() {
    const url = AppConfig.ApiUrlTaskComparisonUsersData;
    return get<ComparisonDto['ComparisonUsersDto']>(url);
}

export function getSkillSummary(fiscalYearId: number) {
    const url = AppConfig.ApiUrlSkillSummaryData;
    const params = { fiscalYearId };
    return get<ComparisonDto['ComparisonSummaryDto']>(url, params);
}

export function getTaskSummary(fiscalYearId: number) {
    const url = AppConfig.ApiUrlTaskSummaryData;
    const params = { fiscalYearId };
    return get<ComparisonDto['ComparisonSummaryDto']>(url, params);
}

export function getSkillGroupSummary(fiscalYearId: number, loginUserId: number) {
    const url = AppConfig.ApiUrlSkillGroupSummaryData;
    const params = { fiscalYearId, userId: loginUserId };
    return get<ComparisonDto['ComparisonSummaryDto']>(url, params);
}

export function getTaskGroupSummary(fiscalYearId: number, loginUserId: number) {
    const url = AppConfig.ApiUrlTaskGroupSummaryData;
    const params = { fiscalYearId, userId: loginUserId };
    return get<ComparisonDto['ComparisonSummaryDto']>(url, params);
}

export function getUsersList() {
    const url = AppConfig.ApiUrlUsersListData;
    return get<ComparisonDto['UsersListDto']>(url);
}

export function getYearsList() {
    const url = AppConfig.ApiUrlYearsListData;
    return get<ComparisonDto['YearsListDto']>(url);
}

// iCDメンテナンス画面用APIコール

export async function initiCDMaintenanceScreen(fiscalYearId: number) {
    //スキルテーブル項目
    const skillColumns = [
        { key: 'skillCategory', name: 'スキルカテゴリ' },
        { key: 'skillClassification', name: 'スキル分類' },
        { key: 'skillItem', name: 'スキル項目' },
    ];

    //各項目の値
    const SkillCategoryOption: option[] = [{ key: '', text: '' }];

    const SkillClassificationOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];

    const SkillItemsOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];

    //スキルテーブル項目
    const taskColumns = [
        { key: 'taskMajorCategory', name: 'タスク大分類' },
        { key: 'taskMiddleCategory', name: 'タスク中分類' },
        { key: 'taskMinorCategory', name: 'タスク小分類' },
    ];

    //各項目の値
    const TaskCategoryOption: option[] = [{ key: '', text: '' }];

    const TaskClassificationOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];

    const TaskItemsOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];

    const url = AppConfig.ApiUrlInitiCDMaintenanceScreen;
    const params = { fiscalYearId };
    const data = await get<iCDMaintenanceScreenData>(url, params);

    // テーブルフィルタ用ドロップダウンリストの生成
    data.skillData.map((skill) => {
        if (!SkillCategoryOption.some((a) => a.key === skill.skillCategoryId.toString())) {
            SkillCategoryOption.push({
                key: skill.skillCategoryId.toString(),
                text: skill.skillCategory,
            });
        }
        if (
            !SkillClassificationOption.some((a) => a.key === skill.skillClassificationId.toString())
        ) {
            SkillClassificationOption.push({
                key: skill.skillClassificationId.toString(),
                key2: skill.skillCategoryId.toString(),
                text: skill.skillClassification,
            });
        }
        if (!SkillItemsOption.some((a) => a.key === skill.skillItemsId.toString())) {
            SkillItemsOption.push({
                key: skill.skillItemsId.toString(),
                key2: skill.skillClassificationId.toString(),
                text: skill.skillItem,
            });
        }
        skill.screenDisplay =
            skill.skillCategoryDisplay && skill.skillClassificationDisplay && skill.display
                ? '表示中'
                : '非表示';
        return skill;
    });

    data.taskData.map((task) => {
        if (!TaskCategoryOption.some((a) => a.key === task.taskMajorCategoryId.toString())) {
            TaskCategoryOption.push({
                key: task.taskMajorCategoryId.toString(),
                text: task.taskMajorCategory,
            });
        }
        if (!TaskClassificationOption.some((a) => a.key === task.taskMiddleCategoryId.toString())) {
            TaskClassificationOption.push({
                key: task.taskMiddleCategoryId.toString(),
                key2: task.taskMajorCategoryId.toString(),
                text: task.taskMiddleCategory,
            });
        }
        if (!TaskItemsOption.some((a) => a.key === task.taskMinorCategoryId.toString())) {
            TaskItemsOption.push({
                key: task.taskMinorCategoryId.toString(),
                key2: task.taskMiddleCategoryId.toString(),
                text: task.taskMinorCategory,
            });
        }
        task.screenDisplay =
            task.taskMajorCategoryDisplay && task.taskMiddleCategoryDisplay && task.display
                ? '表示中'
                : '非表示';
        return task;
    });

    const SkillFieldOption: option[] = [{ key: '', text: '' }];
    const TaskFieldOption: option[] = [{ key: '', text: '' }];
    const addSkillCategoryOption: option[] = [{ key: '', text: '' }];
    const addSkillClassificationOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];
    const addTaskCategoryOption: option[] = [{ key: '', text: '' }];
    const addTaskClassificationOption: ClassificationOption[] = [{ key: '', key2: '', text: '' }];

    data.skillFieldData.forEach((skillField) => {
        SkillFieldOption.push({ key: skillField.id.toString(), text: skillField.skillFieldName });
    });
    data.taskFieldData.forEach((taskField) => {
        TaskFieldOption.push({ key: taskField.id.toString(), text: taskField.taskFieldName });
    });

    // 追加モーダル用ドロップダウンリストの生成
    // フィルタ用ドロップダウンと異なり、全件で生成するので別処理で生成
    data.skillCategoryData.forEach((skillCategory) => {
        addSkillCategoryOption.push({
            key: skillCategory.id.toString(),
            text: skillCategory.customName ? skillCategory.customName : skillCategory.skillCategory,
        });
    });

    data.skillClassificationData.forEach((skillClassification) => {
        addSkillClassificationOption.push({
            key: skillClassification.id.toString(),
            key2: skillClassification.skillCategoryId.toString(),
            text: skillClassification.customName
                ? skillClassification.customName
                : skillClassification.skillClassification,
        });
    });

    data.taskMajorCategoryData.forEach((taskMajorCategory) => {
        addTaskCategoryOption.push({
            key: taskMajorCategory.id.toString(),
            text: taskMajorCategory.customName
                ? taskMajorCategory.customName
                : taskMajorCategory.taskMajorCategory,
        });
    });

    data.taskMiddleCategoryData.forEach((taskMiddleCategory) => {
        addTaskClassificationOption.push({
            key: taskMiddleCategory.id.toString(),
            key2: taskMiddleCategory.taskMajorCategoryId.toString(),
            text: taskMiddleCategory.customName
                ? taskMiddleCategory.customName
                : taskMiddleCategory.taskMiddleCategory,
        });
    });

    return {
        skill: {
            skillData: data.skillData,
            skillColumns: skillColumns,
            SkillCategoryOption: SkillCategoryOption,
            SkillClassificationOption: SkillClassificationOption,
            SkillItemsOption: SkillItemsOption,
            SkillFieldOption: SkillFieldOption,
            addSkillCategoryOption: addSkillCategoryOption,
            addSkillClassificationOption: addSkillClassificationOption,
        },
        task: {
            taskData: data.taskData,
            taskColumns: taskColumns,
            TaskCategoryOption: TaskCategoryOption,
            TaskClassificationOption: TaskClassificationOption,
            TaskItemsOption: TaskItemsOption,
            TaskFieldOption: TaskFieldOption,
            addTaskCategoryOption: addTaskCategoryOption,
            addTaskClassificationOption: addTaskClassificationOption,
        },
    };
}

export async function updateSkillVisible(data: iCDSkillItem, visible: boolean, target: string) {
    let url = AppConfig.ApiUrliCDMaintenanceVisibleSkillItem;
    let id = 0;
    switch (target) {
        case 'Category':
            url = AppConfig.ApiUrliCDMaintenanceVisibleSkillCategory;
            id = data.skillCategoryId;
            break;
        case 'Classification':
            url = AppConfig.ApiUrliCDMaintenanceVisibleSkillClassification;
            id = data.skillClassificationId;
            break;
        case 'Item':
            id = data.skillItemsId;
            break;
    }
    return post(url, {
        id: id,
        visible: visible,
    });
}

export async function updateTaskVisible(
    data: iCDTaskMinorCategory,
    visible: boolean,
    target: string
) {
    let url = AppConfig.ApiUrliCDMaintenanceVisibleTaskMinorCategory;
    let id = 0;
    switch (target) {
        case 'Category':
            url = AppConfig.ApiUrliCDMaintenanceVisibleTaskMajorCategory;
            id = data.taskMajorCategoryId;
            break;
        case 'Classification':
            url = AppConfig.ApiUrliCDMaintenanceVisibleTaskMiddleCategory;
            id = data.taskMiddleCategoryId;
            break;
        case 'Item':
            id = data.taskMinorCategoryId;
            break;
    }
    return post(url, {
        id: id,
        visible: visible,
    });
}

export async function saveiCDData(fiscalYearId: number, data: iCDMaintenanceAddData) {
    let url = AppConfig.ApiUrliCDMaintenanceCreateSkillCategory;
    let addData = data.saveText.Category;
    let categoryId = 0;
    let classificationId = 0;
    let fieldId = 0;
    switch (data.saveMode) {
        case 'Category':
            url =
                data.skillMode === 'skill'
                    ? AppConfig.ApiUrliCDMaintenanceCreateSkillCategory
                    : AppConfig.ApiUrliCDMaintenanceCreateTaskMajorCategory;
            break;
        case 'Classification':
            url =
                data.skillMode === 'skill'
                    ? AppConfig.ApiUrliCDMaintenanceCreateSkillClassification
                    : AppConfig.ApiUrliCDMaintenanceCreateTaskMiddleCategory;
            addData = data.saveText.Classification;
            categoryId = data.categoryKey ? Number(data.categoryKey) : 0;
            break;
        case 'Item':
            url =
                data.skillMode === 'skill'
                    ? AppConfig.ApiUrliCDMaintenanceCreateSkillItem
                    : AppConfig.ApiUrliCDMaintenanceCreateTaskMinorCategory;
            addData = data.saveText.Item;
            categoryId = data.categoryKey ? Number(data.categoryKey) : 0;
            classificationId = data.classificationKey ? Number(data.classificationKey) : 0;
            fieldId = data.fieldId ? Number(data.fieldId) : 0;
            break;
    }
    const saveData = {
        fiscalYearId: fiscalYearId,
        categoryId: categoryId,
        classificationId: classificationId,
        fieldId: fieldId,
        data: addData,
    };
    return post(url, saveData);
}

export async function updateiCDData(data: iCDMaintenanceAddData, id: number) {
    let url = AppConfig.ApiUrliCDMaintenanceUpdateSkillCategory;
    let addData = data.saveText.Category;
    let fieldId = 0;
    switch (data.saveMode) {
        case 'Category':
            url =
                data.skillMode === 'skill'
                    ? AppConfig.ApiUrliCDMaintenanceUpdateSkillCategory
                    : AppConfig.ApiUrliCDMaintenanceUpdateTaskMajorCategory;
            break;
        case 'Classification':
            url =
                data.skillMode === 'skill'
                    ? AppConfig.ApiUrliCDMaintenanceUpdateSkillClassification
                    : AppConfig.ApiUrliCDMaintenanceUpdateTaskMiddleCategory;
            addData = data.saveText.Classification;
            break;
        case 'Item':
            url =
                data.skillMode === 'skill'
                    ? AppConfig.ApiUrliCDMaintenanceUpdateSkillItem
                    : AppConfig.ApiUrliCDMaintenanceUpdateTaskMinorCategory;
            addData = data.saveText.Item;
            fieldId = data.fieldId ? Number(data.fieldId) : 0;
            break;
    }
    const saveData = {
        id: id,
        fieldId: fieldId,
        data: addData,
    };
    return post(url, saveData);
}

export async function deleteSkill(data: iCDSkillItem, target: string) {
    let url = AppConfig.ApiUrliCDMaintenanceDeleteSkillItem;
    let id = 0;
    switch (target) {
        case 'Category':
            url = AppConfig.ApiUrliCDMaintenanceDeleteSkillCategory;
            id = data.skillCategoryId;
            break;
        case 'Classification':
            url = AppConfig.ApiUrliCDMaintenanceDeleteSkillClassification;
            id = data.skillClassificationId;
            break;
        case 'Item':
            id = data.skillItemsId;
            break;
    }
    return post(url, {
        id: id,
    });
}

export async function deleteTask(data: iCDTaskMinorCategory, target: string) {
    let url = AppConfig.ApiUrliCDMaintenanceDeleteTaskMinorCategory;
    let id = 0;
    switch (target) {
        case 'Category':
            url = AppConfig.ApiUrliCDMaintenancDeleteTaskMajorCategory;
            id = data.taskMajorCategoryId;
            break;
        case 'Classification':
            url = AppConfig.ApiUrliCDMaintenanceDeleteTaskMiddleCategory;
            id = data.taskMiddleCategoryId;
            break;
        case 'Item':
            id = data.taskMinorCategoryId;
            break;
    }
    return post(url, {
        id: id,
    });
}
