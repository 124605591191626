import React, { useState, useEffect, useCallback } from 'react';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../stores';
import { GoalsBarChart } from './chart/GoalsBarChart';
import { TendencyRadarChart } from './chart/TendencyRadarChart';
import { getUserChartData } from '../api/call';
import { setLoader, deleteLoader } from '../stores/ui/ui';
import { ChartData } from '../types/types';

export const Home = () => {
    const dispatch = useDispatch();
    //State
    // 全体stateより取得
    const loginUser = useSelector((state: RootState) => state.user);
    // 単体State
    const [chartData, setUserChartData] = useState<ChartData>();

    const rowStyle = { marginLeft: '20px', marginRight: '20px' };

    const setUserChart = useCallback(async () => {
        try {
            if (loginUser.id && loginUser.fiscalYearId) {
                dispatch(setLoader('UserDetail'));
                const data = await getUserChartData(loginUser.id, loginUser.fiscalYearId);
                setUserChartData(data);
            }
        } catch (err) {
            console.log('Home#setUserChart', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('UserDetail'));
        }
    }, [dispatch, loginUser.fiscalYearId, loginUser.id]);

    useEffect(() => {
        setUserChart();
    }, [setUserChart]);

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg12" style={{ marginTop: '20px' }}>
                <div className="ms-Grid-row" style={rowStyle}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <h2 style={{ marginTop: '4px' }}>
                            {loginUser.groupName + '  ' + loginUser.name}
                        </h2>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <h2 style={{ marginTop: '4px', textAlign: 'right' }}>
                            {'最終更新日：'}
                            {chartData && chartData?.latestUpdate ? chartData?.latestUpdate : '-'}
                        </h2>
                    </div>
                </div>
                <div className="ms-Grid-row" style={rowStyle}>
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6"
                        style={{ minWidth: '400px' }}
                    >
                        <div className="ms-Grid-row" style={rowStyle}>
                            <h3>スキル傾向</h3>
                        </div>
                        <div className="ms-Grid-row" style={rowStyle}>
                            <TendencyRadarChart
                                kbn={'skill'}
                                userId={loginUser.id!}
                                fiscalYearId={loginUser.fiscalYearId!}
                                data={chartData?.skillChart}
                                name={'自己スキル'}
                            />
                        </div>
                    </div>

                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg6"
                        style={{ minWidth: '400px' }}
                    >
                        <div className="ms-Grid-row" style={rowStyle}>
                            <h3>タスク傾向</h3>
                        </div>
                        <div className="ms-Grid-row" style={rowStyle}>
                            <TendencyRadarChart
                                kbn={'task'}
                                userId={loginUser.id!}
                                fiscalYearId={loginUser.fiscalYearId!}
                                data={chartData?.taskChart}
                                name={'自己タスク'}
                            />
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row" style={rowStyle}>
                    <div className="ms-Grid-row" style={rowStyle}>
                        <h3>スキル目標</h3>
                    </div>
                    <div className="ms-Grid-row" style={rowStyle}>
                        <GoalsBarChart
                            kbn={'skill'}
                            userId={loginUser.id!}
                            fiscalYearId={loginUser.fiscalYearId!}
                            data={chartData?.skillGoalChart}
                            keyA={'自己スキル'}
                            keyB={'目標'}
                        />
                    </div>
                </div>
                <div className="ms-Grid-row" style={rowStyle}>
                    <div className="ms-Grid-row" style={rowStyle}>
                        <h3>タスク目標</h3>
                    </div>
                    <div className="ms-Grid-row" style={rowStyle}>
                        <GoalsBarChart
                            kbn={'task'}
                            userId={loginUser.id!}
                            fiscalYearId={loginUser.fiscalYearId!}
                            data={chartData?.taskGoalChart}
                            keyA={'自己タスク'}
                            keyB={'目標'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
