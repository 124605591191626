import { DialogType, Link, SelectableOptionMenuItemType } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { useBoolean } from '@uifabric/react-hooks';
import { groupBy } from 'lodash';
import { ComboBox, PrimaryButton } from 'office-ui-fabric-react';
import { DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Row } from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    getTaskProfiles,
    getTaskProfilesDetails,
    getTaskTaskProfiles,
    getUserData,
    insertTaskGoals,
} from '../../api/call';
import { RootState } from '../../stores';
import { User } from '../../stores/loginuser';

import { closeDialog, openConfirm, openDialog } from '../../stores/ui/dialog';
import { deleteLoader, setLoader } from '../../stores/ui/ui';
import {
    FilterOption,
    TaskProfiles,
    TaskProfilesDetails,
    TaskProfilesDetailsDisplay,
    TaskProfilesDetailsOption,
    TaskProfilesDisplay,
    TaskProfilesDto,
    TaskProfilesOption,
    TaskTaskProfiles,
} from '../../types/types';
import { TableLink } from '../TableLink';
import { TableMouseOver } from '../TableMouseOver';

//保持スキル
const OwnedTasksOption: FilterOption['DefaultFilter'] = [
    { key: '1', text: '', itemType: 0 },
    { key: '2', text: '保有タスク', itemType: 0 },
    { key: '3', text: '保有していないタスク', itemType: 0 },
];

//タスクプロフィール
const TaskCheckOption: FilterOption['DefaultFilter'] = [
    { key: '1', text: '', itemType: 0 },
    { key: '2', text: '選択', itemType: 0 },
    { key: '3', text: '未選択', itemType: 0 },
];

//フィルターレイアウト
const ComboBoxCustomStyledExampleStyles = {
    input: {
        height: '28px',
        position: 'relative' as 'relative',
        bottom: '7px',
    },
};

//タスクプロフィール詳細フィルターを作成する関数
function createTaskProfilesDetailsFilter(taskProfilesDetails: TaskProfilesDetailsDisplay[] | undefined, selectTaskMajorCategory?: string,selectTaskMiddleCategory?:string,selectTaskMinorCategory?:string) {
    let rows: TaskProfilesOption | undefined = undefined;
    if (taskProfilesDetails !== undefined && taskProfilesDetails.length > 0) {
        const taskMajorCategoryOption: FilterOption['DefaultFilter'] = [];
        const taskMiddleCategoryOption: FilterOption['DefaultFilter'] = [];
        const taskMinorCategorysOption: FilterOption['DefaultFilter'] = [];
        const filteredEssencialTaskOption: FilterOption['DefaultFilter'] = [ { key: '1', text: '', itemType: 0 }];

        let taskMajorCategoryList: TaskProfilesDetailsDisplay[] = taskProfilesDetails?.filter(
            (element, index: number, self: TaskProfilesDetailsDisplay[]) =>
                self.findIndex((e) => e.taskMajorCategory === element.taskMajorCategory) === index
        );

        const filteredTaskMajorCategoryList = taskMajorCategoryList.filter((element) => (selectTaskMiddleCategory ? element.taskMiddleCategory === selectTaskMiddleCategory : true)&& (selectTaskMinorCategory ? element.taskMinorCategorys === selectTaskMinorCategory : true))
        taskMajorCategoryList = filteredTaskMajorCategoryList.length > 0 ? filteredTaskMajorCategoryList : taskMajorCategoryList;

        let taskMiddleCategoryList: TaskProfilesDetailsDisplay[] = taskProfilesDetails?.filter(
            (element, index: number, self: TaskProfilesDetailsDisplay[]) =>
                self.findIndex(
                    (e) =>
                        e.taskMajorCategory === element.taskMajorCategory &&
                        e.taskMiddleCategory === element.taskMiddleCategory
                ) === index
        );

        const filteredTaskMiddleCategoryList = taskMiddleCategoryList.filter((element) => (selectTaskMajorCategory ? element.taskMajorCategory === selectTaskMajorCategory : true)&& (selectTaskMinorCategory ? element.taskMinorCategorys === selectTaskMinorCategory : true))
        taskMiddleCategoryList = filteredTaskMiddleCategoryList.length > 0 ? filteredTaskMiddleCategoryList : taskMiddleCategoryList;

        let taskMinorCategoryList: TaskProfilesDetailsDisplay[] = taskProfilesDetails?.filter(
            (element, index: number, self: TaskProfilesDetailsDisplay[]) =>
                self.findIndex(
                    (e) =>
                        e.taskMajorCategory === element.taskMajorCategory &&
                        e.taskMiddleCategory === element.taskMiddleCategory &&
                        e.taskMinorCategorys === element.taskMinorCategorys
                ) === index
        );

        const filteredTaskMinorCategoryList = taskMinorCategoryList.filter((element) => (selectTaskMajorCategory ? element.taskMajorCategory === selectTaskMajorCategory : true)&& (selectTaskMiddleCategory ? element.taskMiddleCategory === selectTaskMiddleCategory : true))
        taskMinorCategoryList = filteredTaskMinorCategoryList.length > 0 ? filteredTaskMinorCategoryList : taskMinorCategoryList;

        //keyを紐づける
        taskMajorCategoryOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });

        taskMajorCategoryList.forEach((data, index) => {
            taskMajorCategoryOption.push({
                key: String(index + 1),
                text: data.taskMajorCategory,
                itemType: 0,
            });
        });

        let taskMajorCategoryName: string = taskMiddleCategoryList[0].taskMajorCategory;
        taskMiddleCategoryOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });
        taskMiddleCategoryOption.push(
            {
                key: taskMajorCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: taskMajorCategoryName + 'Header',
                text: taskMajorCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );

        taskMiddleCategoryList.forEach((data, index) => {
            if (data.taskMajorCategory !== taskMajorCategoryName) {
                taskMiddleCategoryOption.push(
                    {
                        key: data.taskMajorCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.taskMajorCategory + 'Header' + index,
                        text: data.taskMajorCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                taskMajorCategoryName = data.taskMajorCategory;
            }
            taskMiddleCategoryOption.push({
                key: String(index + 1),
                text: data.taskMiddleCategory,
                itemType: 0,
            });
        });

        taskMajorCategoryName = taskMinorCategoryList[0].taskMajorCategory;
        taskMinorCategorysOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });
        taskMinorCategorysOption.push(
            {
                key: taskMajorCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: taskMajorCategoryName + 'Header',
                text: taskMajorCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );
        let taskMiddleCategoryName: string = taskMinorCategoryList[0].taskMiddleCategory;
        taskMinorCategorysOption.push(
            {
                key: taskMiddleCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: taskMiddleCategoryName + 'Header',
                text: taskMiddleCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );

        taskMinorCategoryList.forEach((data, index) => {
            if (data.taskMajorCategory !== taskMajorCategoryName) {
                taskMinorCategorysOption.push(
                    {
                        key: data.taskMajorCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.taskMajorCategory + 'Header' + index,
                        text: data.taskMajorCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                taskMajorCategoryName = data.taskMajorCategory;
            }
            if (data.taskMiddleCategory !== taskMiddleCategoryName) {
                taskMinorCategorysOption.push(
                    {
                        key: data.taskMiddleCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.taskMiddleCategory + 'Header' + index,
                        text: data.taskMiddleCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                taskMiddleCategoryName = data.taskMiddleCategory;
            }
            taskMinorCategorysOption.push({
                key: String(index + 1),
                text: data.taskMinorCategorys,
                itemType: 0,
            });
            if (!filteredEssencialTaskOption.some((a) => a.text === data.target)) {
                filteredEssencialTaskOption.push({ key: String(index + 1), text: data.target, itemType: 0 });
            }
        });

        rows = {
            TaskMajorCategoryOption: taskMajorCategoryOption,
            TaskMiddleCategoryOption: taskMiddleCategoryOption,
            TaskMinorCategorysOption: taskMinorCategorysOption,
            OwnedTasksOption: OwnedTasksOption,
            EssencialTaskOption: filteredEssencialTaskOption,
        };
    }
    return rows;
}
//タスクプロフィール一覧フィルターを作成する関数
function createTaskProfilesfilter(taskProfiles: TaskProfiles[] | undefined) {
    let rows: TaskProfilesDetailsOption | undefined = undefined;
    if (taskProfiles !== undefined) {
        const taskProfileTypeOption: FilterOption['DefaultFilter'] = [];
        const taskProfileGroupOption: FilterOption['DefaultFilter'] = [];
        const taskProfileOption: FilterOption['DefaultFilter'] = [];

        //重複データ削除
        const taskProfileTypeList: TaskProfiles[] = taskProfiles?.filter(
            (element, index: number, self: TaskProfiles[]) =>
                self.findIndex((e) => e.taskProfileType === element.taskProfileType) === index
        );

        const taskProfileGroupList: TaskProfiles[] = taskProfiles?.filter(
            (element, index: number, self: TaskProfiles[]) =>
                self.findIndex(
                    (e) =>
                        e.taskProfileType === element.taskProfileType &&
                        e.taskProfileGroup === element.taskProfileGroup
                ) === index
        );

        const taskProfileList: TaskProfiles[] = taskProfiles?.filter(
            (element, index: number, self: TaskProfiles[]) =>
                self.findIndex(
                    (e) =>
                        e.taskProfileType === element.taskProfileType &&
                        e.taskProfileGroup === element.taskProfileGroup &&
                        e.taskProfile === element.taskProfile
                ) === index
        );
        //keyを紐づける
        taskProfileTypeOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });
        taskProfileTypeList.forEach((data, index) => {
            taskProfileTypeOption.push({
                key: String(index + 1),
                text: data.taskProfileType,
                itemType: 0,
            });
        });

        let taskProfileTypeName: string = taskProfileGroupList[0].taskProfileType;
        taskProfileGroupOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });
        taskProfileGroupOption.push(
            {
                key: taskProfileTypeName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: taskProfileTypeName + 'Header',
                text: taskProfileTypeName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );
        taskProfileGroupList.forEach((data, index) => {
            if (data.taskProfileType !== taskProfileTypeName) {
                taskProfileGroupOption.push(
                    {
                        key: data.taskProfileType + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.taskProfileType + 'Header' + index,
                        text: data.taskProfileType,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                taskProfileTypeName = data.taskProfileType;
            }
            taskProfileGroupOption.push({
                key: String(index + 1),
                text: data.taskProfileGroup,
                itemType: 0,
            });
        });

        taskProfileTypeName = taskProfileList[0].taskProfileType;
        taskProfileOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });

        taskProfileOption.push(
            {
                key: taskProfileTypeName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: taskProfileTypeName + 'Header',
                text: taskProfileTypeName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );
        let taskProfileGroupName: string = taskProfileList[0].taskProfileGroup;
        taskProfileOption.push(
            {
                key: taskProfileGroupName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: taskProfileGroupName + 'Header',
                text: taskProfileGroupName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );
        taskProfileList.forEach((data, index) => {
            if (data.taskProfileType !== taskProfileTypeName) {
                taskProfileOption.push(
                    {
                        key: data.taskProfileType + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.taskProfileType + 'Header' + index,
                        text: data.taskProfileType,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                taskProfileTypeName = data.taskProfileType;
            }
            if (data.taskProfileGroup !== taskProfileGroupName) {
                taskProfileOption.push(
                    {
                        key: data.taskProfileGroup + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.taskProfileGroup + 'Header' + index,
                        text: data.taskProfileGroup,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                taskProfileGroupName = data.taskProfileGroup;
            }
            taskProfileOption.push({
                key: String(index + 1),
                text: data.taskProfile,
                itemType: 0,
            });
        });

        rows = {
            TaskProfileTypeOption: taskProfileTypeOption,
            TaskProfileGroupOption: taskProfileGroupOption,
            TaskProfileOption: taskProfileOption,
            TaskCheckOption: TaskCheckOption,
        };
    }
    return rows;
}

function filterList(List: string[]) {
    return List.filter((value, index) => {
        return index === List.indexOf(value);
    });
}

//タスクプロフィール初期表示名
function initTaskProfilesName(taskProfiles: TaskProfiles[] | undefined) {
    if (taskProfiles !== undefined) {
        const initTaskProfile = taskProfiles[0];
        const initTaskProfileName = initTaskProfile.taskProfile;

        if (initTaskProfileName.length > 0) {
            return initTaskProfileName;
        } else {
            return '';
        }
    } else {
        return '';
    }
}

//タスクプロフィール初期チェック
function initCheckTask(taskProfiles: TaskProfiles[] | undefined) {
    const row: TaskProfiles['taskProfileId'][] = [];
    if (taskProfiles !== undefined) {
        const checkTaskProfiles = taskProfiles.filter((value) => {
            return value.checked;
        });

        checkTaskProfiles?.forEach((data) => {
            row.push(data.taskProfileId);
            return null;
        });
    }
    return row;
}

//全グループを開く際に使用する配列
function createOpenTaskRows(taskProfilesDetails: TaskProfilesDetails[] | undefined) {
    const taskM: string[] = [];
    const taskMM: string[] = [];
    const taskMMM: string[] = [];
    let openTaskRows = new Set(['']);

    taskProfilesDetails?.forEach((data) => {
        taskM.push(data.taskMajorCategory);
        taskMM.push(data.taskMajorCategory + '_' + data.taskMiddleCategory);
        taskMMM.push(
            data.taskMajorCategory + '_' + data.taskMiddleCategory + '_' + data.taskMinorCategory
        );
    });

    //重複データ削除
    const taskMList: string[] = filterList(taskM);
    const taskMMList: string[] = filterList(taskMM);
    const taskMMMList: string[] = filterList(taskMMM);
    const MMGroupList: string[] = taskMList.concat(taskMMList);
    const MMMGroupList: string[] = MMGroupList.concat(taskMMMList);
    for (const data of MMMGroupList) {
        openTaskRows.add(data);
    }

    return openTaskRows;
}

//メイン関数
export const TaskTarget = () => {
    const dispatch = useDispatch();
    // 全体stateより取得
    const loginUser = useSelector((state: RootState) => state.user);
    // 単体State
    const [taskProfilesDto, setTaskProfilesDto] = useState<TaskProfilesDto['TaskProfiles']>(); //タスクプロフィール一覧Dto
    const [taskProfilesDetailsDto, setTaskProfilesDetailsDto] = useState<
        TaskProfilesDto['TaskProfilesDetails']
    >(); //タスクプロフィール詳細Dto
    const [taskTaskProfilesDto, setTaskTaskProfilesDto] = useState<
        TaskProfilesDto['TaskTaskProfiles']
    >(); //タスクプロフィールスキル対応表Dto

    const [taskProfilesDetailsOption, setTaskProfilesDetailsOption] = useState<
        TaskProfilesOption | undefined
    >(); //タスクプロフィール詳細一覧フィルター配列

    const [taskProfilesOption, setTaskProfilesOption] = useState<
        TaskProfilesDetailsOption | undefined
    >(); //タスクプロフィール一覧フィルター配列

    const [openTaskGroup, setOpenTaskGroup] = useState<Set<string>>(); //グループ関係配列

    //タスクプロフィール詳細を配列にする
    const [taskRows, setTaskRows] = useState<TaskProfilesDetailsDisplay[] | undefined>([]);
    const [taskProfilesRows, setTaskProfilesRows] = useState<TaskProfilesDisplay[]>([]);
    //目標スキル
    const [checkTask, setCheckTask] = useState<number[]>([]);
    //グループ化で必要な情報
    const [expandedGroups, setExpandedGroups] = useState<any>(
        () => new Set(['taskMajorCategory', 'taskMiddleCategory'])
    );
    const groups: string[] = ['taskMajorCategory', 'taskMiddleCategory'];

    //タスクプロフィール詳細の名前
    const [taskProfileName, setTaskProfileName] = useState<string>('');

    //タスクプロフィール一覧フィルター
    const [taskProfilesFilters, setTaskProfilesFilters] = useState<
        FilterOption['TaskProfilesFilter']
    >({
        targetSelection: '',
        taskProfileTypeDisplay: '',
        taskProfileGroupDisplay: '',
        taskProfileDisplay: '',
    });

    //タスクプロフィール詳細一覧フィルター
    const [taskProfilesDetailsFilters, setTaskProfilesDetailsFilters] = useState<
        FilterOption['TaskProfilesDetailsFilter']
    >({
        taskMiddleCategoryDisplay: '',
        taskMajorCategoryDisplay: '',
        taskMinorCategorysLink: '',
        ownedTasks: '',
        target: '',
    });

    //タスクプロフィール説明で仕様するフラグ
    const [taskDescriptionTag, setTaskDescriptionTag] = useState<string | JSX.Element>('');

    //dialog系のプロパティ
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [dialogContentProps, setDialogContentProps] = useState({
        type: DialogType.largeHeader,
        title: '',
        itemsList: [{ key: 0, name: '' }],
        itemsColumns: [{ key: 'column1', name: '', fieldName: '', minWidth: 50 }],
    });

    const [selectUser, setSelectUser] = useState<User>();

    // クエリーパラメータからユーザIDと年度IDを取得する
    const urlParams = new URLSearchParams(useLocation().search);
    let targetUserId = urlParams.get('userId');
    let targetFiscalYearId = urlParams.get('fiscalYearId');

    //タスクプロフィール詳細一覧初期表示
    const createTaskRows = useCallback(
        (
            taskProfiles: TaskProfiles[] | undefined,
            taskTaskProfiles: TaskTaskProfiles[] | undefined,
            taskProfilesDetails: TaskProfilesDetails[] | undefined
        ) => {
            const rows: TaskProfilesDetailsDisplay[] | undefined = [];
            if (taskProfiles !== undefined) {
                const initTaskProfile = taskProfiles[0];
                const initTaskProfileId: number = initTaskProfile.taskProfileGroupId;
                if (taskTaskProfiles !== undefined) {
                    const taskTaskProfile: TaskTaskProfiles[] = taskTaskProfiles.filter((value) => {
                        return value.taskProfileId === initTaskProfileId;
                    });
                    taskProfilesDetails?.forEach((data, index) => {
                        if (!rows.some((a) => a.taskMinorCategorys === data.taskMinorCategory)) {
                            const target = taskTaskProfile.find((taskTaskProfiles) => {
                                return (
                                    taskTaskProfiles.taskMinorCategoryId ===
                                    data.taskMinorCategoryId
                                );
                            });
                            if (target !== undefined) {
                                const essencialTask = target.essencialTask;
                                const checkbox = (
                                    <div style={{ marginTop: 6 }}>
                                        <Checkbox disabled={true} checked={data.possessedTask} />
                                    </div>
                                );
                                rows.push({
                                    taskMajorCategory: data.taskMajorCategory,
                                    taskMajorCategoryDisplay: TableMouseOver(
                                        index,
                                        data.taskMajorCategory
                                    ),
                                    taskMiddleCategory: data.taskMiddleCategory,
                                    taskMiddleCategoryDisplay: TableMouseOver(
                                        index,
                                        data.taskMiddleCategory
                                    ),
                                    taskMinorCategorys: data.taskMinorCategory,
                                    taskMinorCategorysLink:
                                        data.taskMinorCategoryType === '1' ? (
                                            TableMouseOver(
                                                data.taskMinorCategoryId,
                                                data.taskMinorCategory
                                            )
                                        ) : (
                                            <Link
                                                onClick={() => {
                                                    const taskAssessmentItems = [
                                                        { key: 0, name: '' },
                                                    ];
                                                    taskProfilesDetails.forEach((value, index) => {
                                                        if (
                                                            data.taskMinorCategoryId ===
                                                            value.taskMinorCategoryId
                                                        ) {
                                                            taskAssessmentItems.push({
                                                                key: index + 1,
                                                                name: value.taskAssessmentItem,
                                                            });
                                                        }
                                                    });
                                                    taskAssessmentItems.shift();
                                                    setDialogContentProps({
                                                        type: DialogType.largeHeader,
                                                        title: data.taskMinorCategory,
                                                        itemsList: taskAssessmentItems,
                                                        itemsColumns: [
                                                            {
                                                                key: 'column1',
                                                                name: 'タスク評価項目',
                                                                fieldName: 'name',
                                                                minWidth: 50,
                                                            },
                                                        ],
                                                    });
                                                    toggleHideDialog();
                                                }}
                                            >
                                                {TableMouseOver(
                                                    data.taskMinorCategoryId,
                                                    data.taskMinorCategory
                                                )}
                                            </Link>
                                        ),
                                    ownedTasks: checkbox,
                                    target: essencialTask,
                                });
                            }
                        }
                    });
                }
                return rows;
            }
        },
        [toggleHideDialog]
    );

    //APIからデータを取得する
    const getTasks = useCallback(async () => {
        try {
            dispatch(setLoader('TaskProfiles'));
            let userId: any, fiscalYearId: any;
            if (targetUserId && targetFiscalYearId) {
                userId = targetUserId;
                fiscalYearId = targetFiscalYearId;
            } else if (loginUser.id && loginUser.fiscalYearId) {
                userId = loginUser.id;
                fiscalYearId = loginUser.fiscalYearId;
            }
            if (userId && fiscalYearId) {
                const taskProfilesData = await getTaskProfiles(userId, fiscalYearId);
                const taskProfilesDetailsData = await getTaskProfilesDetails(userId, fiscalYearId);
                const taskTaskProfilesData = await getTaskTaskProfiles();
                const selectUser = await getUserData(userId);
                setTaskProfilesDto(taskProfilesData);
                setTaskProfilesDetailsDto(taskProfilesDetailsData);
                setTaskTaskProfilesDto(taskTaskProfilesData);
                setTaskProfilesOption(createTaskProfilesfilter(taskProfilesData));
                setTaskRows(
                    createTaskRows(taskProfilesData, taskTaskProfilesData, taskProfilesDetailsData)
                );
                setTaskProfileName(initTaskProfilesName(taskProfilesData));
                setCheckTask(initCheckTask(taskProfilesData));
                // setTaskProfilesDetailsOption(
                //     createTaskProfilesDetailsFilter(taskProfilesDetailsData)
                // );
                setOpenTaskGroup(createOpenTaskRows(taskProfilesDetailsData));
                setSelectUser(selectUser);
            }
        } catch (err) {
            console.log('TaskTarget#getTasks', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('TaskProfiles'));
        }
    }, [
        createTaskRows,
        dispatch,
        loginUser.fiscalYearId,
        loginUser.id,
        targetFiscalYearId,
        targetUserId,
    ]);

    //タスク目標更新
    const saveTaskGoals = async () => {
        try {
            dispatch(setLoader('Save'));
            let userId: any, fiscalYearId: any;
            if (targetUserId && targetFiscalYearId) {
                userId = targetUserId;
                fiscalYearId = targetFiscalYearId;
            } else if (loginUser.id && loginUser.fiscalYearId) {
                userId = loginUser.id;
                fiscalYearId = loginUser.fiscalYearId;
            }
            if (userId && fiscalYearId) {
                await insertTaskGoals(userId, fiscalYearId, checkTask);
            }
        } catch (err) {
            console.log('TaskTarget#saveTaskGoals', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('Save'));
        }
    };

    //APIからデータを取得する
    useEffect(() => {
        getTasks();
    }, [getTasks]);

    useEffect(()=> {
        setTaskProfilesDetailsOption(createTaskProfilesDetailsFilter(taskRows,
            taskProfilesDetailsFilters.taskMajorCategoryDisplay,
            taskProfilesDetailsFilters.taskMiddleCategoryDisplay,
            taskProfilesDetailsFilters.taskMinorCategorysLink,));
    },[taskRows,taskProfilesDetailsFilters.taskMajorCategoryDisplay,
        taskProfilesDetailsFilters.taskMiddleCategoryDisplay,
        taskProfilesDetailsFilters.taskMinorCategorysLink,]);

    //タスクプロフィールチェック処理
    const onChange = useCallback(
        (e: any, checked: boolean | undefined) => {
            if (taskProfilesDto !== undefined) {
                const target = e.target;
                const id: number = Number(target.parentElement.previousElementSibling.defaultValue);
                const taskProfilesList: TaskProfiles[] = [...taskProfilesDto];
                let taskProfileId: number = 0;
                taskProfilesList.forEach((data, index) => {
                    if (data.taskProfileId === id) {
                        taskProfileId = index;
                    }
                });

                if (checked) {
                    taskProfilesList[taskProfileId].checked = true;
                    setCheckTask(initCheckTask(taskProfilesList));
                } else {
                    taskProfilesList[taskProfileId].checked = false;
                    setCheckTask(initCheckTask(taskProfilesList));
                }

                setTaskProfilesDto(taskProfilesList);
            }
        },
        [taskProfilesDto]
    );

    //タスクプロフィール説明表示
    const overJobDescriptions = useCallback(
        (e: any) => {
            if (taskProfilesDto !== undefined) {
                const id: string = e.target.id;
                const target = taskProfilesDto.find((taskProfilesList) => {
                    return taskProfilesList.taskProfileId === parseInt(id);
                });
                const jobDescriptionText: string = target ? target.descriptionOfTaskProfile : '';
                const targetId = document.getElementById(id);
                const jobDescription = (
                    <TeachingBubble
                        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                        target={targetId}
                        isWide={true}
                        headline="タスクプロフィール説明"
                    >
                        {jobDescriptionText.split('\r\n').map((str, index) => (
                            <React.Fragment key={index}>
                                {str}
                                <br />
                            </React.Fragment>
                        ))}
                    </TeachingBubble>
                );
                setTaskDescriptionTag(jobDescription);
            }
        },
        [taskProfilesDto]
    );

    //タスクプロフィール説明
    const createTaskDescriptions = useCallback(
        (id: number) => {
            return (
                <div>
                    <Icon
                        iconName="DietPlanNotebook"
                        id={String(id)}
                        onMouseOver={overJobDescriptions}
                        onMouseOut={outJobDescriptions}
                    />
                    {taskDescriptionTag}
                </div>
            );
        },
        [overJobDescriptions, taskDescriptionTag]
    );

    //タスクプロフィール一覧のテーブル項目
    const createJobRows = useCallback(
        (taskProfiles: TaskProfiles[] | undefined) => {
            const rows: TaskProfilesDisplay[] = [];
            taskProfiles?.forEach((data, index) => {
                const checkbox = (
                    <div style={{ marginTop: 6 }}>
                        <input type="hidden" value={data.taskProfileId} />
                        <Checkbox checked={data.checked} onChange={onChange} />
                    </div>
                );
                rows.push({
                    taskProfileGroupId: data.taskProfileId,
                    targetSelection: checkbox,
                    taskProfileType: data.taskProfileType,
                    taskProfileTypeDisplay: TableMouseOver(index, data.taskProfileType),
                    taskProfileGroup: data.taskProfileGroup,
                    taskProfileGroupDisplay: TableMouseOver(index, data.taskProfileGroup),
                    taskProfile: data.taskProfile,
                    taskProfileDisplay: TableMouseOver(index, data.taskProfile),
                    taskDescriptions: createTaskDescriptions(data.taskProfileId),
                });
                return null;
            });
            return rows;
        },
        [createTaskDescriptions, onChange]
    );

    //タスクプロフィール一覧配列の値が取得できた場合
    useMemo(() => {
        if (taskProfilesDto !== undefined) {
            setTaskProfilesRows(createJobRows(taskProfilesDto));
        }
    }, [taskProfilesDto, createJobRows]);

    //グループを全て開く
    const OpenGroup = useCallback(() => {
        setExpandedGroups(openTaskGroup);
    }, [openTaskGroup]);

    useMemo(() => {
        if (openTaskGroup !== undefined) {
            OpenGroup();
        }
    }, [OpenGroup, openTaskGroup]);

    function outJobDescriptions() {
        setTaskDescriptionTag('');
    }

    //タスクプロフィールフィルター
    const OccupationColumns = useMemo(() => {
        return [
            {
                key: 'targetSelection',
                name: '目標選択',
                width: 100,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            text={taskProfilesFilters.targetSelection}
                            options={taskProfilesOption?.TaskCheckOption}
                            onChange={(e, option) => {
                                p.onChange(option?.text);
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskProfileTypeDisplay',
                name: '種別',
                width: 150,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={taskProfilesFilters.taskProfileTypeDisplay}
                            options={taskProfilesOption?.TaskProfileTypeOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskProfileGroupDisplay',
                name: 'グループ',
                width: 200,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={taskProfilesFilters.taskProfileGroupDisplay}
                            options={taskProfilesOption?.TaskProfileGroupOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskProfileDisplay',
                name: 'タスクプロフィール',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={taskProfilesFilters.taskProfileDisplay}
                            options={taskProfilesOption?.TaskProfileOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskDescriptions',
                name: 'タスクプロフィール説明',
                width: 200,
            },
        ];
    }, [
        taskProfilesOption,
        taskProfilesFilters.targetSelection,
        taskProfilesFilters.taskProfileTypeDisplay,
        taskProfilesFilters.taskProfileGroupDisplay,
        taskProfilesFilters.taskProfileDisplay,
    ]);

    //タスクプロフィール詳細フィルター
    const filteredSkillColumns = useMemo(() => {
        return [
            {
                key: 'taskMajorCategoryDisplay',
                name: 'タスク大分類',
                width: 200,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={taskProfilesDetailsFilters.taskMajorCategoryDisplay}
                            options={taskProfilesDetailsOption?.TaskMajorCategoryOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskMiddleCategoryDisplay',
                name: 'タスク中分類',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={taskProfilesDetailsFilters.taskMiddleCategoryDisplay}
                            options={taskProfilesDetailsOption?.TaskMiddleCategoryOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskMinorCategorysLink',
                name: 'タスク小分類',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={taskProfilesDetailsFilters.taskMinorCategorysLink}
                            options={taskProfilesDetailsOption?.TaskMinorCategorysOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'ownedTasks',
                name: '保有',
                width: 100,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            text={taskProfilesDetailsFilters.ownedTasks}
                            options={taskProfilesDetailsOption?.OwnedTasksOption}
                            onChange={(e, option) => {
                                p.onChange(option?.text);
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'target',
                name: '重要度',
                width: 80,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            text={taskProfilesDetailsFilters.target}
                            options={taskProfilesDetailsOption?.EssencialTaskOption}
                            onChange={(e, option) => {
                                p.onChange(option?.text);
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
        ];
    }, [
        taskProfilesDetailsOption,
        taskProfilesDetailsFilters.taskMajorCategoryDisplay,
        taskProfilesDetailsFilters.taskMiddleCategoryDisplay,
        taskProfilesDetailsFilters.taskMinorCategorysLink,
        taskProfilesDetailsFilters.ownedTasks,
        taskProfilesDetailsFilters.target,
    ]);

    //タスクプロフィール一覧フィルター機能
    const filteredTaskProfiles = useMemo(() => {
        return taskProfilesRows.filter((r: any) => {
            return (
                (taskProfilesFilters.taskProfileDisplay
                    ? r.taskProfile.includes(taskProfilesFilters.taskProfileDisplay)
                    : true) &&
                (taskProfilesFilters.taskProfileGroupDisplay
                    ? r.taskProfileGroup.includes(taskProfilesFilters.taskProfileGroupDisplay)
                    : true) &&
                (taskProfilesFilters.taskProfileTypeDisplay
                    ? r.taskProfileType.includes(taskProfilesFilters.taskProfileTypeDisplay)
                    : true) &&
                (taskProfilesFilters.targetSelection === '選択'
                    ? r.targetSelection.props.children[1].props.checked === true
                    : true) &&
                (taskProfilesFilters.targetSelection === '未選択'
                    ? r.targetSelection.props.children[1].props.checked === false
                    : true)
            );
        });
    }, [taskProfilesRows, taskProfilesFilters]);

    //タスクプロフィール詳細一覧フィルター機能
    const filteredTaskProfilesDetails = useMemo(() => {
        if (taskRows !== undefined) {
            return taskRows.filter((r: any) => {
                return (
                    (taskProfilesDetailsFilters.taskMajorCategoryDisplay
                        ? r.taskMajorCategory.includes(
                              taskProfilesDetailsFilters.taskMajorCategoryDisplay
                          )
                        : true) &&
                    (taskProfilesDetailsFilters.taskMiddleCategoryDisplay
                        ? r.taskMiddleCategory.includes(
                              taskProfilesDetailsFilters.taskMiddleCategoryDisplay
                          )
                        : true) &&
                    (taskProfilesDetailsFilters.taskMinorCategorysLink
                        ? r.taskMinorCategorys.includes(
                              taskProfilesDetailsFilters.taskMinorCategorysLink
                          )
                        : true) &&
                    (taskProfilesDetailsFilters.ownedTasks === '保有タスク'
                        ? r.ownedTasks.props.children.props.checked === true
                        : true) &&
                    (taskProfilesDetailsFilters.ownedTasks === '保有していないタスク'
                        ? r.ownedTasks.props.children.props.checked === false
                        : true) &&
                    (taskProfilesDetailsFilters.target
                        ? r.target.includes(taskProfilesDetailsFilters.target)
                        : true)
                );
            });
        }
    }, [taskRows, taskProfilesDetailsFilters]);

    //タスクプロフィール一覧フィルタークリーン
    function clearTaskProfilesFilters() {
        setTaskProfilesFilters({
            targetSelection: '',
            taskProfileTypeDisplay: '',
            taskProfileGroupDisplay: '',
            taskProfileDisplay: '',
        });
    }

    //タスクプロフィール詳細一覧フィルタークリーン
    function clearTaskProfilesDetailsFilters() {
        setTaskProfilesDetailsFilters({
            taskMiddleCategoryDisplay: '',
            taskMajorCategoryDisplay: '',
            taskMinorCategorysLink: '',
            ownedTasks: '',
            target: '',
        });
    }

    //カラムのグループ作成
    const groupByColumn = useCallback(
        (
            rows: TaskProfilesDetailsDisplay[] | undefined,
            columnKeys: any,
            expandedGroups: any,
            treeDepth = 0,
            parentId = ''
        ) => {
            if (columnKeys.length === 0) return rows;
            const gridRows: any = [];
            const [columnKey, ...remainingColumnKeys] = columnKeys;
            const groupedRows: any = groupBy(rows, columnKey);
            const groupedKeys: any = Object.keys(groupedRows);

            for (const groupKey of groupedKeys) {
                const groupId: string = parentId ? `${parentId}_${groupKey}` : groupKey;
                const isExpanded: boolean = expandedGroups.has(groupId);
                const rowGroupHeader: any = {
                    __metaData: {
                        groupId,
                        groupKey,
                        treeDepth,
                        isExpanded,
                    },
                };
                gridRows.push(rowGroupHeader);
                if (isExpanded) {
                    gridRows.push(
                        ...groupByColumn(
                            groupedRows[groupKey],
                            remainingColumnKeys,
                            expandedGroups,
                            treeDepth + 1,
                            groupId
                        )
                    );
                }
            }
            return gridRows;
        },
        []
    );

    //グループ化
    const gridRows = useMemo(() => {
        return groupByColumn(filteredTaskProfilesDetails, groups, expandedGroups);
    }, [groupByColumn, filteredTaskProfilesDetails, groups, expandedGroups]);

    //グループ化
    function GroupRowRenderer(props: any) {
        if (props.row.__metaData === undefined) {
            return <Row {...props} />;
        }
        const { groupKey, isExpanded, treeDepth, columnGroupName, groupId } = props.row.__metaData;
        return (
            <div className="rdg-row rdg-row-default-group" tabIndex={0} style={{ top: props.top }}>
                <span
                    className="rdg-row-expand-icon"
                    style={{ marginLeft: treeDepth * 30 }}
                    onClick={() => onRowExpandToggle(groupId)}
                >
                    {isExpanded ? (
                        <Icon iconName="DoubleChevronDown" />
                    ) : (
                        <Icon iconName="DoubleChevronRight" />
                    )}
                </span>
                <strong>
                    {columnGroupName}
                    {groupKey}
                </strong>
            </div>
        );
    }

    //グループ化に使用する関数
    function onRowExpandToggle(groupId: any) {
        const newExpandedGroups = new Set(expandedGroups);
        if (newExpandedGroups.has(groupId)) {
            newExpandedGroups.delete(groupId);
        } else {
            newExpandedGroups.add(groupId);
        }
        setExpandedGroups(newExpandedGroups);
    }

    //更新処理
    const save = () => {
        dispatch(
            openConfirm({
                title: '確認',
                subText: '目標を保存します、よろしいですか？',
                //ok押下時
                primaryAction: () => {
                    saveTaskGoals();
                    dispatch(
                        openDialog({
                            title: '確認',
                            subText: '目標を保存しました',
                            primaryAction: () => {
                                dispatch(closeDialog());
                            },
                        })
                    );
                },
                //cansel押下時
                secondaryAction: () => {
                    dispatch(closeDialog());
                },
            })
        );
    };

    //タスクプロフィールクリック処理
    function onRowChick(rowId: number, row: any) {
        if (taskTaskProfilesDto !== undefined) {
            const taskProfileName: string = row.taskProfile;
            const taskProfileGroupId: number = row.taskProfileGroupId;
            const taskTaskProfiles = taskTaskProfilesDto.filter((value) => {
                return value.taskProfileId === taskProfileGroupId;
            });

            const rows: any = [];
            taskProfilesDetailsDto?.forEach((data, index) => {
                if (!rows.some((a: any) => a.taskMinorCategorys === data.taskMinorCategory)) {
                    let essencialTask = '';
                    const target = taskTaskProfiles.find((taskTaskProfiles) => {
                        return taskTaskProfiles.taskMinorCategoryId === data.taskMinorCategoryId;
                    });
                    if (target !== undefined) {
                        essencialTask = target.essencialTask;
                        const checkbox = (
                            <div style={{ marginTop: 6 }}>
                                <Checkbox disabled={true} checked={data.possessedTask} />
                            </div>
                        );
                        rows.push({
                            taskMajorCategory: data.taskMajorCategory,
                            taskMajorCategoryDisplay: TableMouseOver(index, data.taskMajorCategory),
                            taskMiddleCategory: data.taskMiddleCategory,
                            taskMiddleCategoryDisplay: TableMouseOver(
                                index,
                                data.taskMiddleCategory
                            ),
                            taskMinorCategorys: data.taskMinorCategory,
                            taskMinorCategorysLink:
                                data.taskMinorCategoryType === '1' ? (
                                    TableMouseOver(data.taskMinorCategoryId, data.taskMinorCategory)
                                ) : (
                                    <Link
                                        onClick={() => {
                                            const taskAssessmentItems = [{ key: 0, name: '' }];
                                            taskProfilesDetailsDto.forEach((value, index) => {
                                                if (
                                                    data.taskMinorCategoryId ===
                                                    value.taskMinorCategoryId
                                                ) {
                                                    taskAssessmentItems.push({
                                                        key: index + 1,
                                                        name: value.taskAssessmentItem,
                                                    });
                                                }
                                            });
                                            taskAssessmentItems.shift();
                                            setDialogContentProps({
                                                type: DialogType.largeHeader,
                                                title: data.taskMinorCategory,
                                                itemsList: taskAssessmentItems,
                                                itemsColumns: [
                                                    {
                                                        key: 'column1',
                                                        name: 'タスク評価項目',
                                                        fieldName: 'name',
                                                        minWidth: 50,
                                                    },
                                                ],
                                            });
                                            toggleHideDialog();
                                        }}
                                    >
                                        {TableMouseOver(
                                            data.taskMinorCategoryId,
                                            data.taskMinorCategory
                                        )}
                                    </Link>
                                ),
                            ownedTasks: checkbox,
                            target: essencialTask,
                        });
                    }
                }
            });
            setTaskRows(rows);
            setTaskProfileName(taskProfileName);
        }
    }

    function onTaskProfilesFiltersChange(filters: any) {
        setTaskProfilesFilters(filters);
    }

    function onTaskProfilesDetailsFiltersChange(filters: any) {
        setTaskProfilesDetailsFilters(filters);
    }

    //グループを全て閉じる
    function CloseGroup() {
        const newExpandedGroups = new Set(['']);
        setExpandedGroups(newExpandedGroups);
    }

    const selectUserDisplay = useMemo(() => {
        if (selectUser === undefined) {
            return '';
        } else {
            return '（' + selectUser.groupName + ' ' + selectUser.name + '）';
        }
    }, [selectUser]);

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
                {TableLink(hideDialog, toggleHideDialog, dialogContentProps)}
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h1>タスク目標設定{selectUserDisplay}</h1>
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h3>
                        タスクプロフィール一覧（目標として設定したいものにチェックを付けて保存してください）
                    </h3>
                </div>
            </div>
            <div
                className="ms-Grid-row ms-sm12 ms-md12 ms-lg12"
                dir="ltr"
                style={{ marginBottom: 10, textAlign: 'left' }}
            >
                <PrimaryButton text="保存" onClick={save} style={{ margin: 2 }} />
                <PrimaryButton
                    text="フィルター初期化"
                    onClick={clearTaskProfilesFilters}
                    style={{ margin: 2 }}
                />
            </div>

            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {
                        <DataGrid
                            columns={OccupationColumns}
                            rows={filteredTaskProfiles}
                            onRowClick={onRowChick}
                            height={250}
                            enableFilters={true}
                            defaultColumnOptions={{
                                resizable: true,
                            }}
                            filters={taskProfilesFilters}
                            onFiltersChange={(taskProfilesFilters) => {
                                onTaskProfilesFiltersChange(taskProfilesFilters);
                            }}
                        />
                    }
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h3>[{taskProfileName}] 習得に必要なタスク</h3>
                </div>
            </div>

            <div
                className="ms-Grid-row ms-sm12 ms-md12 ms-lg12"
                dir="ltr"
                style={{ marginBottom: 10, textAlign: 'left' }}
            >
                <PrimaryButton text="全て開く" onClick={OpenGroup} style={{ margin: 2 }} />
                <PrimaryButton text="全て閉じる" onClick={CloseGroup} style={{ margin: 2 }} />
                <PrimaryButton
                    text="フィルター初期化"
                    onClick={clearTaskProfilesDetailsFilters}
                    style={{ margin: 2 }}
                />
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {
                        <DataGrid
                            columns={filteredSkillColumns}
                            rows={gridRows}
                            height={320}
                            enableFilters={true}
                            defaultColumnOptions={{
                                resizable: true,
                            }}
                            filters={taskProfilesDetailsFilters}
                            onFiltersChange={(taskProfilesDetailsFilters) => {
                                onTaskProfilesDetailsFiltersChange(taskProfilesDetailsFilters);
                            }}
                            rowRenderer={(p) => <GroupRowRenderer {...p} />}
                        />
                    }
                </div>
            </div>
        </div>
    );
};
