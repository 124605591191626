import { DialogType, Link, SelectableOptionMenuItemType } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { useBoolean } from '@uifabric/react-hooks';
import { groupBy } from 'lodash';
import { ComboBox, PrimaryButton } from 'office-ui-fabric-react';
import { DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Row } from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    getJobs,
    getjobsDetails,
    getjobsSkill,
    getUserData,
    insertSkillGoals,
} from '../../api/call';
import { RootState } from '../../stores';
import { User } from '../../stores/loginuser';

import { closeDialog, openConfirm, openDialog } from '../../stores/ui/dialog';
import { deleteLoader, setLoader } from '../../stores/ui/ui';
import {
    FilterOption,
    Jobs,
    JobsDetails,
    JobsDetailsDisplay,
    JobsDisplay,
    JobsDto,
    JobSkill,
    SkillDetailsOption,
    SkillOption,
} from '../../types/types';
import { TableLink } from '../TableLink';
import { TableMouseOver } from '../TableMouseOver';

//保持スキル
const OwnedSkillsOption: FilterOption['DefaultFilter'] = [
    { key: '1', text: '', itemType: 0 },
    { key: '2', text: '保有スキル', itemType: 0 },
    { key: '3', text: '保有していないスキル', itemType: 0 },
];

//職種
const JobCheckOption: FilterOption['DefaultFilter'] = [
    { key: '1', text: '', itemType: 0 },
    { key: '2', text: '選択', itemType: 0 },
    { key: '3', text: '未選択', itemType: 0 },
];

//フィルターレイアウト
const ComboBoxCustomStyledExampleStyles = {
    input: {
        height: '28px',
        position: 'relative' as 'relative',
        bottom: '7px',
    },
};

//職種詳細フィルターを作成する関数
function createJobsDetailsfilter(jobsDetails: JobsDetailsDisplay[] | undefined,selectSkillCategory?: string,selectSkillClassification?:string,selectSkillItem?:string) {
    let rows: SkillOption | undefined = undefined;
    if (jobsDetails !== undefined && jobsDetails.length > 0) {
        const skillCategoryOption: FilterOption['DefaultFilter'] = [];
        const skillClassificationOption: FilterOption['DefaultFilter'] = [];
        const skillItemsOption: FilterOption['DefaultFilter'] = [];
        const filteredEssencialSkillOption: FilterOption['DefaultFilter'] = [ { key: '1', text: '', itemType: 0 }];

        let skillCategoryList: JobsDetailsDisplay[] = jobsDetails?.filter(
            (element, index: number, self: JobsDetailsDisplay[]) =>
                self.findIndex((e) => e.skillCategory === element.skillCategory) === index
        );

        const filteredSkillCategoryList = skillCategoryList.filter((element) => (selectSkillClassification ? element.skillClassification === selectSkillClassification : true) && (selectSkillItem ? element.skillItems === selectSkillItem : true))
        skillCategoryList = filteredSkillCategoryList.length > 0 ? filteredSkillCategoryList : skillCategoryList;

        let skillClassificationList: JobsDetailsDisplay[] = jobsDetails?.filter(
            (element, index: number, self: JobsDetailsDisplay[]) =>
                self.findIndex(
                    (e) =>
                        e.skillCategory === element.skillCategory &&
                        e.skillClassification === element.skillClassification
                ) === index
        );

        const filteredSkillClassificationList = skillClassificationList.filter((element) => (selectSkillCategory ? element.skillCategory === selectSkillCategory : true) && (selectSkillItem ? element.skillItems === selectSkillItem : true));
        skillClassificationList = filteredSkillClassificationList.length > 0 ? filteredSkillClassificationList : skillClassificationList;

        let skillItemList: JobsDetailsDisplay[] = jobsDetails?.filter(
            (element, index: number, self: JobsDetailsDisplay[]) =>
                self.findIndex(
                    (e) =>
                        e.skillCategory === element.skillCategory &&
                        e.skillClassification === element.skillClassification &&
                        e.skillItems === element.skillItems
                ) === index
        );

        const filteredSkillItemList = skillItemList.filter((element) => (selectSkillCategory ? element.skillCategory === selectSkillCategory : true) && (selectSkillClassification ? element.skillClassification === selectSkillClassification : true));
        skillItemList = filteredSkillItemList.length > 0 ? filteredSkillItemList : skillItemList;

        //keyを紐づける
        skillCategoryOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });

        skillCategoryList.forEach((data, index) => {
            skillCategoryOption.push({
                key: String(index + 1),
                text: data.skillCategory,
                itemType: 0,
            });
        });

        let skillCategoryName: string = skillClassificationList[0].skillCategory;
        skillClassificationOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });
        skillClassificationOption.push(
            {
                key: skillCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: skillCategoryName + 'Header',
                text: skillCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );

        skillClassificationList.forEach((data, index) => {
            if (data.skillCategory !== skillCategoryName) {
                skillClassificationOption.push(
                    {
                        key: data.skillCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.skillCategory + 'Header' + index,
                        text: data.skillCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                skillCategoryName = data.skillCategory;
            }
            skillClassificationOption.push({
                key: String(index + 1),
                text: data.skillClassification,
                itemType: 0,
            });
        });

        skillCategoryName = skillItemList[0].skillCategory;
        skillItemsOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });

        skillItemsOption.push(
            {
                key: skillCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: skillCategoryName + 'Header',
                text: skillCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );
        let skillClassificationName: string = skillItemList[0].skillClassification;
        skillItemsOption.push(
            {
                key: skillClassificationName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: skillClassificationName + 'Header',
                text: skillClassificationName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );

        skillItemList.forEach((data, index) => {
            if (data.skillCategory !== skillCategoryName) {
                skillItemsOption.push(
                    {
                        key: data.skillCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.skillCategory + 'Header' + index,
                        text: data.skillCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                skillCategoryName = data.skillCategory;
            }
            if (data.skillClassification !== skillClassificationName) {
                skillItemsOption.push(
                    {
                        key: data.skillClassification + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.skillClassification + 'Header' + index,
                        text: data.skillClassification,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                skillClassificationName = data.skillClassification;
            }
            skillItemsOption.push({ key: String(index + 1), text: data.skillItems, itemType: 0 });
            if (!filteredEssencialSkillOption.some((a) => a.text === data.target)) {
                filteredEssencialSkillOption.push({ key: String(index + 1), text: data.target, itemType: 0 });
            }

        });

        rows = {
            SkillCategoryOption: skillCategoryOption,
            SkillClassificationOption: skillClassificationOption,
            SkillItemsOption: skillItemsOption,
            OwnedSkillsOption: OwnedSkillsOption,
            EssencialSkillOption: filteredEssencialSkillOption,
        };
    }
    return rows;
}
//職種一覧フィルターを作成する関数
function createJobsfilter(jobs: Jobs[] | undefined) {
    let rows: SkillDetailsOption | undefined = undefined;
    if (jobs !== undefined) {
        const jobCategoryOption: FilterOption['DefaultFilter'] = [];
        const specializedFieldOption: FilterOption['DefaultFilter'] = [];

        //重複データ削除
        const jobCategoryList: Jobs[] = jobs?.filter(
            (element, index: number, self: Jobs[]) =>
                self.findIndex((e) => e.jobCategory === element.jobCategory) === index
        );

        const specializedFieldList: Jobs[] = jobs?.filter(
            (element, index: number, self: Jobs[]) =>
                self.findIndex(
                    (e) =>
                        e.jobCategory === element.jobCategory &&
                        e.specializedField === element.specializedField
                ) === index
        );
        //keyを紐づける
        jobCategoryOption.push({ key: '0', text: '', itemType: 0 });

        jobCategoryList.forEach((data, index) => {
            jobCategoryOption.push({ key: String(index + 1), text: data.jobCategory, itemType: 0 });
        });

        let jobCategoryName: string = specializedFieldList[0].jobCategory;
        specializedFieldOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });
        specializedFieldOption.push(
            {
                key: jobCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: jobCategoryName + 'Header',
                text: jobCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );

        specializedFieldList.forEach((data, index) => {
            if (data.jobCategory !== jobCategoryName) {
                specializedFieldOption.push(
                    {
                        key: data.jobCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.jobCategory + 'Header' + index,
                        text: data.jobCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                jobCategoryName = data.jobCategory;
            }
            specializedFieldOption.push({
                key: String(index + 1),
                text: data.specializedField,
                itemType: 0,
            });
        });

        rows = {
            OccupationOption: jobCategoryOption,
            SpecializedFieldOption: specializedFieldOption,
            JobCheckOption: JobCheckOption,
        };
    }
    return rows;
}

function filterList(List: string[]) {
    return List.filter((value, index) => {
        return index === List.indexOf(value);
    });
}

//職種初期表示名
function initJobsName(jobs: Jobs[] | undefined) {
    if (jobs !== undefined) {
        const initJob = jobs[0];
        const initJobName = initJob.specializedField;

        if (initJobName !== '-') {
            return initJobName;
        } else {
            return initJob.jobCategory;
        }
    } else {
        return '';
    }
}

//職種初期チェック
function initCheckjob(jobs: Jobs[] | undefined) {
    const row: Jobs['jobSpecializedFieldId'][] = [];
    if (jobs !== undefined) {
        const checkJobs = jobs.filter((value) => {
            return value.checked;
        });

        checkJobs?.forEach((data) => {
            row.push(data.jobSpecializedFieldId);
            return null;
        });
    }
    return row;
}

//全グループを開く際に使用する配列
function createOpenSkillRows(jobsDetails: JobsDetails[] | undefined) {
    const skillC: string[] = [];
    const skillCC: string[] = [];
    const skillCCI: string[] = [];
    let openSkillRows = new Set(['']);

    jobsDetails?.forEach((data) => {
        skillC.push(data.skillCategory);
        skillCC.push(data.skillCategory + '_' + data.skillClassification);
        skillCCI.push(data.skillCategory + '_' + data.skillClassification + '_' + data.skillItem);
    });

    //重複データ削除
    const skillCList: string[] = filterList(skillC);
    const skillCCList: string[] = filterList(skillCC);
    const skillCCIList: string[] = filterList(skillCCI);
    const CCGroupList: string[] = skillCList.concat(skillCCList);
    const CCIGroupList: string[] = CCGroupList.concat(skillCCIList);
    for (const data of CCIGroupList) {
        openSkillRows.add(data);
    }

    return openSkillRows;
}

//メイン関数
export const SkillTarget = () => {
    const dispatch = useDispatch();
    // 全体stateより取得
    const loginUser = useSelector((state: RootState) => state.user);
    // 単体State
    const [jobsDto, setjobsDto] = useState<JobsDto['Jobs']>(); //職種一覧Dto
    const [jobsDetailsDto, setjobsDetailsDto] = useState<JobsDto['JobsDetails']>(); //職種詳細Dto
    const [jobsSkillDto, setjobsSkillDto] = useState<JobsDto['JobSkill']>(); //職種スキル対応表Dto

    const [jobsDetailsOption, setJobsDetailsOption] = useState<SkillOption | undefined>(); //職種詳細一覧フィルター配列

    const [jobsOption, setJobsOption] = useState<SkillDetailsOption | undefined>(); //職種一覧フィルター配列

    const [openSkillGroup, setOpenSkillGroup] = useState<Set<string>>(); //グループ関係配列

    //職種詳細を配列にする
    const [skillRows, setSkillRows] = useState<JobsDetailsDisplay[] | undefined>([]);
    const [jobRows, setJobRows] = useState<JobsDisplay[]>([]);
    //目標スキル
    const [checkSkill, setCheckSkill] = useState<number[]>([]);
    //グループ化で必要な情報
    const [expandedGroups, setExpandedGroups] = useState<any>(
        () => new Set(['skillCategory', 'skillClassification'])
    );
    const groups: string[] = ['skillCategory', 'skillClassification'];

    //職種詳細の名前
    const [jobName, setJobName] = useState<string>('');

    //職種詳細一覧フィルター
    const [jobsDetailsFilters, setJobsDetailsFilters] = useState<FilterOption['JobsDetailsFilter']>(
        {
            skillClassificationDisplay: '',
            skillCategoryDisplay: '',
            skillItemsLink: '',
            ownedSkills: '',
            target: '',
        }
    );
    //職種一覧フィルター
    const [jobsFilters, setJobsFilters] = useState<FilterOption['JobsFilter']>({
        targetSelection: '',
        occupationDisplay: '',
        specializedFieldDisplay: '',
    });

    //職種説明で仕様するフラグ
    const [jobDescriptionTag, setjobDescriptionTag] = useState<string | JSX.Element>('');

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [dialogContentProps, setDialogContentProps] = useState({
        type: DialogType.largeHeader,
        title: '',
        itemsList: [{ key: 0, name: '' }],
        itemsColumns: [{ key: 'column1', name: '', fieldName: '', minWidth: 50 }],
    });

    const [selectUser, setSelectUser] = useState<User>();

    // クエリーパラメータからユーザIDと年度IDを取得する
    const urlParams = new URLSearchParams(useLocation().search);
    let targetUserId = urlParams.get('userId');
    let targetFiscalYearId = urlParams.get('fiscalYearId');

    //職種詳細一覧初期表示
    const createSkillRows = useCallback(
        (
            jobs: Jobs[] | undefined,
            jobsSkill: JobSkill[] | undefined,
            jobsDetails: JobsDetails[] | undefined
        ) => {
            const rows: JobsDetailsDisplay[] | undefined = [];
            if (jobs !== undefined) {
                const initJob = jobs[0];
                const initJobId: number = initJob.jobId;
                if (jobsSkill !== undefined) {
                    const jobsSKill: JobSkill[] = jobsSkill.filter((value) => {
                        return value.jobSpecializedFieldId === initJobId;
                    });

                    jobsDetails?.forEach((data, index) => {
                        if (!rows.some((a) => a.skillItems === data.skillItem)) {
                            let essencialSkill = '';
                            const target = jobsSKill.find((jobsSKill) => {
                                return jobsSKill.skillItemId === data.skillItemId;
                            });
                            if (target !== undefined) {
                                essencialSkill = target.essencialSkill;
                                const checkbox = (
                                    <div style={{ marginTop: 6 }}>
                                        <Checkbox disabled={true} checked={data.possessedSkill} />
                                    </div>
                                );
                                rows.push({
                                    skillCategory: data.skillCategory,
                                    skillCategoryDisplay: TableMouseOver(index, data.skillCategory),
                                    skillClassification: data.skillClassification,
                                    skillClassificationDisplay: TableMouseOver(
                                        index,
                                        data.skillClassification
                                    ),
                                    skillItems: data.skillItem,
                                    skillItemsLink:
                                        data.skillItemType === '1' ? (
                                            TableMouseOver(data.skillItemId, data.skillItem)
                                        ) : (
                                            <Link
                                                onClick={() => {
                                                    const skillKnowledegeItems = [
                                                        { key: 0, name: '' },
                                                    ];
                                                    jobsDetails.forEach((value, index) => {
                                                        if (
                                                            data.skillItemId === value.skillItemId
                                                        ) {
                                                            skillKnowledegeItems.push({
                                                                key: index + 1,
                                                                name: value.skillKnowledgeItem,
                                                            });
                                                        }
                                                    });
                                                    skillKnowledegeItems.shift();
                                                    setDialogContentProps({
                                                        type: DialogType.largeHeader,
                                                        title: data.skillItem,
                                                        itemsList: skillKnowledegeItems,
                                                        itemsColumns: [
                                                            {
                                                                key: 'column1',
                                                                name: 'スキル知識項目',
                                                                fieldName: 'name',
                                                                minWidth: 50,
                                                            },
                                                        ],
                                                    });
                                                    toggleHideDialog();
                                                }}
                                            >
                                                {TableMouseOver(data.skillItemId, data.skillItem)}
                                            </Link>
                                        ),
                                    ownedSkills: checkbox,
                                    target: essencialSkill,
                                });
                            }
                        }
                    });
                }
                return rows;
            }
        },
        [toggleHideDialog]
    );

    //APIからデータを取得する
    const setJobs = useCallback(async () => {
        try {
            dispatch(setLoader('Jobs'));
            let userId: any, fiscalYearId: any;
            if (targetUserId && targetFiscalYearId) {
                userId = targetUserId;
                fiscalYearId = targetFiscalYearId;
            } else if (loginUser.id && loginUser.fiscalYearId) {
                userId = loginUser.id;
                fiscalYearId = loginUser.fiscalYearId;
            }
            if (userId && fiscalYearId) {
                const jobsData = await getJobs(userId, fiscalYearId);
                const jobsDetailsData = await getjobsDetails(userId, fiscalYearId);
                const jobsSkillData = await getjobsSkill();
                const selectUser = await getUserData(userId);
                setjobsDto(jobsData);
                setjobsDetailsDto(jobsDetailsData);
                setjobsSkillDto(jobsSkillData);
                setJobsOption(createJobsfilter(jobsData));
                setSkillRows(createSkillRows(jobsData, jobsSkillData, jobsDetailsData));
                setJobName(initJobsName(jobsData));
                setCheckSkill(initCheckjob(jobsData));
                //setJobsDetailsOption(createJobsDetailsfilter(jobsDetailsData));
                setOpenSkillGroup(createOpenSkillRows(jobsDetailsData));
                setSelectUser(selectUser);
            }
        } catch (err) {
            console.log('SkillTarget#setJobs', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('Jobs'));
        }
    }, [
        createSkillRows,
        dispatch,
        loginUser.fiscalYearId,
        loginUser.id,
        targetFiscalYearId,
        targetUserId,
    ]);

    //スキル目標更新
    const saveSkillGoals = async () => {
        try {
            dispatch(setLoader('Save'));
            let userId: any, fiscalYearId: any;
            if (targetUserId && targetFiscalYearId) {
                userId = targetUserId;
                fiscalYearId = targetFiscalYearId;
            } else if (loginUser.id && loginUser.fiscalYearId) {
                userId = loginUser.id;
                fiscalYearId = loginUser.fiscalYearId;
            }
            if (userId && fiscalYearId) {
                await insertSkillGoals(userId, fiscalYearId, checkSkill);
                dispatch(deleteLoader('Save'));
                dispatch(
                    openDialog({
                        title: '確認',
                        subText: '目標を保存しました',
                        primaryAction: () => {
                            dispatch(closeDialog());
                        },
                    })
                );
            }
        } catch (err) {
            console.log('SkillTarget#saveSkillGoals', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('Save'));
        }
    };

    //APIからデータを取得する
    useEffect(() => {
        setJobs();
    }, [setJobs]);

    useEffect(()=> {
        setJobsDetailsOption(createJobsDetailsfilter(skillRows,        jobsDetailsFilters.skillCategoryDisplay,
            jobsDetailsFilters.skillClassificationDisplay,
            jobsDetailsFilters.skillItemsLink,));
    },[skillRows,jobsDetailsFilters.skillCategoryDisplay,
        jobsDetailsFilters.skillClassificationDisplay,
        jobsDetailsFilters.skillItemsLink,]);

    //職種チェック処理
    const onChange = useCallback(
        (e: any, checked: boolean | undefined) => {
            if (jobsDto !== undefined) {
                const target = e.target;
                const id: number = Number(target.parentElement.previousElementSibling.defaultValue);
                const jobList: Jobs[] = [...jobsDto];
                let specializedFieldId: number = 0;
                jobList.forEach((data, index) => {
                    if (data.jobSpecializedFieldId === id) {
                        specializedFieldId = index;
                    }
                });

                if (checked) {
                    jobList[specializedFieldId].checked = true;
                    setCheckSkill(initCheckjob(jobList));
                } else {
                    jobList[specializedFieldId].checked = false;
                    setCheckSkill(initCheckjob(jobList));
                }

                setjobsDto(jobList);
            }
        },
        [jobsDto]
    );

    //職種説明表示
    const overJobDescriptions = useCallback(
        (e: any) => {
            if (jobsDto !== undefined) {
                const id: string = e.target.id;
                const target = jobsDto.find((jobsList) => {
                    return jobsList.jobSpecializedFieldId === parseInt(id);
                });
                const jobDescriptionText: string = target ? target.comment : '';
                const targetId = document.getElementById(id);
                const jobDescription = (
                    <TeachingBubble
                        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                        target={targetId}
                        isWide={true}
                        headline="職種説明"
                    >
                        {jobDescriptionText.split('\r\n').map((str, index) => (
                            <React.Fragment key={index}>
                                {str}
                                <br />
                            </React.Fragment>
                        ))}
                    </TeachingBubble>
                );
                setjobDescriptionTag(jobDescription);
            }
        },
        [jobsDto]
    );

    //職種説明
    const createjobDescriptions = useCallback(
        (id: number) => {
            return (
                <div>
                    <Icon
                        iconName="DietPlanNotebook"
                        id={String(id)}
                        onMouseOver={overJobDescriptions}
                        onMouseOut={outJobDescriptions}
                    />
                    {jobDescriptionTag}
                </div>
            );
        },
        [jobDescriptionTag, overJobDescriptions]
    );

    //職種一覧のテーブル項目
    const createJobRows = useCallback(
        (jobs: Jobs[] | undefined) => {
            const rows: JobsDisplay[] = [];

            jobs?.forEach((data, index) => {
                const checkbox = (
                    <div style={{ marginTop: 6 }}>
                        <input type="hidden" value={data.jobSpecializedFieldId} />
                        <Checkbox checked={data.checked} onChange={onChange} />
                    </div>
                );
                rows.push({
                    jobId: data.jobSpecializedFieldId,
                    targetSelection: checkbox,
                    occupation: data.jobCategory,
                    occupationDisplay: TableMouseOver(index, data.jobCategory),
                    specializedField: data.specializedField,
                    specializedFieldDisplay: TableMouseOver(index, data.specializedField),
                    jobDescriptions: createjobDescriptions(data.jobSpecializedFieldId),
                });
                return null;
            });
            return rows;
        },
        [createjobDescriptions, onChange]
    );

    //職種一覧配列の値が取得できた場合
    useMemo(() => {
        if (jobsDto !== undefined) {
            setJobRows(createJobRows(jobsDto));
        }
    }, [jobsDto, createJobRows]);

    //グループを全て開く
    const openGroup = useCallback(() => {
        setExpandedGroups(openSkillGroup);
    }, [openSkillGroup]);

    useMemo(() => {
        if (openSkillGroup !== undefined) {
            openGroup();
        }
    }, [openSkillGroup, openGroup]);

    function outJobDescriptions() {
        setjobDescriptionTag('');
    }

    //職種フィルター
    const OccupationColumns = useMemo(() => {
        return [
            {
                key: 'targetSelection',
                name: '目標選択',
                width: 100,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            text={jobsFilters.targetSelection}
                            options={jobsOption?.JobCheckOption}
                            onChange={(e, option) => {
                                p.onChange(option?.text);
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'occupationDisplay',
                name: '職種一覧',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={jobsFilters.occupationDisplay}
                            options={jobsOption?.OccupationOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'specializedFieldDisplay',
                name: '専門分野',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={jobsFilters.specializedFieldDisplay}
                            options={jobsOption?.SpecializedFieldOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'jobDescriptions',
                name: '職種説明',
                width: 90,
            },
        ];
    }, [
        jobsOption,
        jobsFilters.targetSelection,
        jobsFilters.occupationDisplay,
        jobsFilters.specializedFieldDisplay,
    ]);

    //職種詳細フィルター
    const filteredSkillColumns = useMemo(() => {
        return [
            {
                key: 'skillCategoryDisplay',
                name: 'スキルカテゴリ',
                width: 140,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={jobsDetailsFilters.skillCategoryDisplay}
                            options={jobsDetailsOption?.SkillCategoryOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'skillClassificationDisplay',
                name: 'スキル分類',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={jobsDetailsFilters.skillClassificationDisplay}
                            options={jobsDetailsOption?.SkillClassificationOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'skillItemsLink',
                name: 'スキル項目',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={jobsDetailsFilters.skillItemsLink}
                            options={jobsDetailsOption?.SkillItemsOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'ownedSkills',
                name: '保有',
                width: 100,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            text={jobsDetailsFilters.ownedSkills}
                            options={jobsDetailsOption?.OwnedSkillsOption}
                            onChange={(e, option) => {
                                p.onChange(option?.text);
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'target',
                name: '重要度',
                width: 80,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            text={jobsDetailsFilters.target}
                            options={jobsDetailsOption?.EssencialSkillOption}
                            onChange={(e, option) => {
                                p.onChange(option?.text);
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
        ];
    }, [
        jobsDetailsOption,
        jobsDetailsFilters.skillCategoryDisplay,
        jobsDetailsFilters.skillClassificationDisplay,
        jobsDetailsFilters.skillItemsLink,
        jobsDetailsFilters.ownedSkills,
        jobsDetailsFilters.target,
    ]);

    //職種一覧フィルター機能
    const filteredJobs = useMemo(() => {
        return jobRows.filter((r: any) => {
            return (
                (jobsFilters.specializedFieldDisplay
                    ? r.specializedField.includes(jobsFilters.specializedFieldDisplay)
                    : true) &&
                (jobsFilters.occupationDisplay
                    ? r.occupation.includes(jobsFilters.occupationDisplay)
                    : true) &&
                (jobsFilters.targetSelection === '選択'
                    ? r.targetSelection.props.children[1].props.checked === true
                    : true) &&
                (jobsFilters.targetSelection === '未選択'
                    ? r.targetSelection.props.children[1].props.checked === false
                    : true)
            );
        });
    }, [jobRows, jobsFilters]);

    //職種詳細一覧フィルター機能
    const filteredJobsDetails = useMemo(() => {
        if (skillRows !== undefined) {
            return skillRows.filter((r: any) => {
                return (
                    (jobsDetailsFilters.skillCategoryDisplay
                        ? r.skillCategory.includes(jobsDetailsFilters.skillCategoryDisplay)
                        : true) &&
                    (jobsDetailsFilters.skillClassificationDisplay
                        ? r.skillClassification.includes(
                              jobsDetailsFilters.skillClassificationDisplay
                          )
                        : true) &&
                    (jobsDetailsFilters.skillItemsLink
                        ? r.skillItems.includes(jobsDetailsFilters.skillItemsLink)
                        : true) &&
                    (jobsDetailsFilters.ownedSkills === '保有スキル'
                        ? r.ownedSkills.props.children.props.checked === true
                        : true) &&
                    (jobsDetailsFilters.ownedSkills === '保有していないスキル'
                        ? r.ownedSkills.props.children.props.checked === false
                        : true) &&
                    (jobsDetailsFilters.target
                        ? r.target.includes(jobsDetailsFilters.target)
                        : true)
                );
            });
        }
    }, [skillRows, jobsDetailsFilters]);

    //職種一覧フィルタークリーン
    function clearJobsFilters() {
        setJobsFilters({
            targetSelection: '',
            occupationDisplay: '',
            specializedFieldDisplay: '',
        });
    }

    //職種詳細一覧フィルタークリーン
    function clearJobsDetailsFilters() {
        setJobsDetailsFilters({
            skillClassificationDisplay: '',
            skillCategoryDisplay: '',
            skillItemsLink: '',
            ownedSkills: '',
            target: '',
        });
    }

    //カラムのグループ作成
    const groupByColumn = useCallback(
        (
            rows: JobsDetailsDisplay[] | undefined,
            columnKeys: any,
            expandedGroups: any,
            treeDepth = 0,
            parentId = ''
        ) => {
            if (columnKeys.length === 0) return rows;
            const gridRows: any = [];
            const [columnKey, ...remainingColumnKeys] = columnKeys;
            const groupedRows: any = groupBy(rows, columnKey);
            const groupedKeys: any = Object.keys(groupedRows);

            for (const groupKey of groupedKeys) {
                const groupId: string = parentId ? `${parentId}_${groupKey}` : groupKey;
                const isExpanded: boolean = expandedGroups.has(groupId);
                const rowGroupHeader: any = {
                    __metaData: {
                        groupId,
                        groupKey,
                        treeDepth,
                        isExpanded,
                    },
                };
                gridRows.push(rowGroupHeader);
                if (isExpanded) {
                    gridRows.push(
                        ...groupByColumn(
                            groupedRows[groupKey],
                            remainingColumnKeys,
                            expandedGroups,
                            treeDepth + 1,
                            groupId
                        )
                    );
                }
            }
            return gridRows;
        },
        []
    );

    //グループ化
    const gridRows = useMemo(() => {
        return groupByColumn(filteredJobsDetails, groups, expandedGroups);
    }, [groupByColumn, filteredJobsDetails, groups, expandedGroups]);

    //グループ化
    function GroupRowRenderer(props: any) {
        if (props.row.__metaData === undefined) {
            return <Row {...props} />;
        }
        const { groupKey, isExpanded, treeDepth, columnGroupName, groupId } = props.row.__metaData;
        return (
            <div className="rdg-row rdg-row-default-group" tabIndex={0} style={{ top: props.top }}>
                <span
                    className="rdg-row-expand-icon"
                    style={{ marginLeft: treeDepth * 30 }}
                    onClick={() => onRowExpandToggle(groupId)}
                >
                    {isExpanded ? (
                        <Icon iconName="DoubleChevronDown" />
                    ) : (
                        <Icon iconName="DoubleChevronRight" />
                    )}
                </span>
                <strong>
                    {columnGroupName}
                    {groupKey}
                </strong>
            </div>
        );
    }

    //グループ化に使用する関数
    function onRowExpandToggle(groupId: any) {
        const newExpandedGroups = new Set(expandedGroups);
        if (newExpandedGroups.has(groupId)) {
            newExpandedGroups.delete(groupId);
        } else {
            newExpandedGroups.add(groupId);
        }
        setExpandedGroups(newExpandedGroups);
    }

    //更新処理
    const save = () => {
        dispatch(
            openConfirm({
                title: '確認',
                subText: '目標を保存します、よろしいですか？',
                //ok押下時
                primaryAction: () => {
                    dispatch(closeDialog());
                    saveSkillGoals();
                },
                //cancel押下時
                secondaryAction: () => {
                    dispatch(closeDialog());
                },
            })
        );
    };

    //職種クリック処理
    function onRowClick(rowId: number, row: any) {
        if (jobsSkillDto !== undefined) {
            const jobName: string = row.specializedField;
            const jobId: number = row.jobId;
            const jobsSKill = jobsSkillDto.filter((value) => {
                return value.jobSpecializedFieldId === jobId;
            });

            const rows: JobsDetailsDisplay[] = [];
            jobsDetailsDto?.forEach((data, index) => {
                if (!rows.some((a: any) => a.skillItems === data.skillItem)) {
                    let essencialSkill = '';
                    const target = jobsSKill.find((jobsSKill) => {
                        return jobsSKill.skillItemId === data.skillItemId;
                    });
                    if (target !== undefined) {
                        essencialSkill = target.essencialSkill;
                        const checkbox = (
                            <div style={{ marginTop: 6 }}>
                                <Checkbox disabled={true} checked={data.possessedSkill} />
                            </div>
                        );
                        rows.push({
                            skillCategory: data.skillCategory,
                            skillCategoryDisplay: TableMouseOver(index, data.skillCategory),
                            skillClassification: data.skillClassification,
                            skillClassificationDisplay: TableMouseOver(
                                index,
                                data.skillClassification
                            ),
                            skillItems: data.skillItem,
                            skillItemsLink:
                                data.skillItemType === '1' ? (
                                    TableMouseOver(data.skillItemId, data.skillItem)
                                ) : (
                                    <Link
                                        onClick={() => {
                                            const skillKnowledegeItems = [{ key: 0, name: '' }];
                                            jobsDetailsDto.forEach((value, index) => {
                                                if (data.skillItemId === value.skillItemId) {
                                                    skillKnowledegeItems.push({
                                                        key: index + 1,
                                                        name: value.skillKnowledgeItem,
                                                    });
                                                }
                                            });
                                            skillKnowledegeItems.shift();
                                            setDialogContentProps({
                                                type: DialogType.largeHeader,
                                                title: data.skillItem,
                                                itemsList: skillKnowledegeItems,
                                                itemsColumns: [
                                                    {
                                                        key: 'column1',
                                                        name: 'スキル知識項目',
                                                        fieldName: 'name',
                                                        minWidth: 50,
                                                    },
                                                ],
                                            });
                                            toggleHideDialog();
                                        }}
                                    >
                                        {TableMouseOver(data.skillItemId, data.skillItem)}
                                    </Link>
                                ),
                            ownedSkills: checkbox,
                            target: essencialSkill,
                        });
                    }
                }
            });
            setSkillRows(rows);
            if (jobName === '-') {
                setJobName(row.occupation);
            } else {
                setJobName(jobName);
            }
        }
    }

    function onJobsFiltersChange(filters: any) {
        setJobsFilters(filters);
    }

    function onJobsDetailsFiltersChange(filters: any) {
        setJobsDetailsFilters(filters);
    }

    //グループを全て閉じる
    function CloseGroup() {
        const newExpandedGroups = new Set(['']);
        setExpandedGroups(newExpandedGroups);
    }

    const selectUserDisplay = useMemo(() => {
        if (selectUser === undefined) {
            return '';
        } else {
            return '（' + selectUser.groupName + ' ' + selectUser.name + '）';
        }
    }, [selectUser]);

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
                {TableLink(hideDialog, toggleHideDialog, dialogContentProps)}
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h1>スキル目標設定{selectUserDisplay}</h1>
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h3>職種一覧（目標として設定したいものにチェックを付けて保存してください）</h3>
                </div>
            </div>
            <div
                className="ms-Grid-row ms-sm12 ms-md12 ms-lg12"
                dir="ltr"
                style={{ marginBottom: 10, textAlign: 'left' }}
            >
                <PrimaryButton text="保存" onClick={save} style={{ margin: 2 }} />
                <PrimaryButton
                    text="フィルター初期化"
                    onClick={clearJobsFilters}
                    style={{ margin: 2 }}
                />
            </div>

            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {
                        <DataGrid
                            columns={OccupationColumns}
                            rows={filteredJobs}
                            onRowClick={onRowClick}
                            height={250}
                            enableFilters={true}
                            defaultColumnOptions={{
                                resizable: true,
                            }}
                            filters={jobsFilters}
                            onFiltersChange={(jobsFilters) => {
                                onJobsFiltersChange(jobsFilters);
                            }}
                        />
                    }
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h3>[{jobName}] 習得に必要なスキル</h3>
                </div>
            </div>

            <div
                className="ms-Grid-row ms-sm12 ms-md12 ms-lg12"
                dir="ltr"
                style={{ marginBottom: 10, textAlign: 'left' }}
            >
                <PrimaryButton text="全て開く" onClick={openGroup} style={{ margin: 2 }} />
                <PrimaryButton text="全て閉じる" onClick={CloseGroup} style={{ margin: 2 }} />
                <PrimaryButton
                    text="フィルター初期化"
                    onClick={clearJobsDetailsFilters}
                    style={{ margin: 2 }}
                />
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {
                        <DataGrid
                            columns={filteredSkillColumns}
                            rows={gridRows}
                            height={320}
                            enableFilters={true}
                            defaultColumnOptions={{
                                resizable: true,
                            }}
                            filters={jobsDetailsFilters}
                            onFiltersChange={(jobsDetailsFilters) => {
                                onJobsDetailsFiltersChange(jobsDetailsFilters);
                            }}
                            rowRenderer={(p) => <GroupRowRenderer {...p} />}
                        />
                    }
                </div>
            </div>
        </div>
    );
};
