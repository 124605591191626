import { createSlice } from '@reduxjs/toolkit';

type User = {
    name?: string;
    id?: number;
    fiscalYearId?: number;
    group?: string;
};

type userListState = {
    currentUser?: User;
    userList?: User[];
};

// Stateの初期状態
const initialState: userListState = {
    currentUser: {
        name: '田中島 悠介',
        id: 10,
        fiscalYearId: 3,
        group: 'S6G',
    },
    userList: [
        {
            name: '田中島 悠介',
            id: 10,
            fiscalYearId: 3,
            group: 'S6G',
        },
        {
            name: '疋田 直樹',
            id: 11,
            fiscalYearId: 3,
            group: 'S6G',
        },
        {
            name: '居林 信也',
            id: 12,
            fiscalYearId: 3,
            group: 'S6G',
        },
        {
            name: '小川 環',
            id: 13,
            fiscalYearId: 3,
            group: 'S6G',
        },
        {
            name: '渡辺 大貴',
            id: 14,
            fiscalYearId: 3,
            group: 'S6G',
        },
        {
            name: '林 幸宏',
            id: 15,
            fiscalYearId: 3,
            group: 'S5G',
        },
    ],
};

// Sliceを生成する
const slice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setUserList: (state, action) => {
            state.userList = action.payload;
        },
    },
});

export const userListReducer = slice.reducer;

// Action Creatorsをエクスポートする
export const { setUserList } = slice.actions;
