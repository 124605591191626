import React, { useState, useEffect, useCallback } from 'react';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { useSelector, useDispatch } from 'react-redux';

import { setLoader, deleteLoader } from '../../stores/ui/ui';
import { setError } from '../../stores/error';
import { initiCDMaintenanceScreen } from '../../api/call';
import { iCDSkillScreenData, iCDTaskScreenData } from '../../types/types';
import { RootState } from '../../stores';
import { IcdSkillMaintenance } from './IcdSkillMaintenance';
import { IcdTaskMaintenance } from './IcdTaskMaintenance';

import 'react-data-grid/dist/react-data-grid.css';

export const IcdMaintenance = () => {
    const dispatch = useDispatch();

    const loginUser = useSelector((state: RootState) => state.user);

    let [skillData, setSkillData] = useState<iCDSkillScreenData>();
    let [taskData, setTaskData] = useState<iCDTaskScreenData>();

    const initScreen = useCallback(async () => {
        try {
            if (loginUser) {
                // 権限制御
                if (loginUser.roleId !== 3) {
                    dispatch(setError('システム管理者でログインしてください'));
                }
                if (loginUser.fiscalYearId) {
                    dispatch(setLoader('iCDMaintenanceData'));
                    const data = await initiCDMaintenanceScreen(loginUser.fiscalYearId);
                    setSkillData(data.skill);
                    setTaskData(data.task);
                }
            }
        } catch (err) {
            console.log('IcdImport#execute', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('iCDMaintenanceData'));
        }
    }, [dispatch, loginUser]);

    useEffect(() => {
        initScreen();
    }, [initScreen]);

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h1>iCDメンテナンス</h1>
                </div>
            </div>
            <Pivot aria-label="SkillTab">
                <PivotItem
                    headerText="スキル"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'スキル',
                    }}
                >
                    {skillData ? (
                        <IcdSkillMaintenance skillData={skillData} init={initScreen} />
                    ) : (
                        ''
                    )}
                </PivotItem>

                <PivotItem headerText="タスク">
                    {taskData ? <IcdTaskMaintenance taskData={taskData} init={initScreen} /> : ''}
                </PivotItem>
            </Pivot>
        </div>
    );
};
