import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTopClass extends Component<any, any> {
    componentDidUpdate(prevProps: any) {
        // クエリパラメータまで含んで比較していたので、画面までで比較するよう修正
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export const ScrollToTop = withRouter(ScrollToTopClass);
