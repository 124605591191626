import { createSlice } from '@reduxjs/toolkit';

export interface dialog {
    title: string;
    subText: string;
    cationText?: string;
    isOpen: boolean;
    confirm: boolean;
    result: boolean;
    primaryAction?: () => void;
    secondaryAction?: () => void;
    close?: () => void;
}

// Stateの初期状態
const initialState: dialog = {
    title: 'test',
    subText: 'test',
    isOpen: false,
    confirm: false,
    result: false,
    primaryAction: undefined,
    secondaryAction: undefined,
    close: undefined,
};

// Sliceを生成する
const slice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        openDialog: (state, action) => {
            state.title = action.payload.title;
            state.subText = action.payload.subText;
            state.cationText = action.payload.cationText;
            state.isOpen = true;
            state.confirm = false;
            state.primaryAction = action.payload.primaryAction;
            state.secondaryAction = action.payload.secondaryAction;
            state.close = action.payload.close;
        },
        openConfirm: (state, action) => {
            state.title = action.payload.title;
            state.subText = action.payload.subText;
            state.cationText = action.payload.cationText;
            state.isOpen = true;
            state.confirm = true;
            state.primaryAction = action.payload.primaryAction;
            state.secondaryAction = action.payload.secondaryAction;
        },
        closeDialog: (state) => {
            state.title = '';
            state.subText = '';
            state.cationText = '';
            state.isOpen = false;
            state.confirm = false;
            state.primaryAction = undefined;
            state.secondaryAction = undefined;
            state.close = undefined;
        },
        // etc...
    },
});

// Reducerをエクスポートする
export const dialogReducer = slice.reducer;

// Action Creatorsをエクスポートする
export const { openDialog, openConfirm, closeDialog } = slice.actions;
