import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
} from 'office-ui-fabric-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/index';

export interface ConfirmDialogProps {
    title?: string;
    subText?: string;
    cationText?: string;
    isOpen: boolean;
    confirm: boolean;
    primaryAction?: () => void;
    secondaryAction?: () => void;
    close: () => void;
}

export const ConfirmDialog = () => {
    const {
        title,
        subText,
        cationText,
        isOpen,
        primaryAction,
        secondaryAction,
        close,
        confirm,
    } = useSelector((state: RootState) => state.dialog);

    const secondaryActionCallback = () => {
        if (secondaryAction) {
            secondaryAction();
        } else {
            //close();
        }
    };

    return (
        <Dialog
            hidden={!isOpen}
            onDismiss={close}
            dialogContentProps={{
                type: DialogType.normal,
                title,
                subText,
            }}
            modalProps={{
                isBlocking: false,
                containerClassName: 'dialog',
                styles: { main: { maxWidth: 200 } },
            }}
        >
            {cationText ? <div style={{ color: 'red' }}>{cationText} </div> : ''}
            <DialogFooter>
                <PrimaryButton onClick={primaryAction} text="OK" />
                {confirm ? <DefaultButton onClick={secondaryActionCallback} text="Cancel" /> : ''}
            </DialogFooter>
        </Dialog>
    );
};
