import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../stores/index';

/**
 * ローディングインジケーター
 * @param props
 * @constructor
 */
export const Loading = () => {
    const isLoading = useSelector((state: RootState) => state.ui.isLoading);
    return (
        <div>
            {isLoading.length > 0 ? (
                <div
                    style={{
                        zIndex: 1000,
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        display: 'grid',
                        backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    }}
                >
                    <Spinner
                        size={SpinnerSize.large}
                        label={isLoading + '読み込み中...'}
                        labelPosition={'left'}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
