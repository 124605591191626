import React from 'react';
import { ITooltipHostStyles, TooltipHost } from '@fluentui/react';

export const TableMouseOver = (id: number, data: string) => {
    const calloutProps = { gapSpace: 0 };
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

    function getTooltip() {
        const tooltip = (
            <TooltipHost
                content={data}
                id={String(id)}
                calloutProps={calloutProps}
                styles={hostStyles}
                directionalHint={5}
            >
                <div aria-describedby={String(id)}>{data}</div>
            </TooltipHost>
        );
        return tooltip;
    }
    return getTooltip();
};
