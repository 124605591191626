import React, { useEffect, useState } from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { PrimaryButton, Stack } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
} from 'office-ui-fabric-react/lib/DetailsList';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

import { useDispatch, useSelector } from 'react-redux';
import { getActiveUsers, getUserChartData } from '../../api/call';

import { RootState } from '../../stores';
import { User } from '../../stores/loginuser';
import { deleteLoader, setLoader } from '../../stores/ui/ui';
import { ChartData } from '../../types/types';
import { GoalsBarChart } from '../chart/GoalsBarChart';
import { TendencyRadarChart } from '../chart/TendencyRadarChart';
import { IGroup } from '@fluentui/react';

const stackTokens = {
    childrenGap: 5,
};

const borderRowStyle = {
    paddingBottom: '5px',
    borderBottom: 'solid 1px #0078d4',
};

type UserWithKey = User & { key?: number };

export const OverView = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loginUser = useSelector((state: RootState) => state.user);
    const [users, setUsers] = useState([] as UserWithKey[]);
    const [userListItems, setUserListItems] = useState([] as UserWithKey[]);
    const [group, setGroup] = useState([] as IGroup[]);
    const [chartData, setUserChartData] = useState<ChartData>();
    const [detailsListSelection, setDetailsListSelection] = useState();
    const urlParams = new URLSearchParams(useLocation().search);
    const targetUserId = Number(urlParams.get('userId') ?? loginUser.id!);
    const targetFiscalYearId = Number(urlParams.get('fiscalYearId') ?? loginUser.fiscalYearId!);
    const [selectedUser, setSelectedUser] = useState({} as User);

    // 初期表示データ取得
    useEffect(() => {
        const getInitialData = async () => {
            try {
                dispatch(setLoader('UserDetail'));

                // ユーザーデータを取得
                const users = await getActiveUsers();
                const usersWithKey = users.map((user) => ({ ...user, key: user.id }));
                setUsers(usersWithKey);
                setUserListItems(usersWithKey);
                setSelectedUser(users.filter((user) => user.id === targetUserId)[0]);

                // noinspection JSUnusedGlobalSymbols
                const sel = new Selection({
                    onSelectionChanged: () => {
                        if (sel.getSelection()[0]) {
                            const selectedUserId = sel.getSelection()[0].key as number;
                            if (selectedUserId && users && users.length > 0) {
                                history.replace(
                                    `/overview?userId=${selectedUserId}&fiscalYearId=${targetFiscalYearId}`
                                );
                                setSelectedUser(
                                    users.filter((user) => user.id === selectedUserId)[0]
                                );
                                // グラフデータを取得
                                getUserChartData(selectedUserId, targetFiscalYearId!).then((data) =>
                                    setUserChartData(data)
                                );
                            }
                        }
                    },
                });
                setDetailsListSelection(sel);

                // グラフデータを取得
                const data = await getUserChartData(targetUserId, targetFiscalYearId!);
                setUserChartData(data);

                //userListグループ化
                setGroup(createGroup(usersWithKey));
            } catch (err) {
                console.log('Overview#getInitialData', JSON.stringify(err));
                alert(JSON.stringify(err));
                // dispatch(setError(err.message));
            } finally {
                dispatch(deleteLoader('UserDetail'));
            }
        };
        getInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (detailsListSelection) {
            const sel = detailsListSelection as Selection;
            sel.setKeySelected(selectedUser.id!.toString(), true, true);
        }
    });

    if (!selectedUser || !detailsListSelection) {
        return <h1>Loading...</h1>;
    }

    const rowStyle = {
        marginLeft: '20px',
        marginRight: '20px',
    };

    const exampleChildClass = mergeStyles({
        display: 'block',
        marginBottom: '10px',
    });

    const textFieldStyles = { root: { maxWidth: '300px' } };

    const _columns = [
        {
            key: 'column1',
            name: '氏名',
            fieldName: 'name',
            minWidth: 50,
            maxWidth: 200,
            isResizable: false,
        },
    ];

    // 詳細のURLを組み立てる
    let detailsUrl = 'details';
    let comparisonUrl = '/Comparison';
    let skilltargetUrl = '/skilltarget';
    let tasktargetUrl = '/tasktarget';
    if (selectedUser.id && targetFiscalYearId) {
        const urlParams = new URLSearchParams();
        urlParams.set('userId', selectedUser.id.toString());
        urlParams.set('fiscalYearId', targetFiscalYearId.toString());
        // 詳細ボタン
        detailsUrl += '?' + urlParams.toString();
        // 比較ボタン
        comparisonUrl += '?' + urlParams.toString();
        // スキル目標設定ボタン
        skilltargetUrl += '?' + urlParams.toString();
        // タスク目標設定ボタン
        tasktargetUrl += '?' + urlParams.toString();
    }

    const Buttons = withRouter(({ history }) => (
        <Stack horizontal tokens={stackTokens}>
            <Stack.Item align="center">
                <PrimaryButton
                    text="詳細"
                    onClick={(event) => {
                        event.preventDefault();
                        history.push(detailsUrl);
                    }}
                />
            </Stack.Item>
            <Stack.Item align="center">
                <PrimaryButton
                    text="比較"
                    onClick={(event) => {
                        event.preventDefault();
                        history.push(comparisonUrl);
                    }}
                />
            </Stack.Item>
            <Stack.Item align="center">
                <PrimaryButton
                    text="スキル目標設定"
                    onClick={(event) => {
                        event.preventDefault();
                        history.push(skilltargetUrl);
                    }}
                />
            </Stack.Item>
            <Stack.Item align="center">
                <PrimaryButton
                    text="タスク目標設定"
                    onClick={(event) => {
                        event.preventDefault();
                        history.push(tasktargetUrl);
                    }}
                />
            </Stack.Item>
        </Stack>
    ));

    function _onFilter(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string) {
        const filteredUsers =
            text === ''
                ? users
                : users.filter((user) => user.name!.toLowerCase().indexOf(text ?? '') > -1);
        if(filteredUsers.length > 0){
            setGroup(createGroup(filteredUsers));
        }
        setUserListItems(filteredUsers);
    }

    
    function createGroup(users:UserWithKey[]){

        const group: IGroup[] =[];
        //グループ化初期値
        let start :number = 0;
        let count :number = 0;
        let groupName =  users[0].groupName ; 
        users.forEach((data,index)=>{
            if(groupName === data.groupName){
                count = count +1 ;
            }else{
                group.push({ key: groupName? groupName:'', name: groupName?groupName:'', startIndex: start, count: count, level: 0 });
                start =index;
                count = 1;
                groupName = data.groupName
            }
        });
        group.push({ key: groupName? groupName:'', name: groupName?groupName:'', startIndex: start, count: count, level: 0 });

        return group
    }

    const UserList = (
        <Fabric>
            <div className={exampleChildClass} />
            <TextField
                className={exampleChildClass}
                onChange={_onFilter}
                styles={textFieldStyles}
            />
            <MarqueeSelection selection={detailsListSelection}>
            <div data-is-scrollable={true} style={ {maxHeight: "700px",  maxWidth: '350px', overflow: "auto" }}>
                <DetailsList
                    items={userListItems}
                    columns={_columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.single}
                    selection={detailsListSelection}
                    selectionPreservedOnEmptyClick={true}
                    compact={true}
                    groups={group}
                    data-is-scrollable={true}
                    checkboxVisibility = {2}
                />
            </div>
            </MarqueeSelection>
        </Fabric>
    );

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h1>保有スキル</h1>
                </div>
            </div>

            <div className="ms-Grid-row" style={borderRowStyle}>
                <Buttons />
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">{UserList}</div>
                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg10">
                    <div className="ms-Grid-row" style={rowStyle}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <h2 style={{ marginTop: '4px' }}>
                                {selectedUser.groupName + '  ' + selectedUser.name}
                            </h2>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <h2 style={{ marginTop: '4px', textAlign: 'right' }}>
                                {'最終更新日：'}
                                {chartData && chartData?.latestUpdate
                                    ? chartData?.latestUpdate
                                    : '-'}
                            </h2>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={rowStyle}>
                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg6"
                            style={{ minWidth: '400px' }}
                        >
                            <div className="ms-Grid-row" style={rowStyle}>
                                <h3>スキル傾向</h3>
                            </div>
                            <div className="ms-Grid-row" style={rowStyle}>
                                <TendencyRadarChart
                                    kbn={'skill'}
                                    userId={selectedUser.id!}
                                    fiscalYearId={targetFiscalYearId!}
                                    data={chartData?.skillChart}
                                    name={'自己スキル'}
                                />
                            </div>
                        </div>

                        <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg6"
                            style={{ minWidth: '400px' }}
                        >
                            <div className="ms-Grid-row" style={rowStyle}>
                                <h3>タスク傾向</h3>
                            </div>
                            <div className="ms-Grid-row" style={rowStyle}>
                                <TendencyRadarChart
                                    kbn={'task'}
                                    userId={selectedUser.id!}
                                    fiscalYearId={targetFiscalYearId!}
                                    data={chartData?.taskChart}
                                    name={'自己タスク'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={rowStyle}>
                        <div className="ms-Grid-row" style={rowStyle}>
                            <h3>スキル目標</h3>
                        </div>
                        <div className="ms-Grid-row" style={rowStyle}>
                            <GoalsBarChart
                                kbn={'skill'}
                                userId={selectedUser.id!}
                                fiscalYearId={targetFiscalYearId!}
                                data={chartData?.skillGoalChart}
                                keyA={'自己スキル'}
                                keyB={'目標'}
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={rowStyle}>
                        <div className="ms-Grid-row" style={rowStyle}>
                            <h3>タスク目標</h3>
                        </div>
                        <div className="ms-Grid-row" style={rowStyle}>
                            <GoalsBarChart
                                kbn={'task'}
                                userId={selectedUser.id!}
                                fiscalYearId={targetFiscalYearId!}
                                data={chartData?.taskGoalChart}
                                keyA={'自己タスク'}
                                keyB={'目標'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
