import { Label, SelectableOptionMenuItemType } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { groupBy } from 'lodash';
import { ComboBox, PrimaryButton } from 'office-ui-fabric-react';
import React, { useCallback, useMemo, useState } from 'react';
import DataGrid, { Row } from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';

import {
    FilterOption,
    option,
    possessedTaskOption,
    taskDetailsData,
    taskGoalData,
    taskRowData,
    taskScreenData,
} from '../../types/types';

//全グループを開く際に使用する配列
function createOpenTaskRows(props: taskScreenData) {
    const rows = new Set(['']);
    for (const TaskCategory of props.TaskCategoryOption) {
        rows.add(TaskCategory.text);
        for (const TaskClassification of props.TaskClassificationOption) {
            if (TaskCategory.key === TaskClassification.key2) {
                rows.add(TaskCategory.text + '_' + TaskClassification.text);
            }
            for (const TaskItems of props.TaskItemsOption) {
                if (
                    TaskCategory.key === TaskClassification.key2 &&
                    TaskClassification.key === TaskItems.key2
                ) {
                    rows.add(
                        TaskCategory.text + '_' + TaskClassification.text + '_' + TaskItems.text
                    );
                }
            }
        }
    }
    return rows;
}

// タスク詳細フィルターを作成する関数
function createPossessedDetailsFilter(
    taskDetails: taskDetailsData[] | undefined,
    selectTaskMajorCategory?: string,
    selectTaskMiddleCategory?: string,
    selectTaskMinorCategory?: string
) {
    let rows: possessedTaskOption | undefined = undefined;
    if (taskDetails !== undefined) {
        const taskMajorCategoryOption: FilterOption['DefaultFilter'] = [];
        const taskMiddleCategoryOption: FilterOption['DefaultFilter'] = [];
        const taskMinorCategoriesOption: FilterOption['DefaultFilter'] = [];

        let taskMajorCategoryList: taskDetailsData[] = taskDetails?.filter(
            (element, index: number, self: taskDetailsData[]) =>
                self.findIndex((e) => e.taskMajorCategory === element.taskMajorCategory) === index
        );

        const filteredTaskMajorCategoryList = taskMajorCategoryList.filter(
            (element) =>
                (selectTaskMiddleCategory
                    ? element.taskMiddleCategory === selectTaskMiddleCategory
                    : true) &&
                (selectTaskMinorCategory
                    ? element.taskMinorCategory === selectTaskMinorCategory
                    : true)
        );
        taskMajorCategoryList =
            filteredTaskMajorCategoryList.length > 0
                ? filteredTaskMajorCategoryList
                : taskMajorCategoryList;

        let taskMiddleCategoryList: taskDetailsData[] = taskDetails?.filter(
            (element, index: number, self: taskDetailsData[]) =>
                self.findIndex(
                    (e) =>
                        e.taskMajorCategory === element.taskMajorCategory &&
                        e.taskMiddleCategory === element.taskMiddleCategory
                ) === index
        );

        const filteredTaskMiddleCategoryList = taskMiddleCategoryList.filter(
            (element) =>
                (selectTaskMajorCategory
                    ? element.taskMajorCategory === selectTaskMajorCategory
                    : true) &&
                (selectTaskMinorCategory
                    ? element.taskMinorCategory === selectTaskMinorCategory
                    : true)
        );
        taskMiddleCategoryList =
            filteredTaskMiddleCategoryList.length > 0
                ? filteredTaskMiddleCategoryList
                : taskMiddleCategoryList;

        let taskMinorCategoryList: taskDetailsData[] = taskDetails?.filter(
            (element, index: number, self: taskDetailsData[]) =>
                self.findIndex(
                    (e) =>
                        e.taskMajorCategory === element.taskMajorCategory &&
                        e.taskMiddleCategory === element.taskMiddleCategory &&
                        e.taskMinorCategory === element.taskMinorCategory
                ) === index
        );

        const filteredTaskMinorCategoryList = taskMinorCategoryList.filter(
            (element) =>
                (selectTaskMajorCategory
                    ? element.taskMajorCategory === selectTaskMajorCategory
                    : true) &&
                (selectTaskMiddleCategory
                    ? element.taskMiddleCategory === selectTaskMiddleCategory
                    : true)
        );
        taskMinorCategoryList =
            filteredTaskMinorCategoryList.length > 0
                ? filteredTaskMinorCategoryList
                : taskMinorCategoryList;

        //keyを紐づける
        taskMajorCategoryOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });

        taskMajorCategoryList.forEach((data, index) => {
            taskMajorCategoryOption.push({
                key: String(index + 1),
                text: data.taskMajorCategory,
                itemType: 0,
            });
        });

        let taskMajorCategoryName: string = taskMiddleCategoryList[0].taskMajorCategory;
        taskMiddleCategoryOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });
        taskMiddleCategoryOption.push(
            {
                key: taskMajorCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: taskMajorCategoryName + 'Header',
                text: taskMajorCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );

        taskMiddleCategoryList.forEach((data, index) => {
            if (data.taskMajorCategory !== taskMajorCategoryName) {
                taskMiddleCategoryOption.push(
                    {
                        key: data.taskMajorCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.taskMajorCategory + 'Header' + index,
                        text: data.taskMajorCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                taskMajorCategoryName = data.taskMajorCategory;
            }
            taskMiddleCategoryOption.push({
                key: String(index + 1),
                text: data.taskMiddleCategory,
                itemType: 0,
            });
        });

        taskMajorCategoryName = taskMinorCategoryList[0].taskMajorCategory;
        taskMinorCategoriesOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });
        taskMinorCategoriesOption.push(
            {
                key: taskMajorCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: taskMajorCategoryName + 'Header',
                text: taskMajorCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );
        let taskMiddleCategoryName: string = taskMinorCategoryList[0].taskMiddleCategory;
        taskMinorCategoriesOption.push(
            {
                key: taskMiddleCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: taskMiddleCategoryName + 'Header',
                text: taskMiddleCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );

        taskMinorCategoryList.forEach((data, index) => {
            if (data.taskMajorCategory !== taskMajorCategoryName) {
                taskMinorCategoriesOption.push(
                    {
                        key: data.taskMajorCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.taskMajorCategory + 'Header' + index,
                        text: data.taskMajorCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                taskMajorCategoryName = data.taskMajorCategory;
            }
            if (data.taskMiddleCategory !== taskMiddleCategoryName) {
                taskMinorCategoriesOption.push(
                    {
                        key: data.taskMiddleCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.taskMiddleCategory + 'Header' + index,
                        text: data.taskMiddleCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                taskMiddleCategoryName = data.taskMiddleCategory;
            }
            taskMinorCategoriesOption.push({
                key: String(index + 1),
                text: data.taskMinorCategory,
                itemType: 0,
            });
        });

        rows = {
            TaskMajorCategoryOption: taskMajorCategoryOption,
            TaskMiddleCategoryOption: taskMiddleCategoryOption,
            TaskMinorCategoryOption: taskMinorCategoriesOption,
        };
    }
    return rows;
}

//フィルターレイアウト
const ComboBoxCustomStyledExampleStyles = {
    input: {
        height: '28px',
        position: 'relative' as 'relative',
        bottom: '7px',
    },
};

//メイン関数
export const TaskDetails = (props: any) => {
    //グループ関係配列
    const [openTaskGroup] = useState(createOpenTaskRows(props.taskData));

    //テーブルグループの初期値
    const [expandedGroups, setExpandedGroups] = useState(
        () => new Set(['taskCategory', 'taskClassification'])
    );
    const groups = ['taskCategory', 'taskClassification'];

    //フィルター機能の初期値
    const [filters, setFilters] = useState<Record<string, any>>({
        taskCategoryDisplay: '',
        taskClassificationDisplay: '',
        taskAssessmentItemsLink: '',
        ownedTaskCheckBox: '',
        target: '',
    });

    // フィルターの作成
    let taskDetailOption = createPossessedDetailsFilter(
        props.taskData.TaskDetailsOption,
        filters.taskCategoryDisplay,
        filters.taskClassificationDisplay,
        filters.taskAssessmentItemsLink
    );

    //フィルター項目
    const filteredColumns = useMemo(() => {
        return [
            {
                key: 'taskCategoryDisplay',
                name: 'タスク大分類',
                width: 250,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            options={taskDetailOption?.TaskMajorCategoryOption}
                            text={filters.taskCategoryDisplay}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskClassificationDisplay',
                name: 'タスク中分類',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            options={taskDetailOption?.TaskMiddleCategoryOption}
                            text={filters.taskClassificationDisplay}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskAssessmentItemsLink',
                name: 'タスク小分類',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            options={taskDetailOption?.TaskMinorCategoryOption}
                            text={filters.taskAssessmentItemsLink}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'ownedTaskCheckBox',
                name: '保有',
                width: 100,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            options={props.taskData.OwnedTasksOption}
                            text={filters.ownedTaskCheckBox}
                            onChange={(e, option) => {
                                p.onChange(option?.text);
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'target',
                name: '目標',
                width: 50,
            },
        ];
    }, [
        taskDetailOption,
        filters.taskCategoryDisplay,
        filters.taskClassificationDisplay,
        filters.taskAssessmentItemsLink,
        filters.ownedTaskCheckBox,
        props.taskData.OwnedTasksOption,
    ]);

    const targetColumn = useMemo(() => {
        return(
            <div
                className="ms-Grid-row ms-sm12 ms-md12 ms-lg12"
                dir="ltr"
                style={{ marginBottom: 10, textAlign: 'left' ,maxWidth:'500px'}}
            >
                <div 
                    className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"
                    style={{ width:'50px',paddingRight:'0px',paddingLeft:'0px'}}
                >
                    <Label
                        styles = {{root:{width:'50px',textAlign: 'right'}}}
                    >
                        目標：
                    </Label>
                </div>
                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                    <ComboBox
                        options={props.taskData.TargetOption}
                        text={filters.target}
                        onChange={(e, option) => {
                            setFilters({...filters ,target: option?.text})
                        }}
                    />
                </div>
            </div>
        )
    },[filters,props.taskData.TargetOption])


    //フィルター機能
    const filteredRows = useMemo(() => {
        let rows: any;
        if (filters.target) {
            // フィルターで選んだ職種でユーザの目標をフィルタ
            const targetKey = props.taskData.TargetOption.filter(
                (r: option) => r.text === filters.target
            )[0].key;

            const targets = props.taskData.taskGoalData.filter(
                (tg: taskGoalData) => tg.taskProfileId === targetKey
            );

            // スキルの対応結果を各行にマッピング
            rows = props.taskRows.map((task: taskRowData) => {
                let essential = targets.filter(
                    (target: taskGoalData) => target.taskMinorCategoryId === task.taskItemsId
                );

                task.target = essential && essential.length > 0 ? essential[0].essentialTask : '';
                return task;
            });
        } else {
            // フィルタがリセットされた場合、全行の目標の対応結果もリセット
            rows = props.taskRows.map((task: taskRowData) => {
                task.target = '';
                return task;
            });
        }

        return rows.filter((r: taskRowData) => {
            return (
                (filters.taskCategoryDisplay
                    ? r.taskCategory.includes(filters.taskCategoryDisplay)
                    : true) &&
                (filters.taskClassificationDisplay
                    ? r.taskClassification.includes(filters.taskClassificationDisplay)
                    : true) &&
                (filters.taskAssessmentItemsLink
                    ? r.taskItems.includes(filters.taskAssessmentItemsLink)
                    : true) &&
                (filters.ownedTaskCheckBox !== '' && filters.ownedTaskCheckBox !== 'ALL'
                    ? r.ownedTasksValue === filters.ownedTaskCheckBox
                    : true) &&
                (filters.target ? r.target : true)
            );
        });
    }, [
        filters.target,
        filters.taskCategoryDisplay,
        filters.taskClassificationDisplay,
        filters.taskAssessmentItemsLink,
        filters.ownedTaskCheckBox,
        props.taskData.TargetOption,
        props.taskData.taskGoalData,
        props.taskRows,
    ]);

    //フィルタークリーン
    function clearFilters() {
        setFilters({
            taskCategory: '',
            taskClassification: '',
            taskAssessmentItemsLink: '',
            ownedTaskCheckBox: '',
            target: '',
        });
    }

    //カラムのグループ作成
    const groupByColumn: any = useCallback(
        (
            rows: any,
            columnKeys: any,
            expandedGroups: any,
            treeDepth: any = 0,
            parentId: any = ''
        ) => {
            if (columnKeys.length === 0) return rows;
            const gridRows = [];
            const [columnKey, ...remainingColumnKeys] = columnKeys;
            const groupedRows = groupBy(rows, columnKey);
            const groupedKeys = Object.keys(groupedRows);

            for (const groupKey of groupedKeys) {
                const groupId = parentId ? `${parentId}_${groupKey}` : groupKey;
                const isExpanded = expandedGroups.has(groupId);
                const rowGroupHeader = {
                    __metaData: {
                        groupId,
                        groupKey,
                        treeDepth,
                        isExpanded,
                        columnGroupName: props.taskData.taskColumns.find(
                            (c: option) => c.key === columnKey
                        ).name,
                    },
                };
                gridRows.push(rowGroupHeader);
                if (isExpanded) {
                    gridRows.push(
                        ...groupByColumn(
                            groupedRows[groupKey],
                            remainingColumnKeys,
                            expandedGroups,
                            treeDepth + 1,
                            groupId
                        )
                    );
                }
            }
            return gridRows;
        },
        [props.taskData.taskColumns]
    );

    //グループ化
    const gridRows = useMemo(() => {
        return groupByColumn(filteredRows, groups, expandedGroups);
    }, [groupByColumn, filteredRows, groups, expandedGroups]);

    //グループ化
    function GroupRowRenderer(props: any) {
        if (props.row.__metaData === undefined) {
            return <Row {...props} />;
        }
        const { groupKey, isExpanded, treeDepth, columnGroupName, groupId } = props.row.__metaData;
        return (
            <div className="rdg-row rdg-row-default-group" tabIndex={0} style={{ top: props.top }}>
                <span
                    className="rdg-row-expand-icon"
                    style={{ marginLeft: treeDepth * 20 }}
                    onClick={() => onRowExpandToggle(groupId)}
                >
                    {isExpanded ? (
                        <Icon iconName="DoubleChevronDown" />
                    ) : (
                        <Icon iconName="DoubleChevronRight" />
                    )}
                </span>
                <strong>
                    {' '}
                    {columnGroupName}: {groupKey}
                </strong>
            </div>
        );
    }

    //グループ化に使用する関数
    function onRowExpandToggle(groupId: any) {
        const newExpandedGroups = new Set(expandedGroups);
        if (newExpandedGroups.has(groupId)) {
            newExpandedGroups.delete(groupId);
        } else {
            newExpandedGroups.add(groupId);
        }
        setExpandedGroups(newExpandedGroups);
    }

    //グループを全て閉じる
    function CloseGroup() {
        const newExpandedGroups = new Set(['']);
        setExpandedGroups(newExpandedGroups);
    }

    //グループを全て開く
    function OpenGroup() {
        setExpandedGroups(openTaskGroup);
    }

    function onFiltersChange(filters: Record<string, any>) {
        setFilters(filters);
    }

    return (
        <div className="ms-Grid" dir="ltr">
            <div
                className="ms-Grid-row ms-sm12 ms-md12 ms-lg12"
                dir="ltr"
                style={{ marginBottom: 10, textAlign: 'left' }}
            >
                <PrimaryButton
                    text="保存"
                    style={{ margin: 2 }}
                    disabled={!props.isEditable}
                    onClick={props.onSave}
                />
                <PrimaryButton text="全て開く" onClick={OpenGroup} style={{ margin: 2 }} />
                <PrimaryButton text="全て閉じる" onClick={CloseGroup} style={{ margin: 2 }} />
                <PrimaryButton
                    text="フィルター初期化"
                    onClick={clearFilters}
                    style={{ margin: 2 }}
                />
            </div>
            {targetColumn}
            <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12">
                <DataGrid
                    columns={filteredColumns}
                    rows={gridRows}
                    height={500}
                    enableFilters={true}
                    filters={filters}
                    defaultColumnOptions={{
                        resizable: true,
                    }}
                    onFiltersChange={(filter) => {
                        onFiltersChange(filter);
                    }}
                    rowRenderer={(p) => <GroupRowRenderer {...p} />}
                />
            </div>
        </div>
    );
};
