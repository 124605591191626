export class AppConfig {
    static ApiUrl: string;
    static ApiUrlGetUserData: string;
    static ApiUrlGetLoginUserData: string;
    static ApiUrlGetUserDetailData: string;
    static ClientUrl: string;
    static ApiUrlGetActiveUsers: string;
    static ApiUrlGetSkillDisplayData: string;
    static ApiUrlGetTaskDisplayData: string;
    static ApiUrlSavePossession: string;
    static ApiUrlJobsData: string;
    static ApiUrlJobsDetailsData: string;
    static ApiUrlJobsSkillData: string;
    static ApiUrlInsertSkillGoalsData: string;
    static ApiUrlTaskProfilesData: string;
    static ApiUrlTaskProfilesDetailsData: string;
    static ApiUrlTaskTaskProfilesData: string;
    static ApiUrlInsertTaskGoalsData: string;
    static ApiUrlInitiCDMaintenanceScreen: string;
    static ApiUrliCDMaintenanceVisibleSkillItem: string;
    static ApiUrliCDMaintenanceVisibleSkillCategory: string;
    static ApiUrliCDMaintenanceVisibleSkillClassification: string;
    static ApiUrlIcdFileTypeData: string;
    static ApiUrlIcdImportHistoryData: string;
    static ApiUrlIcdImport: string;
    static ApiUrlComparisonUsersData: string;
    static ApiUrlComparisonSummaryData: string;
    static ApiUrlSkillComparisonUsersData: string;
    static ApiUrlSkillSummaryData: string;
    static ApiUrlSkillGroupSummaryData: string;
    static ApiUrlTaskComparisonUsersData: string;
    static ApiUrlTaskSummaryData: string;
    static ApiUrlTaskGroupSummaryData: string;
    static ApiUrlUsersListData: string;
    static ApiUrlYearsListData: string;
    static ApiUrliCDMaintenanceVisibleTaskMinorCategory: string;
    static ApiUrliCDMaintenanceVisibleTaskMiddleCategory: string;
    static ApiUrliCDMaintenanceVisibleTaskMajorCategory: string;
    static ApiUrliCDMaintenanceCreateSkillCategory: string;
    static ApiUrliCDMaintenanceCreateSkillClassification: string;
    static ApiUrliCDMaintenanceCreateSkillItem: string;
    static ApiUrliCDMaintenanceCreateTaskMajorCategory: string;
    static ApiUrliCDMaintenanceCreateTaskMiddleCategory: string;
    static ApiUrliCDMaintenanceCreateTaskMinorCategory: string;
    static ApiUrliCDMaintenanceUpdateSkillCategory: string;
    static ApiUrliCDMaintenanceUpdateSkillClassification: string;
    static ApiUrliCDMaintenanceUpdateSkillItem: string;
    static ApiUrliCDMaintenanceUpdateTaskMajorCategory: string;
    static ApiUrliCDMaintenanceUpdateTaskMiddleCategory: string;
    static ApiUrliCDMaintenanceUpdateTaskMinorCategory: string;
    static ApiUrliCDMaintenanceDeleteSkillCategory: string;
    static ApiUrliCDMaintenanceDeleteSkillClassification: string;
    static ApiUrliCDMaintenanceDeleteSkillItem: string;
    static ApiUrliCDMaintenancDeleteTaskMajorCategory: string;
    static ApiUrliCDMaintenanceDeleteTaskMiddleCategory: string;
    static ApiUrliCDMaintenanceDeleteTaskMinorCategory: string;
}

const CLIENT_URL = 'http://localhost:3000/';
const API_URL = '/';

AppConfig.ClientUrl = CLIENT_URL;
AppConfig.ApiUrl = API_URL;

AppConfig.ApiUrlGetUserData = AppConfig.ApiUrl + 'users/getUser';
AppConfig.ApiUrlGetLoginUserData = AppConfig.ApiUrl + 'users/loginUser';
AppConfig.ApiUrlGetActiveUsers = AppConfig.ApiUrl + 'users/activeUsers';

AppConfig.ApiUrlGetUserDetailData = AppConfig.ApiUrl + 'top/dashboard';

AppConfig.ApiUrlGetSkillDisplayData = AppConfig.ApiUrl + 'skill-display/displaySkill';
AppConfig.ApiUrlGetTaskDisplayData = AppConfig.ApiUrl + 'task-display/displayTask';
AppConfig.ApiUrlSavePossession = AppConfig.ApiUrl + 'skill-display/update';

AppConfig.ApiUrlJobsData = AppConfig.ApiUrl + 'skill-goal-setting/jobs';
AppConfig.ApiUrlJobsDetailsData = AppConfig.ApiUrl + 'skill-goal-setting/jobsDetails';
AppConfig.ApiUrlJobsSkillData = AppConfig.ApiUrl + 'skill-goal-setting/jobsSkill';
AppConfig.ApiUrlInsertSkillGoalsData = AppConfig.ApiUrl + 'skill-goal-setting/update';
AppConfig.ApiUrlTaskProfilesData = AppConfig.ApiUrl + 'task-goal-setting/taskProfiles';
AppConfig.ApiUrlTaskProfilesDetailsData =
    AppConfig.ApiUrl + 'task-goal-setting/taskProfilesDetails';
AppConfig.ApiUrlTaskTaskProfilesData = AppConfig.ApiUrl + 'task-goal-setting/taskTaskProfiles';
AppConfig.ApiUrlInsertTaskGoalsData = AppConfig.ApiUrl + 'task-goal-setting/update';

AppConfig.ApiUrlInitiCDMaintenanceScreen = AppConfig.ApiUrl + 'icd-maintenance/initScreen';
AppConfig.ApiUrliCDMaintenanceVisibleSkillItem =
    AppConfig.ApiUrl + 'icd-maintenance/visibleSkillItem';
AppConfig.ApiUrliCDMaintenanceVisibleSkillCategory =
    AppConfig.ApiUrl + 'icd-maintenance/visibleSkillCategory';
AppConfig.ApiUrliCDMaintenanceVisibleSkillClassification =
    AppConfig.ApiUrl + 'icd-maintenance/visibleSkillClassification';

AppConfig.ApiUrliCDMaintenanceVisibleTaskMajorCategory =
    AppConfig.ApiUrl + 'icd-maintenance/visibleTaskMajorCategory';
AppConfig.ApiUrliCDMaintenanceVisibleTaskMiddleCategory =
    AppConfig.ApiUrl + 'icd-maintenance/visibleTaskMiddleCategory';
AppConfig.ApiUrliCDMaintenanceVisibleTaskMinorCategory =
    AppConfig.ApiUrl + 'icd-maintenance/visibleTaskMinorCategory';

AppConfig.ApiUrliCDMaintenanceCreateSkillCategory =
    AppConfig.ApiUrl + 'icd-maintenance/createSkillCategory';
AppConfig.ApiUrliCDMaintenanceCreateSkillClassification =
    AppConfig.ApiUrl + 'icd-maintenance/createSkillClassification';
AppConfig.ApiUrliCDMaintenanceCreateSkillItem =
    AppConfig.ApiUrl + 'icd-maintenance/createSkillItem';

AppConfig.ApiUrliCDMaintenanceCreateTaskMajorCategory =
    AppConfig.ApiUrl + 'icd-maintenance/createTaskMajorCategory';
AppConfig.ApiUrliCDMaintenanceCreateTaskMiddleCategory =
    AppConfig.ApiUrl + 'icd-maintenance/createTaskMiddleCategory';
AppConfig.ApiUrliCDMaintenanceCreateTaskMinorCategory =
    AppConfig.ApiUrl + 'icd-maintenance/createTaskMinorCategory';

AppConfig.ApiUrliCDMaintenanceUpdateSkillCategory =
    AppConfig.ApiUrl + 'icd-maintenance/updateSkillCategory';
AppConfig.ApiUrliCDMaintenanceUpdateSkillClassification =
    AppConfig.ApiUrl + 'icd-maintenance/updateSkillClassification';
AppConfig.ApiUrliCDMaintenanceUpdateSkillItem =
    AppConfig.ApiUrl + 'icd-maintenance/updateSkillItem';

AppConfig.ApiUrliCDMaintenanceUpdateTaskMajorCategory =
    AppConfig.ApiUrl + 'icd-maintenance/updateTaskMajorCategory';
AppConfig.ApiUrliCDMaintenanceUpdateTaskMiddleCategory =
    AppConfig.ApiUrl + 'icd-maintenance/updateTaskMiddleCategory';
AppConfig.ApiUrliCDMaintenanceUpdateTaskMinorCategory =
    AppConfig.ApiUrl + 'icd-maintenance/updateTaskMinorCategory';

AppConfig.ApiUrliCDMaintenanceDeleteSkillCategory =
    AppConfig.ApiUrl + 'icd-maintenance/deleteSkillCategory';
AppConfig.ApiUrliCDMaintenanceDeleteSkillClassification =
    AppConfig.ApiUrl + 'icd-maintenance/deleteSkillClassification';
AppConfig.ApiUrliCDMaintenanceDeleteSkillItem =
    AppConfig.ApiUrl + 'icd-maintenance/deleteSkillItem';

AppConfig.ApiUrliCDMaintenancDeleteTaskMajorCategory =
    AppConfig.ApiUrl + 'icd-maintenance/deleteTaskMajorCategory';
AppConfig.ApiUrliCDMaintenanceDeleteTaskMiddleCategory =
    AppConfig.ApiUrl + 'icd-maintenance/deleteTaskMiddleCategory';
AppConfig.ApiUrliCDMaintenanceDeleteTaskMinorCategory =
    AppConfig.ApiUrl + 'icd-maintenance/deleteTaskMinorCategory';

AppConfig.ApiUrlIcdFileTypeData = AppConfig.ApiUrl + 'icd-import/icdFileTypes';
AppConfig.ApiUrlIcdImportHistoryData = AppConfig.ApiUrl + 'icd-import/icdImportHistories';
AppConfig.ApiUrlIcdImport = AppConfig.ApiUrl + 'icd-import/import';
AppConfig.ApiUrlComparisonUsersData = AppConfig.ApiUrl + 'comparison/comparisonUsers';
AppConfig.ApiUrlComparisonSummaryData = AppConfig.ApiUrl + 'comparison/summary';

AppConfig.ApiUrlUsersListData = AppConfig.ApiUrl + 'comparison/usersList';
AppConfig.ApiUrlYearsListData = AppConfig.ApiUrl + 'comparison/yearsList';
AppConfig.ApiUrlTaskComparisonUsersData = AppConfig.ApiUrl + 'comparison/taskComparisonUsers';
AppConfig.ApiUrlSkillComparisonUsersData = AppConfig.ApiUrl + 'comparison/skillComparisonUsers';
AppConfig.ApiUrlTaskComparisonUsersData = AppConfig.ApiUrl + 'comparison/taskComparisonUsers';
AppConfig.ApiUrlSkillSummaryData = AppConfig.ApiUrl + 'comparison/skillSummary';
AppConfig.ApiUrlTaskSummaryData = AppConfig.ApiUrl + 'comparison/taskSummary';
AppConfig.ApiUrlSkillGroupSummaryData = AppConfig.ApiUrl + 'comparison/skillGroupSummary';
AppConfig.ApiUrlTaskGroupSummaryData = AppConfig.ApiUrl + 'comparison/taskGroupSummary';
