import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { useSelector, useDispatch } from 'react-redux';
import { useBoolean } from '@uifabric/react-hooks';

import { SkillDetails } from './SkillDetails';
import { TaskDetails } from './TaskDetails';
import { setLoader, deleteLoader } from '../../stores/ui/ui';
import { getUserData, getSkillDetail, getTaskDetail, SavePossession } from '../../api/call';
import {
    SkillData,
    TaskData,
    skillScreenData,
    taskScreenData,
    skillRowData,
    taskRowData,
    savePossessedData,
} from '../../types/types';
import { RootState } from '../../stores';
import { openDialog, openConfirm, closeDialog } from '../../stores/ui/dialog';
import { DialogType, Link } from '@fluentui/react';
import { TableMouseOver } from '../TableMouseOver';
import { TableLink } from '../TableLink';
import { User } from '../../stores/loginuser';

//メイン関数
export const Details = () => {
    const dispatch = useDispatch();
    // 全体stateより取得
    const loginUser = useSelector((state: RootState) => state.user);
    const currentUser = useSelector((state: RootState) => state.userList.currentUser);

    // クエリーパラメータからユーザIDと年度IDを取得する
    const urlParams = new URLSearchParams(useLocation().search);
    const targetUserId = urlParams.get('userId');
    const targetFiscalYearId = urlParams.get('fiscalYearId');

    // 権限制御。ログインユーザと同じユーザもしくは管理者の場合編集可能
    const [isEditable, setIsEditable] = useState<boolean>(
        loginUser.id?.toString() === targetUserId?.toString() ||
            loginUser.id === currentUser?.id ||
            loginUser.roleId === 3
    );

    // FIXME: const定義だと動作しないため、stateを直書き換えで対応中
    let [skillData, setSkillData] = useState<skillScreenData>();
    let [taskData, setTaskData] = useState<taskScreenData>();
    //テーブルデータ取得
    let [skillRows, setSkillRows] = useState<skillRowData[]>();
    let [taskRows, setTaskRows] = useState<taskRowData[]>();
    //dialog系のプロパティ
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [dialogContentProps, setDialogContentProps] = useState({
        type: DialogType.largeHeader,
        title: '',
        itemsList: [{ key: 0, name: '' }],
        itemsColumns: [{ key: 'column1', name: '', fieldName: '', minWidth: 50 }],
    });
    const [selectUser, setSelectUser] = useState<User>();

    const skillCheckBoxChange = (skillItemId: number) => {
        if (skillRows !== undefined) {
            skillRows.map((skillRow) => {
                if (skillRow.skillItemsId === skillItemId) {
                    skillRow.ownedSkills = !skillRow.ownedSkills;
                    // FIXME: table内にレンダーされる要素を丸々書き換えているため、checkBoxも再度書き入れないと動かない模様
                    skillRow.ownedSkillCheckBox = (
                        <div style={{ marginTop: 6 }}>
                            <Checkbox
                                disabled={!isEditable}
                                checked={skillRow.ownedSkills}
                                onChange={() => skillCheckBoxChange(skillRow.skillItemsId)}
                            />
                        </div>
                    );
                    skillRow.ownedSkillsValue = skillRow.ownedSkills
                        ? '保持スキル'
                        : '保持していないスキル';
                }
                return skillRow;
            });
            setSkillRows(skillRows);
        }
    };

    const taskCheckBoxChange = (taskItemsId: number) => {
        if (taskRows) {
            taskRows.map((taskRow) => {
                if (taskRow.taskItemsId === taskItemsId) {
                    taskRow.ownedTasks = !taskRow.ownedTasks;
                    // FIXME: table内にレンダーされる要素を丸々書き換えているため、checkBoxも再度書き入れないと動かない模様
                    taskRow.ownedTaskCheckBox = (
                        <div style={{ marginTop: 6 }}>
                            <Checkbox
                                disabled={!isEditable}
                                checked={taskRow.ownedTasks}
                                onChange={() => taskCheckBoxChange(taskRow.taskItemsId)}
                            />
                        </div>
                    );
                    taskRow.ownedTasksValue = taskRow.ownedTasks
                        ? '保持タスク'
                        : '保持していないタスク';
                }
                return taskRow;
            });
            setTaskRows(taskRows);
        }
    };
    //スキルテーブルデータ
    function createSkillRows(skillData: SkillData[]) {
        let rows: skillRowData[] = [];
        skillData.map((data) => {
            if (!rows.some((a) => a.skillItemsId === data.skillItemsId)) {
                rows.push({
                    skillCategory: data.skillCategory,
                    skillCategoryDisplay: TableMouseOver(data.skillCategoryId, data.skillCategory),
                    skillClassification: data.skillClassification,
                    skillClassificationDisplay: TableMouseOver(
                        data.skillClassificationId,
                        data.skillClassification
                    ),
                    skillItemsId: data.skillItemsId,
                    skillItems: data.skillItems,
                    skillItemsLink:
                        data.skillItemsType === '1' ? (
                            TableMouseOver(data.skillItemsId, data.skillItems)
                        ) : (
                            <Link
                                onClick={() => {
                                    const skillKnowledegeItems = [{ key: 0, name: '' }];
                                    skillData.forEach((value, index) => {
                                        if (data.skillItemsId === value.skillItemsId) {
                                            skillKnowledegeItems.push({
                                                key: index + 1,
                                                name: value.skillKnowledgeItems,
                                            });
                                        }
                                    });
                                    skillKnowledegeItems.shift();
                                    setDialogContentProps({
                                        type: DialogType.largeHeader,
                                        title: data.skillItems,
                                        itemsList: skillKnowledegeItems,
                                        itemsColumns: [
                                            {
                                                key: 'column1',
                                                name: 'スキル知識項目',
                                                fieldName: 'name',
                                                minWidth: 50,
                                            },
                                        ],
                                    });
                                    toggleHideDialog();
                                }}
                            >
                                {TableMouseOver(data.skillItemsId, data.skillItems)}
                            </Link>
                        ),
                    ownedSkills: data.ownedSkills,
                    ownedSkillCheckBox: (
                        <div style={{ marginTop: 6 }}>
                            <Checkbox
                                disabled={!isEditable}
                                checked={data.ownedSkills}
                                onChange={() => skillCheckBoxChange(data.skillItemsId)}
                            />
                        </div>
                    ),
                    ownedSkillsValue: data.ownedSkills ? '保持スキル' : '保持していないスキル',
                    target: '',
                });
            }
            return data;
        });
        // FIXME: const定義だと動作しないため、stateを直書き換えで対応中
        skillRows = rows;
        setSkillRows(rows);
        return rows;
    }

    //タスクテーブルデータ
    const createTaskRows = useCallback((taskData: TaskData[]) => {
        let rows: taskRowData[] = [];
        taskData.map((data) => {
            if (!rows.some((a) => a.taskItemsId === data.taskItemsId)) {
                rows.push({
                    taskCategory: data.taskCategory,
                    taskCategoryDisplay: TableMouseOver(data.taskCategoryId, data.taskCategory),
                    taskClassification: data.taskClassification,
                    taskClassificationDisplay: TableMouseOver(
                        data.taskClassificationId,
                        data.taskClassification
                    ),
                    taskItemsId: data.taskItemsId,
                    taskItems: data.taskItems,
                    taskAssessmentItemsLink:
                        data.taskItemsType === '1' ? (
                            TableMouseOver(data.taskItemsId, data.taskItems)
                        ) : (
                            <Link
                                onClick={() => {
                                    const taskAssessmentItems = [{ key: 0, name: '' }];
                                    taskData.forEach((value, index) => {
                                        if (data.taskItemsId === value.taskItemsId) {
                                            taskAssessmentItems.push({
                                                key: index + 1,
                                                name: value.taskAssessmentItems,
                                            });
                                        }
                                    });
                                    taskAssessmentItems.shift();
                                    setDialogContentProps({
                                        type: DialogType.largeHeader,
                                        title: data.taskItems,
                                        itemsList: taskAssessmentItems,
                                        itemsColumns: [
                                            {
                                                key: 'column1',
                                                name: 'タスク評価項目',
                                                fieldName: 'name',
                                                minWidth: 50,
                                            },
                                        ],
                                    });
                                    toggleHideDialog();
                                }}
                            >
                                {TableMouseOver(data.taskItemsId, data.taskItems)}
                            </Link>
                        ),
                    ownedTasks: data.ownedTasks,
                    ownedTaskCheckBox: (
                        <div style={{ marginTop: 6 }}>
                            <Checkbox
                                disabled={!isEditable}
                                checked={data.ownedTasks}
                                onChange={() => taskCheckBoxChange(data.taskItemsId)}
                            />
                        </div>
                    ),
                    ownedTasksValue: data.ownedTasks ? '保持タスク' : '保持していないタスク',
                    target: '',
                });
            }
            return data;
        });
        // FIXME: const定義だと動作しないため、stateを直書き換えで対応中
        // eslint-disable-next-line react-hooks/exhaustive-deps
        taskRows = rows;
        setTaskRows(rows);
        return rows;
    }, []);

    const getSkillData = useCallback(async () => {
        try {
            // TODO: ログイン・認証機能系が実装されたらデータ取得条件を要再検討
            let userId: any, fiscalYearId: any;
            if (targetUserId && targetFiscalYearId) {
                userId = targetUserId;
                fiscalYearId = targetFiscalYearId;
            } else if (currentUser && currentUser.id && currentUser.fiscalYearId) {
                userId = currentUser.id;
                fiscalYearId = currentUser.fiscalYearId;
            }
            if (userId && fiscalYearId) {
                dispatch(setLoader('SkillData'));
                const data = await getSkillDetail(userId, fiscalYearId);
                const selectUser = await getUserData(userId);
                createSkillRows(data.skillData);
                // FIXME: const定義だと動作しないため、stateを直書き換えで対応中
                // eslint-disable-next-line react-hooks/exhaustive-deps
                skillData = data;
                setSkillData(data);
                setSelectUser(selectUser);
            }
        } catch (err) {
            console.log('Details#getSkillData', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('SkillData'));
        }
    }, []);

    const getTaskData = useCallback(async () => {
        try {
            // TODO: ログイン・認証機能系が実装されたらデータ取得条件を要再検討
            // TODO: ログイン・認証機能系が実装されたらデータ取得条件を要再検討
            let userId: any, fiscalYearId: any;
            if (targetUserId && targetFiscalYearId) {
                userId = targetUserId;
                fiscalYearId = targetFiscalYearId;
            } else if (currentUser && currentUser.id && currentUser.fiscalYearId) {
                userId = currentUser.id;
                fiscalYearId = currentUser.fiscalYearId;
            }
            if (userId && fiscalYearId) {
                dispatch(setLoader('TaskData'));
                const data = await getTaskDetail(userId, fiscalYearId);
                createTaskRows(data.taskData);
                setTaskData(data);
            }
        } catch (err) {
            console.log('Details#getTaskData', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('TaskData'));
        }
    }, [createTaskRows, currentUser, dispatch, targetFiscalYearId, targetUserId]);

    const createSaveData = () => {
        let saveData: savePossessedData = {
            possessedSkillData: [],
            possessedTaskData: [],
        };
        skillRows?.map((skillRow) => {
            if (skillRow.ownedSkills) {
                saveData.possessedSkillData.push(skillRow.skillItemsId);
            }
        });
        taskRows?.map((taskRow) => {
            if (taskRow.ownedTasks) {
                saveData.possessedTaskData.push(taskRow.taskItemsId);
            }
        });
        return saveData;
    };
    const save = () => async () => {
        try {
            setIsEditable(false);
            let userId: any, fiscalYearId: any;
            if (targetUserId && targetFiscalYearId) {
                userId = targetUserId;
                fiscalYearId = targetFiscalYearId;
            } else if (currentUser && currentUser.id && currentUser.fiscalYearId) {
                userId = currentUser.id;
                fiscalYearId = currentUser.fiscalYearId;
            }
            if (userId && fiscalYearId) {
                dispatch(setLoader('savePossessionData'));
                dispatch(closeDialog());
                const saveData = createSaveData();
                await SavePossession(userId, fiscalYearId, saveData);
                dispatch(
                    openDialog({
                        title: '保存完了',
                        subText: '保存しました',
                        primaryAction: () => dispatch(closeDialog()),
                    })
                );
            }
        } catch (err) {
            console.log('Details#save', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('savePossessionData'));
            setIsEditable(
                loginUser.id?.toString() === targetUserId?.toString() ||
                    loginUser.id === currentUser?.id ||
                    loginUser.roleId === 3
            );
        }
    };

    const onSave = () => {
        dispatch(
            openConfirm({
                title: '保存確認',
                subText: '変更を保存します。よろしいですか？',
                primaryAction: save(),
                secondaryAction: () => dispatch(closeDialog()),
            })
        );
    };

    useEffect(() => {
        Promise.all([getSkillData(), getTaskData()]);
    }, [getSkillData, getTaskData]);

    const selectUserDisplay = useMemo(() => {
        if (selectUser === undefined) {
            return '';
        } else {
            return '（' + selectUser.groupName + ' ' + selectUser.name + '）';
        }
    }, [selectUser]);

    return (
        <div className="ms-Grid" dir="ltr">
            {TableLink(hideDialog, toggleHideDialog, dialogContentProps)}
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h1>詳細{selectUserDisplay}</h1>
                </div>
            </div>
            <Pivot aria-label="SkillTab">
                <PivotItem
                    headerText="スキル"
                    headerButtonProps={{
                        'data-order': 1,
                        'data-title': 'スキル',
                    }}
                >
                    <Label>スキル詳細</Label>
                    {skillData ? (
                        <SkillDetails
                            isEditable={isEditable}
                            skillData={skillData}
                            skillRows={skillRows}
                            setSkillRows={setSkillRows}
                            onSave={onSave}
                        />
                    ) : (
                        ''
                    )}
                </PivotItem>
                <PivotItem headerText="タスク">
                    <Label>タスク詳細</Label>
                    {taskData ? (
                        <TaskDetails
                            isEditable={isEditable}
                            taskData={taskData}
                            taskRows={taskRows}
                            setTaskRows={setTaskRows}
                            onSave={onSave}
                            setTaskData={setTaskData}
                        />
                    ) : (
                        ''
                    )}
                </PivotItem>
            </Pivot>
        </div>
    );
};
