import React from 'react';
import {
    Legend,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    Tooltip,
} from 'recharts';
import { Link } from 'react-router-dom';
import { radarChart } from '../../types/types';

interface temp {
    [key: string]: { [alert: string]: JSX.Element };
}

interface prop {
    kbn: string;
    userId: number;
    fiscalYearId: number;
    data?: radarChart[];
    name: string;
}

export const TendencyRadarChart = ({ kbn, userId, fiscalYearId, data, name }: prop) => {
    let dataCount: number = 0;
    data?.forEach((data) => {
        if (data.A !== 0) {
            dataCount = dataCount + 1;
        }
    });
    const tempData: temp = {
        skill: {
            alert: (
                <div style={{ height: 300 }}>
                    <div>スキルが登録されていません</div>
                    <Link to={`/details?userId=${userId}&fiscalYearId=${fiscalYearId}`}>
                        保有スキル詳細画面
                    </Link>
                    よりスキルを登録してください
                </div>
            ),
        },
        task: {
            alert: (
                <div style={{ height: 300 }}>
                    <div>タスクが登録されていません</div>
                    <Link to={`/details?userId=${userId}&fiscalYearId=${fiscalYearId}`}>
                        保有タスク詳細画面
                    </Link>
                    よりタスクを登録してください
                </div>
            ),
        },
    };
    const alert = tempData[kbn];

    return !data || dataCount < 1 ? (
        alert.alert
    ) : (
        <RadarChart
            outerRadius={90}
            width={400}
            height={300}
            data={data}
            style={{ margin: 'auto' }}
            margin={{ top: 0, right: 30, left: 20, bottom: 5 }}
        >
            <PolarGrid />
            <Tooltip />
            <PolarAngleAxis dataKey="name" />
            <PolarRadiusAxis angle={120} domain={[0, 10]} />
            <Radar name={name} dataKey="A" stroke="#0078d4" fill="#0078d4" fillOpacity={0.6} />
            <Legend verticalAlign="top" height={30} />
        </RadarChart>
    );
};
export const TendencyComparisonRadarChart = ({  kbn, userId, fiscalYearId, data, name }: prop) => {
    let dataCount: number = 0;
    data?.forEach((data) => {
        if (data.A !== 0) {
            dataCount = dataCount + 1;
        }
    });

    const tempData: temp = {
        skill: {
            alert: (
                <div style={{ height: 300 }}>
                    <div>スキルが登録されていません</div>
                    <Link to={`/details?userId=${userId}&fiscalYearId=${fiscalYearId}`}>保有スキル詳細画面</Link>
                    よりスキルを登録してください
                </div>
            ),
        },
        task: {
            alert: (
                <div style={{ height: 300 }}>
                    <div>タスクが登録されていません</div>
                    <Link to={`/details?userId=${userId}&fiscalYearId=${fiscalYearId}`}>保有タスク詳細画面</Link>
                    よりタスクを登録してください
                </div>
            ),
        },
    };
    const alert = tempData[kbn];

    return !data || dataCount < 1 ? (
        alert.alert
    ) : (
        <RadarChart
            outerRadius={90}
            width={400}
            height={300}
            data={data}
            margin={{ top: 0, right: 30, left: 20, bottom: 5 }}
        >
            <PolarGrid />
            <Tooltip />
            <PolarAngleAxis dataKey="name" />
            <PolarRadiusAxis angle={120} domain={[0, 10]} />
            <Radar name={name} dataKey="A" stroke="#0078d4" fill="#0078d4" fillOpacity={0.6} />
            <Radar name="比較" dataKey="B" stroke="#b7472a" fill="#b7472a" fillOpacity={0.6} />

            <Legend verticalAlign="top" height={30} />
        </RadarChart>
    );
};
