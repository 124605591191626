import { IContextualMenuItem, INavLink, ITooltipHostStyles, TooltipHost } from '@fluentui/react';
import { CommandBarButton, Nav, OverflowSet, Stack } from 'office-ui-fabric-react';
import { IOverflowSetItemProps } from 'office-ui-fabric-react/lib/OverflowSet';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../stores';

const menuIcon = {
    ホーム: 'Home',
    保有スキル: 'PartyLeader', // アイコン候補 PartyLeader Ribbon Medal
    目標設定: 'Trackers', // アイコン候補 Trackers MobileReport IconSetsFlag ChartSeries BookmarkReport
    システムメンテナンス: 'Settings',
};

export const Sidemenu = (props: any) => {
    const history = useHistory();
    const loginUser = useSelector((state: RootState) => state.user);
    const [navBarCollapsed, setNavBarCollapsed] = useState(true);

    const onRenderItem = (item: IOverflowSetItemProps) => {
        const calloutProps = { gapSpace: 0 };
        const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
        const styles =
            item.key === 'open' // メニュー開くアイコン
                ? {
                      root: {
                          padding: '10px',
                          borderBottom: 'solid 1px LightGray',
                      },
                  }
                : {
                      // それ以外のアイコン（No.7 サイドメニューのCommandBar対応に伴い若干大きさ修正
                      root: {
                          padding: '6px',
                      },
                      menuIcon: {
                          fontSize: '10px',
                      },
                  };

        return (        
            <TooltipHost
                content={item.name}
                id={item.name}
                calloutProps={calloutProps}
                styles={hostStyles}
                directionalHint={8}
            >
                <CommandBarButton
                    role="menuitem"
                    styles={styles}
                    iconProps={{
                        iconName: item.icon,
                    }}
                    menuProps={item.subMenuProps}
                    onClick={item.onClick}
                />
            </TooltipHost>
        
        );
    };

    const openSidemenuStyle = {
        width: '100%',
        padding: '10px',
        backgroundColor: 'white',
        marginLeft: '200px',
    };

    const closeSidemenuStyle = {
        width: '100%',
        padding: '10px',
        backgroundColor: 'white',
        marginLeft: '70px',
    };

    function openSidMenu() {
        props.setSideMenuStyle(openSidemenuStyle);
        setNavBarCollapsed(false);
    }

    function closeSidMenu() {
        props.setSideMenuStyle(closeSidemenuStyle);
        setNavBarCollapsed(true);
    }

    const onRenderOverflowButton = (): JSX.Element => {
        return <div />;
    };

    let navbar;

    let links: INavLink[] = [
        {
            name: 'Home',
            url: '/',
            key: 'home',
            // サブメニューバグにつきアイコン非表示(こちらだけアイコンが表示されていても変なので)
            // icon: menuIcon.ホーム,
        },
        {
            name: '保有スキル',
            url: `/overview?userId=${loginUser.id}&fiscalYearId=${loginUser.fiscalYearId}`,
            key: 'hoyu-skill',
            // サブメニューバグにつきアイコン非表示(こちらだけアイコンが表示されていても変なので)
            // icon: menuIcon.保有スキル,
        },
        {
            name: '目標設定',
            url: '',
            key: 'mokuhyo-settei',
            // FIXME: サブメニューとアイコンを併用すると矢印アイコンと重なってしまう
            // icon: menuIcon.目標設定,
            links: [
                {
                    name: 'スキル目標設定',
                    url: '/SkillTarget',
                    //icon:menuIcon.目標設定,
                },
                {
                    name: 'タスク目標設定',
                    url: '/TaskTarget',
                },
            ],
            isExpanded: true,
        },
    ];

    let items: IContextualMenuItem[] = [
        {
            key: 'open',
            icon: 'DoubleChevronRight',
            name: 'メニュー展開',
            onClick: () => openSidMenu(),
        },
        {
            key: 'home',
            icon: menuIcon.ホーム,
            name: 'ホーム',
            onClick: () => history.push('/'),
        },
        {
            key: 'hoyu-skill',
            icon: menuIcon.保有スキル,
            name: '保有スキル',
            onClick: () =>
                history.push(
                    `/overview?userId=${loginUser.id}&fiscalYearId=${loginUser.fiscalYearId}`
                ),
        },
        {
            key: 'mokuhyo-settei',
            icon: menuIcon.目標設定,
            name: '目標',
            onClick: () => history.push('/SkillTarget'),
            subMenuProps: {
                items: [
                    {
                        key: 'skillTarget',
                        icon: menuIcon.目標設定,
                        name: 'スキル目標設定',
                        onClick: () => history.push('/SkillTarget'),
                    },
                    {
                        key: 'taskTarget',
                        icon: menuIcon.目標設定,
                        name: 'タスク目標設定',
                        onClick: () => history.push('/TaskTarget'),
                    },
                ],
            },
        },
    ];

    if (loginUser && loginUser.roleId === 3) {
        links.push({
            name: 'システムメンテナンス',
            // FIXME: サブメニューとアイコンを併用すると矢印アイコンと重なってしまう
            // icon: menuIcons.システムメンテナンス,
            url: '',
            links: [
                {
                    name: 'iCD取り込み',
                    url: '/IcdImport',
                },
                {
                    name: 'iCDメンテナンス',
                    url: '/IcdMaintenance',
                },
            ],
            isExpanded: true,
        });
        items.push({
            key: 'system-maintenance',
            icon: menuIcon.システムメンテナンス,
            name: 'システムメンテナンス',
            onClick: () => history.push('/IcdMaintenance'),
            subMenuProps: {
                items: [
                    {
                        key: 'IcdMaintenance',
                        icon: menuIcon.システムメンテナンス,
                        name: 'iCDメンテナンス',
                        onClick: () => history.push('/IcdMaintenance'),
                    },
                    {
                        key: 'IcdImport',
                        icon: menuIcon.システムメンテナンス,
                        name: 'iCD取り込み',
                        onClick: () => history.push('/IcdImport'),
                    },
                ],
            },
        });
    }

    if (navBarCollapsed) {
        navbar = (
            <OverflowSet
                vertical
                role="menubar"
                onRenderItem={onRenderItem}
                items={items}
                // 必須属性なのでIDEによっては警告が出るため空オブジェクトを渡しておく
                onRenderOverflowButton={onRenderOverflowButton}
            />
        );
    } else {
        navbar = (
            <Stack>
                <CommandBarButton
                    style={{
                        height: '40px',
                        borderBottom: 'solid 1px LightGray',
                        textAlign: 'start',
                    }}
                    text="折り畳む"
                    iconProps={{
                        iconName: 'DoubleChevronLeft',
                    }}
                    onClick={() => closeSidMenu()}
                />{' '}
                <Nav
                    onLinkClick={(event, element) => {
                        if (event !== undefined && element !== undefined) {
                            // No.12 サイドバーの本来はクリックしない場所をどうするか（開く閉じるにする、または非活性）の修正
                            if (element.url !== '') {
                                event.preventDefault();
                                history.push(element.url);
                            }
                        }
                    }}
                    groups={[{ links: links }]}
                />{' '}
            </Stack>
        );
    }

    return (
        <div
            style={{
                height: '100%',
                borderRight: 'solid 1px LightGray',
                zIndex: 100,
                position: 'fixed',
                backgroundColor: 'white',
            }}
        >
            {' '}
            {navbar}{' '}
        </div>
    );
};
