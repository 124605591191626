import { createSlice } from '@reduxjs/toolkit';

import { AppThunk } from './index';
import { AppConfig } from '../AppConfig';

type Error = {
    message?: string;
};

// Stateの初期状態
const initialState: Error = {
    message: undefined,
};

// Sliceを生成する
const slice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        clearMessage: (state) => {
            state.message = '';
        },
        // etc...
    },
});

// Reducerをエクスポートする
export const errorReducer = slice.reducer;

export const setError = (message: string): AppThunk => async (dispatch) => {
    try {
        //const history = useHistory();
        console.error('エラー発生', message);
        dispatch(setMessage(message));
        //history.push('/error');
        // FIXME: アプリ再ロードになるのでエラーメッセージが消えて表示されない
        window.location.href = AppConfig.ClientUrl + 'error';
    } catch (err) {
        console.log(err);
    }
};

// Action Creatorsをエクスポートする
export const { setMessage } = slice.actions;
