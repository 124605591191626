import { DefaultButton, DetailsList, Dialog, DialogFooter, DialogType } from '@fluentui/react';
import React from 'react';

export const TableLink = (
    hideDialog: boolean,
    toggleHideDialog: () => void,
    dialogContentProps: {
        type: DialogType;
        title: string;
        itemsList: {
            key: number;
            name: string;
        }[];
        itemsColumns: {
            key: string;
            name: string;
            fieldName: string;
            minWidth: number;
        }[];
    }
) => {
    const modelProps = {
        isBlocking: false,
    };

    return (
        <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={dialogContentProps}
            modalProps={modelProps}
            minWidth={800}
        >
            <DetailsList
                items={dialogContentProps.itemsList}
                columns={dialogContentProps.itemsColumns}
                checkboxVisibility={2}
            />
            <DialogFooter>
                <DefaultButton onClick={toggleHideDialog} text="Close" />
            </DialogFooter>
        </Dialog>
    );
};
