import { Icon } from '@fluentui/react/lib/Icon';
import { useBoolean } from '@uifabric/react-hooks';
import { groupBy } from 'lodash';
import { ComboBox, PrimaryButton } from 'office-ui-fabric-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Position, Row } from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import { useDispatch } from 'react-redux';
import { deleteTask, updateTaskVisible } from '../../api/call';
import { closeDialog, openConfirm, openDialog } from '../../stores/ui/dialog';
import { deleteLoader, setLoader } from '../../stores/ui/ui';

import {
    Columns,
    editiCDSelectTaskData,
    iCDTaskMinorCategory,
    iCDTaskScreenData,
} from '../../types/types';

import { IcdMaintenanceAddModal } from './IcdMaintenanceAddModal';
import { IcdMaintenanceEditModal } from './IcdMaintenanceEditModal';

//全グループを開く際に使用する配列
function createOpenTaskRows(props: iCDTaskScreenData) {
    const rows = new Set(['']);
    for (const TaskCategory of props.TaskCategoryOption) {
        rows.add(TaskCategory.text);
        for (const TaskClassification of props.TaskClassificationOption) {
            if (TaskCategory.key === TaskClassification.key2) {
                rows.add(TaskCategory.text + '_' + TaskClassification.text);
            }
            for (const TaskItems of props.TaskItemsOption) {
                if (
                    TaskCategory.key === TaskClassification.key2 &&
                    TaskClassification.key === TaskItems.key2
                ) {
                    rows.add(
                        TaskCategory.text + '_' + TaskClassification.text + '_' + TaskItems.text
                    );
                }
            }
        }
    }
    return rows;
}

//フィルターレイアウト
const ComboBoxCustomStyledExampleStyles = {
    input: {
        height: '28px',
        position: 'relative' as 'relative',
        bottom: '7px',
    },
};

//メイン関数
export const IcdTaskMaintenance = (props: any) => {
    const dispatch = useDispatch();
    const [isAddModalOpen, { setTrue: showAddModal, setFalse: hideAddModal }] = useBoolean(false);
    const [isEditModalOpen, { setTrue: showEditModal, setFalse: hideEditModal }] = useBoolean(
        false
    );
    let [selectData, setSelectData] = useState<editiCDSelectTaskData>();
    //グループ関係配列
    const [openTaskGroup] = useState(createOpenTaskRows(props.taskData));

    //テーブルグループの初期値
    const [expandedGroups, setExpandedGroups] = useState(
        () => new Set(['taskMajorCategory', 'taskMiddleCategory'])
    );
    const groups = ['taskMajorCategory', 'taskMiddleCategory'];

    //フィルター機能の初期値
    const [filters, setFilters] = useState<Record<string, any>>({
        taskMajorCategory: '',
        taskMiddleCategory: '',
        taskMinorCategory: '',
    });

    //フィルター項目
    const filteredColumns = useMemo(() => {
        return [
            {
                key: 'taskMajorCategory',
                name: 'タスク大分類',
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            options={props.taskData.TaskCategoryOption}
                            text={filters.taskMajorCategory}
                            onChange={(e, option,index?: number, value?: string) => {
                                if(option === undefined){
                                    p.onChange(value);
                                } else{
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskMiddleCategory',
                name: 'タスク中分類',
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            options={props.taskData.TaskClassificationOption}
                            text={filters.taskMiddleCategory}
                            onChange={(e, option,index?: number, value?: string) => {
                                if(option === undefined){
                                    p.onChange(value);
                                } else{
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'taskMinorCategory',
                name: 'タスク小分類',
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            options={props.taskData.TaskItemsOption}
                            text={filters.taskMinorCategory}
                            onChange={(e, option,index?: number, value?: string) => {
                                if(option === undefined){
                                    p.onChange(value);
                                } else{
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'screenDisplay',
                name: '表示',
            },
        ];
    }, [
        filters.taskMajorCategory,
        filters.taskMiddleCategory,
        filters.taskMinorCategory,
        props.taskData.TaskCategoryOption,
        props.taskData.TaskClassificationOption,
        props.taskData.TaskItemsOption,
    ]);

    //フィルター機能
    const filteredRows = useMemo(() => {
        return props.taskData.taskData.filter((r: iCDTaskMinorCategory) => {
            return (
                (filters.taskMajorCategory
                    ? r.taskMajorCategory.includes(filters.taskMajorCategory)
                    : true) &&
                (filters.taskMiddleCategory
                    ? r.taskMiddleCategory.includes(filters.taskMiddleCategory)
                    : true) &&
                (filters.taskMinorCategory
                    ? r.taskMinorCategory.includes(filters.taskMinorCategory)
                    : true)
            );
        });
    }, [props.taskData.taskData, filters]);

    //フィルタークリーン
    function clearFilters() {
        setFilters({
            taskMajorCategory: '',
            taskMiddleCategory: '',
            taskMinorCategory: '',
        });
    }

    //カラムのグループ作成
    const groupByColumn: any = useCallback(
        (
            rows: any,
            columnKeys: any,
            expandedGroups: any,
            treeDepth: any = 0,
            parentId: any = ''
        ) => {
            if (columnKeys.length === 0) return rows;
            const gridRows = [];
            const [columnKey, ...remainingColumnKeys] = columnKeys;
            const groupedRows = groupBy(rows, columnKey);
            const groupedKeys = Object.keys(groupedRows);

            for (const groupKey of groupedKeys) {
                const groupId = parentId ? `${parentId}_${groupKey}` : groupKey;
                const isExpanded = expandedGroups.has(groupId);
                const rowGroupHeader = {
                    __metaData: {
                        groupId,
                        groupKey,
                        treeDepth,
                        isExpanded,
                        columnGroupName: props.taskData.taskColumns.find(
                            (c: Columns) => c.key === columnKey
                        )?.name,
                    },
                };
                gridRows.push(rowGroupHeader);
                if (isExpanded) {
                    gridRows.push(
                        ...groupByColumn(
                            groupedRows[groupKey],
                            remainingColumnKeys,
                            expandedGroups,
                            treeDepth + 1,
                            groupId
                        )
                    );
                }
            }
            return gridRows;
        },
        [props.taskData.taskColumns]
    );

    //グループ化
    const gridRows = useMemo(() => {
        return groupByColumn(filteredRows, groups, expandedGroups);
    }, [groupByColumn, filteredRows, groups, expandedGroups]);

    //グループ化
    function GroupRowRenderer(props: any) {
        if (props.row.__metaData === undefined) {
            return <Row {...props} />;
        }
        const { groupKey, isExpanded, treeDepth, columnGroupName, groupId } = props.row.__metaData;
        return (
            <div className="rdg-row rdg-row-default-group" tabIndex={0} style={{ top: props.top }}>
                <span
                    className="rdg-row-expand-icon"
                    style={{ marginLeft: treeDepth * 20 }}
                    onClick={() => onRowExpandToggle(groupId)}
                >
                    {isExpanded ? (
                        <Icon iconName="DoubleChevronDown" />
                    ) : (
                        <Icon iconName="DoubleChevronRight" />
                    )}
                </span>
                <strong>
                    {' '}
                    {columnGroupName}: {groupKey}
                </strong>
            </div>
        );
    }

    //グループ化に使用する関数
    function onRowExpandToggle(groupId: any) {
        const newExpandedGroups = new Set(expandedGroups);
        if (newExpandedGroups.has(groupId)) {
            newExpandedGroups.delete(groupId);
        } else {
            newExpandedGroups.add(groupId);
        }
        setExpandedGroups(newExpandedGroups);
    }

    //グループを全て閉じる
    function CloseGroup() {
        const newExpandedGroups = new Set(['']);
        setExpandedGroups(newExpandedGroups);
    }

    //グループを全て開く
    function OpenGroup() {
        setExpandedGroups(openTaskGroup);
    }

    function onFiltersChange(filters: Record<string, any>) {
        setFilters(filters);
    }

    function selectCell(p: Position) {
        const selectClass = ['Category', 'Classification', 'Item', 'display'][p.idx];
        let display = true;
        let id = 0;
        switch (selectClass) {
            case 'Category':
                display = gridRows[p.rowIdx].taskMajorCategoryDisplay;
                id = gridRows[p.rowIdx].taskMajorCategoryId;
                break;
            case 'Classification':
                display = gridRows[p.rowIdx].taskMiddleCategoryDisplay;
                id = gridRows[p.rowIdx].taskMiddleCategoryId;
                break;
            case 'Item':
                display = gridRows[p.rowIdx].display;
                id = gridRows[p.rowIdx].taskMinorCategoryId;

                break;
        }
        const selectRow: editiCDSelectTaskData = {
            id: id,
            selectClass: selectClass,
            selectData: gridRows[p.rowIdx],
            selectDisplayEnabled: !display,
        };
        selectData = selectRow;
        setSelectData(selectRow);
    }

    function openEditModal() {
        if (selectData) {
            showEditModal();
        } else {
            alert('1');
        }
    }

    const changeDisplay = (display: boolean) => async () => {
        try {
            if (selectData) {
                dispatch(setLoader('saveVisibleData'));
                dispatch(closeDialog());
                await updateTaskVisible(selectData?.selectData, display, selectData?.selectClass);
                dispatch(
                    openDialog({
                        title: '保存完了',
                        subText: '保存しました',
                        primaryAction: () => {
                            dispatch(closeDialog());
                            props.init();
                        },
                    })
                );
            }
        } catch (err) {
            console.log(err);

            dispatch(
                openDialog({
                    title: '変更エラー',
                    subText: err.message, // FIXME: エラーメッセージが取得できてない
                    primaryAction: () => {
                        dispatch(closeDialog());
                    },
                })
            );
        } finally {
            dispatch(deleteLoader('saveVisibleData'));
        }
    };

    const onChangeDisplay = (display: boolean) => {
        const displayText = display ? '表示' : '非表示';
        if (selectData) {
            let subText = '';
            let cationText = '';
            switch (selectData.selectClass) {
                case 'Category':
                    subText =
                        'タスク大分類：' +
                        selectData.selectData.taskMajorCategory +
                        'を' +
                        displayText +
                        'します。よろしいですか？';
                    cationText =
                        '※タスク大分類の表示状態を変更すると、それに紐づく中分類以下のデータの表示状態も変更となります。';
                    break;
                case 'Classification':
                    subText =
                        'タスク中分類：' +
                        selectData.selectData.taskMiddleCategory +
                        'を' +
                        displayText +
                        'に変更します。よろしいですか？';
                    cationText =
                        '※タスク中分類の表示状態を変更すると、それに紐づく小分類以下のデータの表示状態も変更となります。';
                    break;
                case 'Item':
                    subText =
                        'タスク小分類：' +
                        selectData.selectData.taskMinorCategory +
                        'を' +
                        displayText +
                        'しますよろしいですか？';
                    break;
            }
            dispatch(
                openConfirm({
                    title: '表示変更確認',
                    subText: subText,
                    cationText: cationText,
                    primaryAction: changeDisplay(display),
                    secondaryAction: () => dispatch(closeDialog()),
                })
            );
        }
    };

    const deleteData = () => async () => {
        try {
            if (selectData) {
                dispatch(setLoader('savePossessionData'));
                dispatch(closeDialog());
                await deleteTask(selectData?.selectData, selectData?.selectClass);
                dispatch(
                    openDialog({
                        title: '削除完了',
                        subText: '削除しました',
                        primaryAction: () => {
                            dispatch(closeDialog());
                            props.init();
                        },
                    })
                );
            }
        } catch (err) {
            console.log(err);
            dispatch(
                openDialog({
                    title: '削除エラー',
                    subText: err.message, // FIXME: エラーメッセージが取得できてない
                    primaryAction: () => {
                        dispatch(closeDialog());
                    },
                })
            );
        } finally {
            dispatch(deleteLoader('savePossessionData'));
        }
    };

    const onDelete = () => {
        if (selectData) {
            let subText = '';
            let cationText = '';
            switch (selectData.selectClass) {
                case 'Category':
                    subText =
                        'タスク大分類：' +
                        selectData.selectData.taskMajorCategory +
                        'を削除します。よろしいですか？';
                    cationText =
                        '※タスク大分類を削除すると、それに紐づく中分類以下のデータも削除されます。';
                    break;
                case 'Classification':
                    subText =
                        'タスク中分類：' +
                        selectData.selectData.taskMiddleCategory +
                        'を削除します。よろしいですか？';
                    cationText =
                        '※タスク中分類を削除すると、それに紐づく小分類以下のデータも削除されます。';
                    break;
                case 'Item':
                    subText =
                        'タスク小分類：' +
                        selectData.selectData.taskMinorCategory +
                        'を削除します。よろしいですか？';
                    break;
            }
            dispatch(
                openConfirm({
                    title: '削除確認',
                    subText: subText,
                    cationText: cationText,
                    primaryAction: deleteData(),
                    secondaryAction: () => dispatch(closeDialog()),
                })
            );
        }
    };

    useEffect(() => {
        setSelectData(undefined);
    }, [props.taskData]);

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12" style={{ marginTop: 10 }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg7">
                    <PrimaryButton
                        text="表示"
                        style={{ margin: 2 }}
                        disabled={
                            !selectData ||
                            !selectData.selectDisplayEnabled ||
                            selectData.selectClass === 'display'
                        }
                        onClick={() => onChangeDisplay(true)}
                    />
                    <PrimaryButton
                        text="非表示"
                        style={{ margin: 2 }}
                        disabled={
                            !selectData ||
                            selectData.selectDisplayEnabled ||
                            selectData.selectClass === 'display'
                        }
                        onClick={() => onChangeDisplay(false)}
                    />
                    <PrimaryButton
                        text="追加"
                        style={{ margin: 2 }}
                        onClick={() => showAddModal()}
                    />
                    <PrimaryButton
                        text="編集"
                        style={{ margin: 2 }}
                        onClick={() => openEditModal()}
                        disabled={!selectData || selectData.selectClass === 'display'}
                    />
                    <PrimaryButton
                        text="削除"
                        style={{ margin: 2 }}
                        disabled={!selectData || selectData.selectClass === 'display'}
                        onClick={onDelete}
                    />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5" style={{ textAlign: 'right' }}>
                    <PrimaryButton text="全て開く" onClick={OpenGroup} style={{ margin: 2 }} />
                    <PrimaryButton text="全て閉じる" onClick={CloseGroup} style={{ margin: 2 }} />
                    <PrimaryButton
                        text="フィルター初期化"
                        onClick={clearFilters}
                        style={{ margin: 2 }}
                    />
                </div>
            </div>
            <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12" style={{ marginTop: 10 }}>
                <DataGrid
                    columns={filteredColumns}
                    rows={gridRows}
                    height={500}
                    enableFilters={true}
                    filters={filters}
                    onFiltersChange={(filter) => {
                        onFiltersChange(filter);
                    }}
                    onSelectedCellChange={(p) => selectCell(p)}
                    rowRenderer={(p) => (
                        <GroupRowRenderer {...p} onRowExpandToggle={onRowExpandToggle} />
                    )}
                />
            </div>
            <IcdMaintenanceAddModal
                data={props.taskData}
                isModalOpen={isAddModalOpen}
                hideModal={hideAddModal}
                mode={'task'}
                init={props.init}
            />
            {selectData ? (
                <IcdMaintenanceEditModal
                    data={props.taskData}
                    selectData={selectData}
                    isModalOpen={isEditModalOpen}
                    hideModal={hideEditModal}
                    mode={'task'}
                    init={props.init}
                />
            ) : (
                ''
            )}
        </div>
    );
};
