import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLoginUserData } from '../api/call';
import { AppThunk } from './index';
import { deleteLoader, setLoader } from './ui/ui';

export interface User {
    id?: number;
    roleId?: number;
    roleName?: string;
    groupId?: number;
    groupName?: string;
    loginId?: string;
    name?: string;
    fiscalYearId?: number;
    isLogin?: boolean;
}

// Stateの初期状態
const initialState: User = {
    isLogin: false,
};

// Sliceを生成する
export const loginUserSlice = createSlice({
    name: 'login-user',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<User>) => {
            state.id = action.payload.id;
            state.roleId = action.payload.roleId;
            state.roleName = action.payload.roleName;
            state.groupId = action.payload.groupId;
            state.groupName = action.payload.groupName;
            state.loginId = action.payload.loginId;
            state.name = action.payload.name;
            state.fiscalYearId = 5; // FIXME: 現在年度をサーバー側から取得してセット
            state.isLogin = true;
        },
    },
});

// Reducerをエクスポートする
export const userReducer = loginUserSlice.reducer;

export const fetchLoginUser = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoader('UserData'));
        const data = await getLoginUserData();
        dispatch(loginUserSlice.actions.setUserData(data));
    } catch (err) {
        console.log('fetchLoginUser', JSON.stringify(err));
        alert(JSON.stringify(err));
        // dispatch(setError(err));
    } finally {
        dispatch(deleteLoader('UserData'));
    }
};
