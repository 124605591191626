import { combineReducers } from 'redux';
import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

// それぞれ slice.reducer を default export している前提
import { userReducer } from './loginuser';
import { errorReducer } from './error';
import { dialogReducer } from './ui/dialog';
import { userListReducer } from './userList';
import { uiReducer } from './ui/ui';

const reducer = combineReducers({
    user: userReducer,
    error: errorReducer,
    dialog: dialogReducer,
    userList: userListReducer,
    ui: uiReducer,
});

export type RootState = ReturnType<typeof reducer>;

export const store = configureStore({ reducer });

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
