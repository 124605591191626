import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ComboBox, Label, IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../stores';
import { User } from '../../stores/loginuser';
import {
    ChartData,
    ComparisonDto,
    ComparisonSummaryDto,
    ComparisonUsersDto,
    radarChart,
} from '../../types/types';
import { deleteLoader, setLoader } from '../../stores/ui/ui';
import {
    getUserData,
    getSkillSummary,
    getSkillComparisonUsers,
    getUserChartData,
    getTaskComparisonUsers,
    getTaskSummary,
    getSkillGroupSummary,
    getTaskGroupSummary,
    getYearsList,
    getUsersList,
} from '../../api/call';
import { TendencyComparisonRadarChart } from '../chart/TendencyRadarChart';
import { SelectableOptionMenuItemType } from '@fluentui/react';

const selectSummary: IComboBoxOption[] = [
    { key: '0', text: '社内平均', index: 0 },
    { key: '1', text: '所属グループ平均', index: 1 },
];

function createUsersList(usersList: ComparisonDto['UsersListDto'] | undefined) {
    const rows: IComboBoxOption[] = [];
    let groupName = '';
    usersList?.forEach((data) => {
        if (data.groupName !== groupName) {
            rows.push({
                key: data.groupName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            });
            rows.push({
                key: data.groupName + 'Header',
                text: data.groupName,
                itemType: SelectableOptionMenuItemType.Header,
            });
            groupName = data.groupName;
        }
        rows.push({ key: String(data.userId), text: data.name, index: data.userId });
    });

    return rows;
}

function createYearsList(yearsList: ComparisonDto['YearsListDto'] | undefined) {
    const rows: IComboBoxOption[] = [];
    yearsList?.forEach((data) => {
        rows.push({ key: String(data.fiscalYearId), text: data.name, index: data.fiscalYearId });
    });

    return rows;
}

export const Comparison = () => {
    const dispatch = useDispatch();

    // 全体stateより取得
    const loginUser = useSelector((state: RootState) => state.user);

    // クエリーパラメータからユーザIDと年度IDを取得する
    const urlParams = new URLSearchParams(useLocation().search);
    const targetUserId = Number(urlParams.get('userId') ?? loginUser.id!);
    const targetFiscalYearId = Number(urlParams.get('fiscalYearId') ?? loginUser.fiscalYearId!);

    // 単体State
    const [user, setUserData] = useState<User>();
    const [userChartData, setUserChartData] = useState<ChartData>(); //ログアウトユーザのデータ

    const [skillComparisonUsers, setSkillComparisonUsers] = useState<
        ComparisonDto['ComparisonUsersDto']
    >(); //比較スキルユーザ情報
    const [taskComparisonUsers, setTaskComparisonUsers] = useState<
        ComparisonDto['ComparisonUsersDto']
    >();
    const [skillSummary, setSkillSummary] = useState<ComparisonDto['ComparisonSummaryDto']>(); //社内スキル比較サマリ情報
    const [taskSummary, setTaskSummary] = useState<ComparisonDto['ComparisonSummaryDto']>(); //社内タスク比較サマリ情報
    const [skillGroupSummary, setSkillGroupSummary] = useState<
        ComparisonDto['ComparisonSummaryDto']
    >(); //グループスキル比較サマリ情報
    const [taskGroupSummary, setTaskGroupSummary] = useState<
        ComparisonDto['ComparisonSummaryDto']
    >(); //グループタスク比較サマリ情報
    const [usersList, setUsersList] = useState<IComboBoxOption[]>(); //ユーザ情報
    const [yearsList, setYearsList] = useState<IComboBoxOption[]>(); //年度情報

    const [skillChartData, setSkillChartData] = useState<ChartData['skillChart']>();
    const [taskChartData, setTaskChartData] = useState<ChartData['taskChart']>();
    const [skillSummaryChartData, setSkillSummaryChartData] = useState<ChartData['skillChart']>();
    const [taskSummaryChartData, setTaskSummaryChartData] = useState<ChartData['taskChart']>();
    const [selectUser, setSelectUser] = useState<number>();
    const [selectYear, setSelectYear] = useState<number>();

    //初期社員比較
    const createSkillChartData = useCallback(
        (List: ChartData | undefined, skillComparisonUsers: ComparisonUsersDto[]) => {
            let skillChart: radarChart[] | undefined = [];
            const fiscalYearId: number | undefined = targetFiscalYearId;
            if (fiscalYearId !== undefined) {
                const year = fiscalYearId;
                setSelectUser(targetUserId);
                setSelectYear(year);
                const skillComparisonUsersData = skillComparisonUsers?.filter(
                    (e) => e.userId === targetUserId && e.fiscalYearId === year
                );

                if (skillComparisonUsersData?.length === 0) {
                    skillChart = List?.skillChart;
                } else {
                    List?.skillChart?.forEach((List) => {
                        const usersData = skillComparisonUsersData?.filter(
                            (e) => e.fieldName === List.name
                        );
                        if (usersData?.length === 0 || usersData === undefined) {
                            skillChart?.push({
                                name: List.name,
                                A: List.A,
                                B: List.B,
                            });
                        } else {
                            const value: number = usersData[0].value;
                            skillChart?.push({
                                name: List.name,
                                A: List.A,
                                B: value,
                            });
                        }
                    });
                }
            }
            return skillChart;
        },
        [targetFiscalYearId, targetUserId]
    );

    //初期社員比較
    const createTaskChartData = useCallback(
        (List: ChartData | undefined, taskComparisonUsers: ComparisonUsersDto[]) => {
            let taskChart: radarChart[] | undefined = [];
            const fiscalYearId: number | undefined = targetFiscalYearId;
            if (fiscalYearId !== undefined) {
                const year = fiscalYearId;
                const taskComparisonUsersData = taskComparisonUsers?.filter(
                    (e) => e.userId === targetUserId && e.fiscalYearId === year
                );

                if (taskComparisonUsersData?.length === 0) {
                    taskChart = List?.taskChart;
                } else {
                    List?.taskChart?.forEach((List) => {
                        const usersData = taskComparisonUsersData?.filter(
                            (e) => e.fieldName === List.name
                        );
                        if (usersData?.length === 0 || usersData === undefined) {
                            taskChart?.push({
                                name: List.name,
                                A: List.A,
                                B: List.B,
                            });
                        } else {
                            const value: number = usersData[0].value;
                            taskChart?.push({
                                name: List.name,
                                A: List.A,
                                B: value,
                            });
                        }
                    });
                }
            }
            return taskChart;
        },
        [targetFiscalYearId, targetUserId]
    );

    //APIからデータを取得
    const setUserChart = useCallback(async () => {
        try {
            if (targetUserId && targetFiscalYearId) {
                dispatch(setLoader('skillComparision'));
                const user = await getUserData(targetUserId);
                const userChartData = await getUserChartData(targetUserId, targetFiscalYearId);
                const skillComparisonUsersData = await getSkillComparisonUsers();
                const taskComparisonUsersData = await getTaskComparisonUsers();

                const skillSummaryData = await getSkillSummary(targetFiscalYearId);
                const taskSummaryData = await getTaskSummary(targetFiscalYearId);

                const skillGroupSummaryData = await getSkillGroupSummary(
                    targetFiscalYearId,
                    targetUserId
                );
                const taskGroupSummaryData = await getTaskGroupSummary(
                    targetFiscalYearId,
                    targetUserId
                );

                const usersListData = await getUsersList();
                const yearsListData = await getYearsList();

                setUserData(user);
                setUserChartData(userChartData);
                setUsersList(createUsersList(usersListData));
                setYearsList(createYearsList(yearsListData));

                setSkillComparisonUsers(skillComparisonUsersData);
                setTaskComparisonUsers(taskComparisonUsersData);
                setSkillSummary(skillSummaryData);
                setTaskSummary(taskSummaryData);
                setSkillGroupSummary(skillGroupSummaryData);
                setTaskGroupSummary(taskGroupSummaryData);

                setSkillChartData(createSkillChartData(userChartData, skillComparisonUsersData));
                setTaskChartData(createTaskChartData(userChartData, taskComparisonUsersData));

                setSkillSummaryChartData(
                    createSkillSummaryChartData(userChartData, skillSummaryData)
                );
                setTaskSummaryChartData(createTaskSummaryChartData(userChartData, taskSummaryData));
            }
        } catch (err) {
            console.log('Comparison#setUserChart', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('skillComparision'));
        }
    }, [createSkillChartData, createTaskChartData, dispatch, targetFiscalYearId, targetUserId]);

    useEffect(() => {
        setUserChart();
    }, [setUserChart]);

    //サマリ初期表示
    function createSkillSummaryChartData(
        List: ChartData | undefined,
        skillSummaryData: ComparisonSummaryDto[]
    ) {
        let skillSummaryChart: radarChart[] | undefined = [];
        List?.skillChart?.forEach((userChartData) => {
            const summaryData = skillSummaryData?.filter((e) => e.fieldName === userChartData.name);
            if (summaryData?.length === 0 || summaryData === undefined) {
                skillSummaryChart?.push({
                    name: userChartData.name,
                    A: userChartData.A,
                    B: userChartData.B,
                });
            } else {
                const value: number = summaryData[0].avgValue;
                skillSummaryChart?.push({
                    name: userChartData.name,
                    A: userChartData.A,
                    B: value,
                });
            }
        });

        return skillSummaryChart;
    }
    //サマリ初期表示
    function createTaskSummaryChartData(
        List: ChartData | undefined,
        taskSummaryData: ComparisonSummaryDto[]
    ) {
        let taskSummaryChart: radarChart[] | undefined = [];
        List?.taskChart?.forEach((userChartData) => {
            const summaryData = taskSummaryData?.filter((e) => e.fieldName === userChartData.name);
            if (summaryData?.length === 0 || summaryData === undefined) {
                taskSummaryChart?.push({
                    name: userChartData.name,
                    A: userChartData.A,
                    B: userChartData.B,
                });
            } else {
                const value: number = summaryData[0].avgValue;
                taskSummaryChart?.push({
                    name: userChartData.name,
                    A: userChartData.A,
                    B: value,
                });
            }
        });
        return taskSummaryChart;
    }

    const rowStyle = {
        marginLeft: '20px',
        marginRight: '20px',
    };

    const borderRowStyle = {
        marginLeft: '20px',
        marginRight: '20px',
        paddingBottom: '5px',
        borderBottom: 'solid 1px #0078d4',
    };

    const skillChart = (
        <TendencyComparisonRadarChart
            kbn={'skill'}
            userId={targetUserId!}
            fiscalYearId={targetFiscalYearId!}
            data={skillChartData}
            name={'自己スキル'}
        />
    );
    const taskChart = (
        <TendencyComparisonRadarChart
            kbn={'task'}
            userId={targetUserId!}
            fiscalYearId={targetFiscalYearId!}
            data={taskChartData}
            name={'自己タスク'}
        />
    );

    const summarySkillChart = (
        <TendencyComparisonRadarChart
            kbn={'skill'}
            userId={targetUserId!}
            fiscalYearId={targetFiscalYearId!}
            data={skillSummaryChartData}
            name={'自己スキル'}
        />
    );
    const summaryTaskChart = (
        <TendencyComparisonRadarChart
            kbn={'task'}
            userId={targetUserId!}
            fiscalYearId={targetFiscalYearId!}
            data={taskSummaryChartData}
            name={'自己タスク'}
        />
    );

    function _summaryChange(e: React.FormEvent<IComboBox>, element?: IComboBoxOption) {
        const summary = element?.text;

        let skillSummaryChart: radarChart[] | undefined = [];
        if (summary === '社内平均') {
            userChartData?.skillChart?.forEach((userChartData) => {
                const summaryData = skillSummary?.filter((e) => e.fieldName === userChartData.name);
                if (summaryData?.length === 0 || summaryData === undefined) {
                    skillSummaryChart?.push({
                        name: userChartData.name,
                        A: userChartData.A,
                        B: userChartData.B,
                    });
                } else {
                    const value: number = summaryData[0].avgValue;
                    skillSummaryChart?.push({
                        name: userChartData.name,
                        A: userChartData.A,
                        B: value,
                    });
                }
            });
        } else if (summary === '所属グループ平均') {
            userChartData?.skillChart?.forEach((userChartData) => {
                const summaryData = skillGroupSummary?.filter(
                    (e) => e.fieldName === userChartData.name
                );
                if (summaryData?.length === 0 || summaryData === undefined) {
                    skillSummaryChart?.push({
                        name: userChartData.name,
                        A: userChartData.A,
                        B: userChartData.B,
                    });
                } else {
                    const value: number = summaryData[0].avgValue;
                    skillSummaryChart?.push({
                        name: userChartData.name,
                        A: userChartData.A,
                        B: value,
                    });
                }
            });
        } else {
            skillSummaryChart = userChartData?.skillChart;
        }

        let taskSummaryChart: radarChart[] | undefined = [];
        if (summary === '社内平均') {
            userChartData?.taskChart?.forEach((userChartData) => {
                const summaryData = taskSummary?.filter((e) => e.fieldName === userChartData.name);
                if (summaryData?.length === 0 || summaryData === undefined) {
                    taskSummaryChart?.push({
                        name: userChartData.name,
                        A: userChartData.A,
                        B: userChartData.B,
                    });
                } else {
                    const value: number = summaryData[0].avgValue;
                    taskSummaryChart?.push({
                        name: userChartData.name,
                        A: userChartData.A,
                        B: value,
                    });
                }
            });
        } else if (summary === '所属グループ平均') {
            userChartData?.taskChart?.forEach((userChartData) => {
                const summaryData = taskGroupSummary?.filter(
                    (e) => e.fieldName === userChartData.name
                );
                if (summaryData?.length === 0 || summaryData === undefined) {
                    taskSummaryChart?.push({
                        name: userChartData.name,
                        A: userChartData.A,
                        B: userChartData.B,
                    });
                } else {
                    const value: number = summaryData[0].avgValue;
                    taskSummaryChart?.push({
                        name: userChartData.name,
                        A: userChartData.A,
                        B: value,
                    });
                }
            });
        } else {
            taskSummaryChart = userChartData?.taskChart;
        }
        setSkillSummaryChartData(skillSummaryChart);
        setTaskSummaryChartData(taskSummaryChart);
    }

    const _userChange = useCallback(
        (e: React.FormEvent<IComboBox>, element?: IComboBoxOption) => {
            const userId = element?.index;
            const yearId = selectYear;

            const skillComparisonUsersData = skillComparisonUsers?.filter(
                (e) => e.userId === userId && e.fiscalYearId === yearId
            );
            let skillChart: radarChart[] | undefined = [];
            if (skillComparisonUsersData?.length === 0) {
                skillChart = userChartData?.skillChart;
            } else {
                userChartData?.skillChart?.forEach((userChartData) => {
                    const usersData = skillComparisonUsersData?.filter(
                        (e) => e.fieldName === userChartData.name
                    );
                    if (usersData?.length === 0 || usersData === undefined) {
                        skillChart?.push({
                            name: userChartData.name,
                            A: userChartData.A,
                            B: userChartData.B,
                        });
                    } else {
                        const value: number = usersData[0].value;
                        skillChart?.push({
                            name: userChartData.name,
                            A: userChartData.A,
                            B: value,
                        });
                    }
                });
            }

            const taskComparisonUsersData = taskComparisonUsers?.filter(
                (e) => e.userId === userId && e.fiscalYearId === yearId
            );
            let taskChart: radarChart[] | undefined = [];
            if (taskComparisonUsersData?.length === 0) {
                taskChart = userChartData?.taskChart;
            } else {
                userChartData?.taskChart?.forEach((userChartData) => {
                    const usersData = taskComparisonUsersData?.filter(
                        (e) => e.fieldName === userChartData.name
                    );
                    if (usersData?.length === 0 || usersData === undefined) {
                        taskChart?.push({
                            name: userChartData.name,
                            A: userChartData.A,
                            B: userChartData.B,
                        });
                    } else {
                        const value: number = usersData[0].value;
                        taskChart?.push({
                            name: userChartData.name,
                            A: userChartData.A,
                            B: value,
                        });
                    }
                });
            }

            setSkillChartData(skillChart);
            setTaskChartData(taskChart);
            setSelectUser(userId);
            setSelectYear(yearId);
        },
        [selectYear, skillComparisonUsers, taskComparisonUsers, userChartData]
    );

    const _yearChange = useCallback(
        (e: React.FormEvent<IComboBox>, element?: IComboBoxOption) => {
            const yearId = element?.index;
            const userId = selectUser;

            const skillComparisonUsersData = skillComparisonUsers?.filter(
                (e) => e.userId === userId && e.fiscalYearId === yearId
            );
            let skillChart: radarChart[] | undefined = [];
            if (skillComparisonUsersData?.length === 0) {
                skillChart = userChartData?.skillChart;
            } else {
                userChartData?.skillChart?.forEach((userChartData) => {
                    const usersData = skillComparisonUsersData?.filter(
                        (e) => e.fieldName === userChartData.name
                    );
                    if (usersData?.length === 0 || usersData === undefined) {
                        skillChart?.push({
                            name: userChartData.name,
                            A: userChartData.A,
                            B: userChartData.B,
                        });
                    } else {
                        const value: number = usersData[0].value;
                        skillChart?.push({
                            name: userChartData.name,
                            A: userChartData.A,
                            B: value,
                        });
                    }
                });
            }
            const taskComparisonUsersData = taskComparisonUsers?.filter(
                (e) => e.userId === userId && e.fiscalYearId === yearId
            );
            let taskChart: radarChart[] | undefined = [];
            if (taskComparisonUsersData?.length === 0) {
                taskChart = userChartData?.taskChart;
            } else {
                userChartData?.taskChart?.forEach((userChartData) => {
                    const usersData = taskComparisonUsersData?.filter(
                        (e) => e.fieldName === userChartData.name
                    );
                    if (usersData?.length === 0 || usersData === undefined) {
                        taskChart?.push({
                            name: userChartData.name,
                            A: userChartData.A,
                            B: userChartData.B,
                        });
                    } else {
                        const value: number = usersData[0].value;
                        taskChart?.push({
                            name: userChartData.name,
                            A: userChartData.A,
                            B: value,
                        });
                    }
                });
            }
            setSkillChartData(skillChart);
            setTaskChartData(taskChart);
            setSelectUser(userId);
            setSelectYear(yearId);
        },
        [selectUser, skillComparisonUsers, taskComparisonUsers, userChartData]
    );

    const usersListOption = useMemo(() => {
        if (targetUserId !== undefined) {
            const user = usersList;
            const id: string = String(targetUserId);
            if (user !== undefined) {
                return (
                    <ComboBox
                        style={{ maxWidth: 300 }}
                        defaultSelectedKey={id}
                        allowFreeform={true}
                        autoComplete="on"
                        options={usersList}
                        onChange={_userChange}
                    />
                );
            }
        }
        return '';
    }, [targetUserId, usersList, _userChange]);

    //TODO 年度マスタを使用する際に使う変数
    // eslint-disable-next-line
    const yearsListOption = useMemo(() => {
        if (targetFiscalYearId !== undefined) {
            const years = yearsList;
            const fiscalYearId: string = String(targetFiscalYearId - 1);

            if (years !== undefined) {
                return (
                    <ComboBox
                        style={{ maxWidth: 300 }}
                        defaultSelectedKey={fiscalYearId}
                        autoComplete="on"
                        options={yearsList}
                        onChange={_yearChange}
                    />
                );
            }
        }
        return '';
    }, [targetFiscalYearId, yearsList, _yearChange]);

    const selectUserDisplay = useMemo(() => {
        if (user === undefined) {
            return '';
        } else {
            return '（' + user.groupName + ' ' + user.name + '）';
        }
    }, [user]);

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h1>スキル比較{selectUserDisplay}</h1>
                </div>
            </div>
            <div className="ms-Grid-row" style={borderRowStyle}>
                <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg3">
                    <Label>サマリ</Label>
                    <ComboBox
                        style={{ maxWidth: 300 }}
                        defaultSelectedKey="0"
                        autoComplete="on"
                        options={selectSummary}
                        onChange={_summaryChange}
                    />
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg3" />
                <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg3">
                    <Label>社員名</Label>
                    {usersListOption}
                </div>
                {/*TODO 年度マスタを後で使用する
                <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg3">
                    <Label>年度</Label>
                    {yearsListOption}
                </div>
                */}
            </div>

            <div className="ms-Grid-row" style={rowStyle}>
                <h2 style={{ marginTop: '4px' }}>スキル</h2>
            </div>
            <div className="ms-Grid-row" style={borderRowStyle}>
                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg5" style={{ minWidth: '400px' }}>
                    <div className="ms-Grid-row">
                        <h3 style={{ margin: '0px' }}>サマリ比較</h3>
                    </div>
                    <div className="ms-Grid-row">{summarySkillChart}</div>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2" />
                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg5" style={{ minWidth: '400px' }}>
                    <div className="ms-Grid-row">
                        <h3 style={{ margin: '0px' }}>社員比較</h3>
                    </div>
                    <div className="ms-Grid-row">{skillChart}</div>
                </div>
            </div>
            <div className="ms-Grid-row" style={rowStyle}>
                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg2">
                    <h2 style={{ marginTop: '4px' }}>タスク</h2>
                </div>
            </div>
            <div className="ms-Grid-row" style={rowStyle}>
                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg5" style={{ minWidth: '400px' }}>
                    <div className="ms-Grid-row">
                        <h3 style={{ margin: '0px' }}>サマリ比較</h3>
                    </div>
                    <div className="ms-Grid-row">{summaryTaskChart}</div>
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg2" />
                <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg5" style={{ minWidth: '400px' }}>
                    <div className="ms-Grid-row">
                        <h3 style={{ margin: '0px' }}>社員比較</h3>
                    </div>
                    <div className="ms-Grid-row">{taskChart}</div>
                </div>
            </div>
        </div>
    );
};
