import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    ComboBox,
    Label,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    TextField,
    IComboBox,
    IComboBoxOption,
} from 'office-ui-fabric-react';
import { DetailsList, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';

import { RootState } from '../../stores';
import { getIcdFileTypes, getIcdImportHistories, executeIcdImport } from '../../api/call';
import { setLoader, deleteLoader } from '../../stores/ui/ui';

import { useSelector, useDispatch } from 'react-redux';
import { IcdFileType, IcdImportHistory } from '../../types/types';

export const IcdImport = () => {
    // 全体stateより取得
    const loginUser = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    // 単体State
    const [allItems, setAllItems] = useState<any[]>();
    const [icdFileTypeItems, setIcdFileTypeItems] = useState<any[]>();
    const [icdFileTypeData, setIcdFileType] = useState<IcdFileType[]>();
    const [icdImportHistoryData, setIcdImportHistory] = useState<IcdImportHistory[]>();
    const [error, setError] = useState<string | JSX.Element>('');
    const [fileName, setFileName] = useState('ファイルを選択して下さい');
    const [file, setFile] = useState<File>();
    const [fileType, setFileType] = useState('');

    // iCDファイル種別
    const setIcdFileTypeData = useCallback(async () => {
        try {
            dispatch(setLoader('IcdImport'));
            if (loginUser.id && loginUser.fiscalYearId) {
                // iCDファイル種別
                const icdFileTypes = await getIcdFileTypes();
                setIcdFileType(icdFileTypes);
            }
        } catch (err) {
            console.log('IcdImport#setIcdFileTypeData', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('IcdImport'));
        }
    }, [dispatch, loginUser.fiscalYearId, loginUser.id]);

    // iCD取り込み履歴情報
    const setIcdImportHistoryData = useCallback(async () => {
        try {
            dispatch(setLoader('IcdImport'));
            if (loginUser.id && loginUser.fiscalYearId) {
                // iCDインポート取り込み履歴
                const icdImportHistories = await getIcdImportHistories();
                setIcdImportHistory(icdImportHistories);
            }
        } catch (err) {
            console.log('IcdImport#setIcdImportHistoryData', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
        } finally {
            dispatch(deleteLoader('IcdImport'));
        }
    }, [dispatch, loginUser.fiscalYearId, loginUser.id]);

    // APIからデータを取得する
    useEffect(() => {
        setIcdFileTypeData();
        setIcdImportHistoryData();
    }, [setIcdFileTypeData, setIcdImportHistoryData]);

    // iCDファイル種別とiCD取り込み履歴が取得できた場合
    useMemo(() => {
        // iCDファイル種別
        const _icdTypeItems = [{ key: '', text: '---- ファイルの種類を選択してください -----' }];
        icdFileTypeData?.map((data) => _icdTypeItems.push({ key: data.id, text: data.fileName }));
        setIcdFileTypeItems(_icdTypeItems);

        // iCD取り込み履歴
        let _allItems: any[] = [];
        icdImportHistoryData?.map((row) => {
            let result = '';
            switch (row.result) {
                case 0:
                    result = '処理中';
                    break;
                case 1:
                    result = '正常終了';
                    break;
                case 2:
                    result = '異常終了';
                    break;
            }
            _allItems.push({
                key: row.id,
                fileName: row.icdFileType,
                result: result,
                LastUpdater: row.lastUpdatedBy,
                LastUpdateDate: row.lastUpdatedAt,
            });
        });
        setAllItems(_allItems);
    }, [icdFileTypeData, icdImportHistoryData]);

    //履歴の項目名
    const columns = [
        {
            key: 'column1',
            name: 'ファイル名',
            fieldName: 'fileName',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
        },
        {
            key: 'column2',
            name: '取り込み結果',
            fieldName: 'result',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'column3',
            name: '最新更新者',
            fieldName: 'LastUpdater',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'column4',
            name: '最新更新日',
            fieldName: 'LastUpdateDate',
            minWidth: 250,
            maxWidth: 300,
            isResizable: true,
        },
    ];

    function fileTypeChange(e: React.FormEvent<IComboBox>, option: IComboBoxOption | undefined) {
        if (option !== undefined) {
            setFileType(option.key + '');
        }
    }

    const fileInputRef = React.createRef<HTMLInputElement>();

    function fileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files !== null) {
            let selectedFile = e.target.files[0];
            if (selectedFile !== undefined) {
                setFile(selectedFile);
                setFileName(selectedFile.name);
            }
        } else {
            setFileName('');
        }
    }

    function displayMessageBar(messageBarType: MessageBarType, messageText: string): JSX.Element {
        return (
            <MessageBar
                messageBarType={messageBarType}
                isMultiline={true}
                dismissButtonAriaLabel="Close"
            >
                {messageText}
            </MessageBar>
        );
    }

    function execute() {
        try {
            dispatch(setLoader('IcdImport'));
            if (
                loginUser.id &&
                loginUser.fiscalYearId &&
                fileType.length > 0 &&
                file &&
                fileName.length > 0
            ) {
                // サーバーにPOST送信する
                executeIcdImport(loginUser.id, loginUser.fiscalYearId, parseInt(fileType), file)
                    .then(() => {
                        // TODO ファイル選択をクリアできない
                        setFileType('');
                        setFile(undefined);
                        setFileName('');
                        // ファイル種別を再取得
                        setIcdFileTypeData();
                        // 履歴を再取得
                        setIcdImportHistoryData();
                        setError(
                            displayMessageBar(
                                MessageBarType.info,
                                'ファイルを取り込み中です。取り込み終了まで時間がかかりますので、しばらくお待ちください。'
                            )
                        );
                    })
                    .catch((error) => {
                        // TODO リクエスト前の選択値がファイル選択のプルダウンで再選択されない
                        // ファイル種別を再取得
                        setIcdFileTypeData();
                        // 履歴を再取得
                        setIcdImportHistoryData();
                        console.log('エラー: ' + error.response);
                        return setError(
                            displayMessageBar(MessageBarType.error, error.response.data)
                        );
                    });
            } else {
                setError(displayMessageBar(MessageBarType.error, '未入力の項目があります。'));
            }
        } catch (err) {
            console.log('IcdImport#execute', JSON.stringify(err));
            alert(JSON.stringify(err));
            // dispatch(setError(err.message));
            setError(
                displayMessageBar(
                    MessageBarType.error,
                    'ファイル取り込みの送信中にエラーが発生しました。'
                )
            );
        } finally {
            dispatch(deleteLoader('IcdImport'));
        }
    }

    return (
        <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <h1>iCD取り込み</h1>
                </div>
            </div>

            <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12" style={{ margin: 10 }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                    <Label>ファイル選択</Label>
                    <ComboBox
                        style={{ maxWidth: 300 }}
                        // TODO 初期値を設定できない
                        autoComplete="off"
                        options={icdFileTypeItems}
                        onChange={fileTypeChange}
                    />
                </div>
            </div>

            <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12" style={{ margin: 10 }}>
                <div
                    className="ms-Grid-col ms-sm12 ms-md12 ms-lg4"
                    style={{ marginTop: 5, maxWidth: 315 }}
                >
                    <TextField readOnly disabled value={fileName} />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4" style={{ marginTop: 5 }}>
                    <PrimaryButton
                        text="ファイル選択"
                        onClick={() => {
                            if (fileInputRef.current != null) {
                                fileInputRef.current.click();
                            }
                        }}
                    />
                    <input ref={fileInputRef} type="file" hidden onChange={fileChange} />
                </div>
            </div>

            <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12" style={{ margin: 10 }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">{error}</div>
            </div>

            <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12" style={{ margin: 10 }}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <PrimaryButton
                        text="取り込み"
                        onClick={() => {
                            execute();
                        }}
                    />
                </div>
            </div>

            <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12" style={{ margin: 10 }}>
                <h2 style={{ margin: 0 }}>履歴</h2>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8">
                    <DetailsList
                        items={
                            allItems
                                ? allItems
                                : [
                                      {
                                          key: 1,
                                          fileName: '不明',
                                          result: '',
                                          LastUpdater: '',
                                          LastUpdateDate: '',
                                      },
                                  ]
                        }
                        columns={columns}
                        selectionMode={SelectionMode.none}
                    />
                </div>
            </div>
        </div>
    );
};
