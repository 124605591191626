import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { Link } from 'react-router-dom';
import { radarChart } from '../../types/types';

interface temp {
    [key: string]: { [alert: string]: JSX.Element };
}

interface prop {
    kbn: string;
    userId: number;
    fiscalYearId: number;
    data?: radarChart[];
    keyA: string;
    keyB: string;
}

export const GoalsBarChart = ({ kbn, userId, fiscalYearId, data, keyA, keyB }: prop) => {
    function CustomizedAxisTick(e: any) {
        let str: string = e.payload.value;
        if (str.length > 20) {
            const value: string = str.slice(0, 20);
            str = value + '...';
        }

        return (
            <g transform={`translate(${e.x},${e.y})`}>
                <text fill="#666">
                    <tspan textAnchor="end" x="0">
                        {str}
                    </tspan>
                </text>
            </g>
        );
    }

    const tempData: temp = {
        skill: {
            alert: (
                <>
                    <div>スキル目標が設定されていません</div>
                    <Link to={`/skilltarget?userId=${userId}&fiscalYearId=${fiscalYearId}`}>
                        スキル目標設定画面
                    </Link>
                    より目標を設定してください
                </>
            ),
        },
        task: {
            alert: (
                <>
                    <div>タスク目標が登録されていません</div>
                    <Link to={`/tasktarget?userId=${userId}&fiscalYearId=${fiscalYearId}`}>
                        タスク目標設定画面
                    </Link>
                    より目標を登録してください
                </>
            ),
        },
    };
    const alert = tempData[kbn];

    return !data || data.length < 1 ? (
        alert.alert
    ) : (
        <BarChart
            width={800}
            height={200}
            data={data}
            layout="vertical"
            style={{ fontSize: '13px' }}
            margin={{ top: 5, right: 0, left: 130, bottom: 5 }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis
                type="category"
                dataKey="name"
                width={150}
                interval={0}
                tick={CustomizedAxisTick}
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="A" fill="#0078d4" name={keyA} />
            <Bar dataKey="B" fill="#b7472a" name={keyB} />
        </BarChart>
    );
};
