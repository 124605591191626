import { CommandBarButton, Image } from 'office-ui-fabric-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { useDispatch, useSelector } from 'react-redux';

import { keycloak } from '../App';
import { RootState } from '../stores';

const theme = getTheme();

export const HeaderNav = () => {
    useDispatch();
    const loginUser = useSelector((state: RootState) => state.user.name);

    return (
        <div
            style={{
                flex: '0 1 50px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '50px',
                borderBottom: 'solid 1px #0078d4',
                width: '100%',
                zIndex: 300,
                position: 'fixed',
                backgroundColor: 'white',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <div
                    style={{
                        height: '100%',
                        width: '10px',
                        backgroundColor: theme.palette.themePrimary,
                    }}
                />
                <Link
                    to="/"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        textDecoration: 'none',
                    }}
                >
                    <Image
                        src="logo.png"
                        style={{
                            marginLeft: '10px',
                            height: '40px',
                        }}
                    />
                    <span
                        style={{
                            marginLeft: '5px',
                            fontSize: 30,
                            fontFamily: 'Patua One, cursive',
                            color: theme.palette.themePrimary,
                        }}
                    >
                        Crowt
                    </span>
                    <span
                        style={{
                            marginLeft: '10px',
                            fontSize: 15,
                            // 年賀状をHTMLとCSSで再現してみる – 毛筆フォントと縦書きCSS
                            // https://blog.members.co.jp/article/23725
                            fontFamily: "'HGP行書体','ＭＳ Ｐ明朝','MS PMincho','STKaiti'",
                            color: theme.palette.themePrimary,
                        }}
                    >
                        ～
                    </span>
                    <span
                        style={{
                            fontSize: 30,
                            fontFamily: "'HGP行書体','ＭＳ Ｐ明朝','MS PMincho','STKaiti'",
                            color: theme.palette.themePrimary,
                        }}
                    >
                        玄人
                    </span>
                    <span
                        style={{
                            marginLeft: '5px',
                            fontSize: 15,
                            fontFamily: "'HGP行書体','ＭＳ Ｐ明朝','MS PMincho','STKaiti'",
                            color: theme.palette.themePrimary,
                        }}
                    >
                        ～
                    </span>
                </Link>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <CommandBarButton
                    style={{ height: '100%', width: '100%' }}
                    text={loginUser}
                    iconProps={{ iconName: 'Contact' }}
                    menuProps={{
                        items: [
                            {
                                key: 'logout',
                                text: 'ログアウト',
                                iconProps: { iconName: 'OutOfOffice' },
                                onClick: () => {
                                    keycloak.logout();
                                },
                            },
                        ],
                    }}
                />
                <div
                    style={{
                        height: '100%',
                        width: '10px',
                        backgroundColor: theme.palette.themePrimary,
                    }}
                />
            </div>
        </div>
    );
};
