import { createSlice } from '@reduxjs/toolkit';

export interface ui {
    isLoading: string[];
}

// Stateの初期状態
const initialState: ui = {
    isLoading: [],
};

// Sliceを生成する
const slice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            let newLoading = state.isLoading.concat();
            newLoading.push(action.payload);
            state.isLoading = newLoading;
        },
        deleteLoader: (state, action) => {
            let nowLoading = state.isLoading.filter((n) => n !== action.payload);
            state.isLoading = nowLoading;
        },
        // etc...
    },
});

// Reducerをエクスポートする
export const uiReducer = slice.reducer;

// Action Creatorsをエクスポートする
export const { setLoader, deleteLoader } = slice.actions;
