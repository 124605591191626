import React, { useState, useEffect } from 'react';
import { DefaultButton, Overlay } from 'office-ui-fabric-react';
import { withRouter, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'office-ui-fabric-react/dist/css/fabric.css';
import { initializeIcons } from '@uifabric/icons';
import Keycloak from 'keycloak-js';

import { HeaderNav } from './Components/HeaderNav';
import { Sidemenu } from './Components/Sidemenu';
import { Home } from './Components/Home';
import { IcdMaintenance } from './Components/IcdMaintenance/IcdMaintenance';
import { IcdImport } from './Components/IcdImport/IcdImport';
import { OverView } from './Components/Possess/Overview';
import { Details } from './Components/Possess/Details';
import { Comparison } from './Components/Comparison/Comparison';
import { SkillTarget } from './Components/Target/SkillTarget';
import { TaskTarget } from './Components/Target/TaskTarget';
import { Error } from './Components/Error';
import { ScrollToTop } from './Components/ScrollToTop';
import { ConfirmDialog } from './Components/Dialog/ConfirmDialog';
import { Loading } from './Components/Loading';
import { RootState } from './stores';
import { fetchLoginUser } from './stores/loginuser';

initializeIcons();

export const keycloak = Keycloak('keycloak.json');

export const App = () => {
    const dispatch = useDispatch();
    const isLogin = useSelector((state: RootState) => state.user.isLogin);
    const [sideMenuStyle, setSideMenuStyle] = useState({
        width: '100%',
        padding: '10px',
        backgroundColor: 'white',
        marginLeft: '70px',
    });

    useEffect(() => {
        const authenticate = async () => {
            const authenticated = await keycloak.init({ onLoad: 'login-required' });

            if (authenticated) {
                dispatch(fetchLoginUser());
            }
        };
        authenticate();
    }, [dispatch]);

    const Buttons = withRouter(({ history }) => (
        <Overlay
            allowTouchBodyScroll={true}
            style={{
                marginTop: '53px',
                marginRight: '3px',
                textAlign: 'right',
                height: 0.2,
                zIndex: 3,
            }}
        >
            {!['/'].includes(history.location.pathname) && (
                <DefaultButton onClick={() => history.goBack()}>戻る</DefaultButton>
            )}
        </Overlay>
    ));

    const contents: JSX.Element = isLogin ? (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/IcdMaintenance" component={IcdMaintenance} />
            <Route path="/IcdImport" component={IcdImport} />
            <Route path="/OverView" component={OverView} />
            <Route path="/Details" component={Details} />
            <Route path="/Comparison" component={Comparison} />
            <Route path="/SkillTarget" component={SkillTarget} />
            <Route path="/TaskTarget" component={TaskTarget} />
            <Route path="/error" component={Error} />
        </Switch>
    ) : (
        <>
            <Switch>
                <Route path="/error" component={Error} />
            </Switch>
            <Loading />
        </>
    );

    return (
        <Router>
            <ScrollToTop>
                {/*Divを残りの画面スペースの高さに合わせる -*/}
                {/*https://www.it-swarm.dev/ja/html/div%E3%82%92%E6%AE%8B%E3%82%8A%E3%81%AE%E7%94%BB%E9%9D%A2%E3%82%B9%E3%83%9A%E3%83%BC%E3%82%B9%E3%81%AE%E9%AB%98%E3%81%95%E3%81%AB%E5%90%88%E3%82%8F%E3%81%9B%E3%82%8B/957400330/*/}
                <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
                    <Loading />
                    <HeaderNav />
                    <div
                        style={{
                            flex: '1 1 auto',
                            display: 'flex',
                            marginTop: '50px',
                        }}
                    >
                        <Sidemenu setSideMenuStyle={setSideMenuStyle} />
                        <div style={sideMenuStyle}>
                            <Buttons />
                            {contents}
                        </div>
                    </div>
                </div>
                <ConfirmDialog />
            </ScrollToTop>
        </Router>
    );
};
