import { Label, SelectableOptionMenuItemType } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { groupBy } from 'lodash';
import { ComboBox, PrimaryButton } from 'office-ui-fabric-react';
import React, { useCallback, useMemo, useState } from 'react';
import DataGrid, { Row } from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';

import {
    FilterOption,
    option,
    possessedSkillOption,
    skillDetailsData,
    skillGoalData,
    skillRowData,
    skillScreenData,
} from '../../types/types';

//全グループを開く際に使用する配列
function createOpenSkillRows(props: skillScreenData) {
    const rows = new Set(['']);
    for (const SkillCategory of props.SkillCategoryOption) {
        rows.add(SkillCategory.text);
        for (const SkillClassification of props.SkillClassificationOption) {
            if (SkillCategory.key === SkillClassification.key2) {
                rows.add(SkillCategory.text + '_' + SkillClassification.text);
            }
            for (const SkillItems of props.SkillItemsOption) {
                if (
                    SkillCategory.key === SkillClassification.key2 &&
                    SkillClassification.key === SkillItems.key2
                ) {
                    rows.add(
                        SkillCategory.text + '_' + SkillClassification.text + '_' + SkillItems.text
                    );
                }
            }
        }
    }
    return rows;
}

// スキル詳細フィルターを作成する関数
function createPossessedDetailsFilter(
    skillDetails: skillDetailsData[] | undefined,
    selectSkillCategory?: string,
    selectSkillClassification?: string,
    selectSkillItem?: string
) {
    let rows: possessedSkillOption | undefined = undefined;
    if (skillDetails !== undefined) {
        const skillCategoryOption: FilterOption['DefaultFilter'] = [];
        const skillClassificationOption: FilterOption['DefaultFilter'] = [];
        const skillItemsOption: FilterOption['DefaultFilter'] = [];

        let skillCategoryList: skillDetailsData[] = skillDetails?.filter(
            (element, index: number, self: skillDetailsData[]) =>
                self.findIndex((e) => e.skillCategory === element.skillCategory) === index
        );

        const filteredSkillCategoryList = skillCategoryList.filter(
            (element) =>
                (selectSkillClassification
                    ? element.skillClassification === selectSkillClassification
                    : true) && (selectSkillItem ? element.skillItem === selectSkillItem : true)
        );
        skillCategoryList =
            filteredSkillCategoryList.length > 0 ? filteredSkillCategoryList : skillCategoryList;

        let skillClassificationList: skillDetailsData[] = skillDetails?.filter(
            (element, index: number, self: skillDetailsData[]) =>
                self.findIndex(
                    (e) =>
                        e.skillCategory === element.skillCategory &&
                        e.skillClassification === element.skillClassification
                ) === index
        );

        const filteredSkillClassificationList = skillClassificationList.filter(
            (element) =>
                (selectSkillCategory ? element.skillCategory === selectSkillCategory : true) &&
                (selectSkillItem ? element.skillItem === selectSkillItem : true)
        );

        skillClassificationList =
            filteredSkillClassificationList.length > 0
                ? filteredSkillClassificationList
                : skillClassificationList;

        let skillItemList: skillDetailsData[] = skillDetails?.filter(
            (element, index: number, self: skillDetailsData[]) =>
                self.findIndex(
                    (e) =>
                        e.skillCategory === element.skillCategory &&
                        e.skillClassification === element.skillClassification &&
                        e.skillItem === element.skillItem
                ) === index
        );

        const filteredSkillItemList = skillItemList.filter(
            (element) =>
                (selectSkillCategory ? element.skillCategory === selectSkillCategory : true) &&
                (selectSkillClassification
                    ? element.skillClassification === selectSkillClassification
                    : true)
        );

        skillItemList = filteredSkillItemList.length > 0 ? filteredSkillItemList : skillItemList;

        //keyを紐づける
        skillCategoryOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });

        skillCategoryList.forEach((data, index) => {
            skillCategoryOption.push({
                key: String(index + 1),
                text: data.skillCategory,
                itemType: 0,
            });
        });

        let skillCategoryName: string = skillClassificationList[0].skillCategory;
        skillClassificationOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });
        skillClassificationOption.push(
            {
                key: skillCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: skillCategoryName + 'Header',
                text: skillCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );

        skillClassificationList.forEach((data, index) => {
            if (data.skillCategory !== skillCategoryName) {
                skillClassificationOption.push(
                    {
                        key: data.skillCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.skillCategory + 'Header' + index,
                        text: data.skillCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                skillCategoryName = data.skillCategory;
            }
            skillClassificationOption.push({
                key: String(index + 1),
                text: data.skillClassification,
                itemType: 0,
            });
        });

        skillCategoryName = skillItemList[0].skillCategory;
        skillItemsOption.push({
            key: '0',
            text: '',
            itemType: 0,
        });

        skillItemsOption.push(
            {
                key: skillCategoryName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: skillCategoryName + 'Header',
                text: skillCategoryName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );
        let skillClassificationName: string = skillItemList[0].skillClassification;
        skillItemsOption.push(
            {
                key: skillClassificationName + 'Divider',
                text: '-',
                itemType: SelectableOptionMenuItemType.Divider,
            },
            {
                key: skillClassificationName + 'Header',
                text: skillClassificationName,
                itemType: SelectableOptionMenuItemType.Header,
            }
        );

        skillItemList.forEach((data, index) => {
            if (data.skillCategory !== skillCategoryName) {
                skillItemsOption.push(
                    {
                        key: data.skillCategory + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.skillCategory + 'Header' + index,
                        text: data.skillCategory,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                skillCategoryName = data.skillCategory;
            }
            if (data.skillClassification !== skillClassificationName) {
                skillItemsOption.push(
                    {
                        key: data.skillClassification + 'Divider' + index,
                        text: '-',
                        itemType: SelectableOptionMenuItemType.Divider,
                    },
                    {
                        key: data.skillClassification + 'Header' + index,
                        text: data.skillClassification,
                        itemType: SelectableOptionMenuItemType.Header,
                    }
                );
                skillClassificationName = data.skillClassification;
            }
            skillItemsOption.push({ key: String(index + 1), text: data.skillItem, itemType: 0 });
        });

        rows = {
            SkillCategoryOption: skillCategoryOption,
            SkillClassificationOption: skillClassificationOption,
            SkillItemsOption: skillItemsOption,
        };
    }
    return rows;
}

//フィルターレイアウト
const ComboBoxCustomStyledExampleStyles = {
    input: {
        height: '28px',
        position: 'relative' as 'relative',
        bottom: '7px',
    },
};

//メイン関数
export const SkillDetails = (props: any) => {
    //グループ関係配列
    const [openSkillGroup] = useState(createOpenSkillRows(props.skillData));

    //テーブルグループの初期値
    const [expandedGroups, setExpandedGroups] = useState(
        () => new Set(['skillCategory', 'skillClassification'])
    );
    const groups = ['skillCategory', 'skillClassification'];

    //フィルター機能の初期値
    const [filters, setFilters] = useState<Record<string, any>>({
        skillCategoryDisplay: '',
        skillClassificationDisplay: '',
        skillItemsLink: '',
        ownedSkillCheckBox: '',
        target: '',
    });

    // フィルターの作成
    let skillDetailOption = createPossessedDetailsFilter(
        props.skillData.SkillDetailsOption,
        filters.skillCategoryDisplay,
        filters.skillClassificationDisplay,
        filters.skillItemsLink
    );

    //フィルター項目
    const filteredColumns = useMemo(() => {
        return [
            {
                key: 'skillCategoryDisplay',
                name: 'スキルカテゴリ',
                width: 140,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={filters.skillCategoryDisplay}
                            options={skillDetailOption?.SkillCategoryOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'skillClassificationDisplay',
                name: 'スキル分類',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={filters.skillClassificationDisplay}
                            options={skillDetailOption?.SkillClassificationOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'skillItemsLink',
                name: 'スキル項目',
                minWidth: 350,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            allowFreeform
                            autoComplete="on"
                            text={filters.skillItemsLink}
                            options={skillDetailOption?.SkillItemsOption}
                            onChange={(e, option, index?: number, value?: string) => {
                                if (option === undefined) {
                                    p.onChange(value);
                                } else {
                                    p.onChange(option?.text);
                                }
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'ownedSkillCheckBox',
                name: '保有',
                width: 100,
                filterRenderer: (p: any) => (
                    <div style={{ marginTop: 6 }}>
                        <ComboBox
                            options={props.skillData.OwnedSkillsOption}
                            text={filters.ownedSkillCheckBox}
                            onChange={(e, option) => {
                                p.onChange(option?.text);
                            }}
                            styles={ComboBoxCustomStyledExampleStyles}
                        />
                    </div>
                ),
            },
            {
                key: 'target',
                name: '目標',
                width: 50,
            },
        ];
    }, [
        filters.ownedSkillCheckBox,
        filters.skillCategoryDisplay,
        filters.skillClassificationDisplay,
        filters.skillItemsLink,
        props.skillData.OwnedSkillsOption,
        skillDetailOption,
    ]);

    const targetColumn = useMemo(() => {
        return(
            <div
                className="ms-Grid-row ms-sm12 ms-md12 ms-lg12"
                dir="ltr"
                style={{ marginBottom: 10, textAlign: 'left' ,maxWidth:'500px'}}
            >
                <div 
                    className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"
                    style={{ width:'50px',paddingRight:'0px',paddingLeft:'0px'}}
                >
                    <Label
                        styles = {{root:{width:'50px',textAlign: 'right'}}}
                    >
                        目標：
                    </Label>
                </div>
                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                    <ComboBox
                        options={props.skillData.TargetOption}
                        text={filters.target}
                        onChange={(e, option) => {
                            setFilters({...filters ,target: option?.text})
                        }}
                    />
                </div>
            </div>
        )
    },[filters,props.skillData.TargetOption])

    //フィルター機能
    const filteredRows = useMemo(() => {
        let rows: any;
        if (filters.target) {
            // フィルターで選んだ職種でユーザの目標をフィルタ
            const targetKey = props.skillData.TargetOption.filter(
                (r: option) => r.text === filters.target
            )[0].key;

            const targets = props.skillData.skillGoalData.filter(
                (sg: skillGoalData) => sg.jobSpecializedFieldId === targetKey
            );

            // スキルの対応結果を各行にマッピング
            rows = props.skillRows.map((skill: skillRowData) => {
                let essential = targets.filter(
                    (target: skillGoalData) => target.skillItemsId === skill.skillItemsId
                );

                skill.target = essential && essential.length > 0 ? essential[0].essentialSkill : '';
                return skill;
            });
        } else {
            // フィルタがリセットされた場合、全行の目標の対応結果もリセット
            rows = props.skillRows.map((skill: skillRowData) => {
                skill.target = '';
                return skill;
            });
        }

        return rows.filter((r: skillRowData) => {
            return (
                (filters.skillCategoryDisplay
                    ? r.skillCategory.includes(filters.skillCategoryDisplay)
                    : true) &&
                (filters.skillClassificationDisplay
                    ? r.skillClassification.includes(filters.skillClassificationDisplay)
                    : true) &&
                (filters.skillItemsLink ? r.skillItems.includes(filters.skillItemsLink) : true) &&
                (filters.ownedSkillCheckBox !== '' && filters.ownedSkillCheckBox !== 'ALL'
                    ? r.ownedSkillsValue === filters.ownedSkillCheckBox
                    : true) &&
                (filters.target ? r.target : true)
            );
        });
    }, [
        filters.target,
        filters.skillCategoryDisplay,
        filters.skillClassificationDisplay,
        filters.skillItemsLink,
        filters.ownedSkillCheckBox,
        props.skillData.TargetOption,
        props.skillData.skillGoalData,
        props.skillRows,
    ]);

    //フィルタークリーン
    function clearFilters() {
        setFilters({
            skillCategoryDisplay: '',
            skillClassificationDisplay: '',
            skillItemsLink: '',
            ownedSkillCheckBox: '',
            target: '',
        });
    }

    //カラムのグループ作成
    const groupByColumn: any = useCallback(
        (
            rows: any,
            columnKeys: any,
            expandedGroups: any,
            treeDepth: any = 0,
            parentId: any = ''
        ) => {
            if (columnKeys.length === 0) return rows;
            const gridRows = [];
            const [columnKey, ...remainingColumnKeys] = columnKeys;
            const groupedRows = groupBy(rows, columnKey);
            const groupedKeys = Object.keys(groupedRows);

            for (const groupKey of groupedKeys) {
                const groupId = parentId ? `${parentId}_${groupKey}` : groupKey;
                const isExpanded = expandedGroups.has(groupId);
                const rowGroupHeader = {
                    __metaData: {
                        groupId,
                        groupKey,
                        treeDepth,
                        isExpanded,
                        columnGroupName: props.skillData.skillColumns.find(
                            (c: option) => c.key === columnKey
                        )?.name,
                    },
                };
                gridRows.push(rowGroupHeader);
                if (isExpanded) {
                    gridRows.push(
                        ...groupByColumn(
                            groupedRows[groupKey],
                            remainingColumnKeys,
                            expandedGroups,
                            treeDepth + 1,
                            groupId
                        )
                    );
                }
            }
            return gridRows;
        },
        [props.skillData.skillColumns]
    );

    //グループ化
    const gridRows = useMemo(() => {
        return groupByColumn(filteredRows, groups, expandedGroups);
    }, [groupByColumn, filteredRows, groups, expandedGroups]);

    //グループ化
    function GroupRowRenderer(props: any) {
        if (props.row.__metaData === undefined) {
            return <Row {...props} />;
        }
        const { groupKey, isExpanded, treeDepth, columnGroupName, groupId } = props.row.__metaData;
        return (
            <div className="rdg-row rdg-row-default-group" tabIndex={0} style={{ top: props.top }}>
                <span
                    className="rdg-row-expand-icon"
                    style={{ marginLeft: treeDepth * 30 }}
                    onClick={() => onRowExpandToggle(groupId)}
                >
                    {isExpanded ? (
                        <Icon iconName="DoubleChevronDown" />
                    ) : (
                        <Icon iconName="DoubleChevronRight" />
                    )}
                </span>
                <strong>
                    {' '}
                    {columnGroupName} : {groupKey}
                </strong>
            </div>
        );
    }

    //グループ化に使用する関数
    function onRowExpandToggle(groupId: any) {
        const newExpandedGroups = new Set(expandedGroups);
        if (newExpandedGroups.has(groupId)) {
            newExpandedGroups.delete(groupId);
        } else {
            newExpandedGroups.add(groupId);
        }
        setExpandedGroups(newExpandedGroups);
    }

    //グループを全て閉じる
    function CloseGroup() {
        const newExpandedGroups = new Set(['']);
        setExpandedGroups(newExpandedGroups);
    }

    //グループを全て開く
    function OpenGroup() {
        setExpandedGroups(openSkillGroup);
    }

    function onFiltersChange(filters: Record<string, any>) {
        setFilters(filters);
    }

    return (
        <div className="ms-Grid" dir="ltr">
            <div
                className="ms-Grid-row ms-sm12 ms-md12 ms-lg12"
                dir="ltr"
                style={{ marginBottom: 10, textAlign: 'left' }}
            >
                <PrimaryButton
                    text="保存"
                    style={{ margin: 2 }}
                    disabled={!props.isEditable}
                    onClick={props.onSave}
                />
                <PrimaryButton text="全て開く" onClick={OpenGroup} style={{ margin: 2 }} />
                <PrimaryButton text="全て閉じる" onClick={CloseGroup} style={{ margin: 2 }} />
                <PrimaryButton
                    text="フィルター初期化"
                    onClick={clearFilters}
                    style={{ margin: 2 }}
                />
            </div>
            {targetColumn}
            <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12">
                <DataGrid
                    columns={filteredColumns}
                    rows={gridRows}
                    height={500}
                    enableFilters={true}
                    filters={filters}
                    defaultColumnOptions={{
                        resizable: true,
                    }}
                    onFiltersChange={(filter) => {
                        onFiltersChange(filter);
                    }}
                    rowRenderer={(p) => <GroupRowRenderer {...p} />}
                />
            </div>
        </div>
    );
};
